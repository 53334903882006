export const themeColors: { [key: string]: string } = {
  BLUE050: '#DEEBFF',
  BLUE075: '#B3D4FF',
  BLUE100: '#4C9AFF',
  BLUE200: '#2684FF',
  BLUE300: '#0065FF',
  BLUE400: '#0052CC',
  BLUE500: '#0747A6',
  GREEN050: '#E2FFEE',
  GREEN075: '#ABF5D1',
  GREEN100: '#79F2C0',
  GREEN200: '#57D9A3',
  GREEN300: '#36B37E',
  GREEN400: '#00875A',
  GREEN500: '#006644',
  TEAL900: '#063649',
  TEAL1000: '#032432',
  NEUTRAL000: '#ffffff',
  NEUTRAL010: '#FAFBFC',
  NEUTRAL020: '#F4F5F7',
  NEUTRAL030: '#EBECF0',
  NEUTRAL040: '#DFE1E6',
  NEUTRAL050: '#C1C7D0',
  NEUTRAL060: '#B3BAC5',
  NEUTRAL070: '#A5ADBA',
  NEUTRAL080: '#97A0AF',
  NEUTRAL090: '#8993A4',
  NEUTRAL100: '#7A869A',
  NEUTRAL200: '#6B778C',
  NEUTRAL300: '#5E6C84',
  NEUTRAL400: '#505F79',
  NEUTRAL500: '#42526E',
  NEUTRAL600: '#344563',
  NEUTRAL700: '#253858',
  NEUTRAL800: '#172B4D',
  NEUTRAL900: '#091E42',
  BLACK: '#000000',
  PURPLE050: '#EAE6FF',
  PURPLE075: '#C0B6F2',
  PURPLE100: '#998DD9',
  PURPLE200: '#8777D9',
  PURPLE300: '#6554C0',
  PURPLE400: '#5243AA',
  PURPLE500: '#403294',
  RED050: '#FFEBE5',
  RED075: '#FFBDAD',
  RED100: '#FF8F73',
  RED200: '#FF7452',
  RED300: '#FF5630',
  RED400: '#DE350B',
  RED500: '#BF2600',
  YELLOW050: '#FFFAE6',
  YELLOW075: '#FFF0B3',
  YELLOW100: '#FFE380',
  YELLOW200: '#FFC400',
  YELLOW300: '#FFAB00',
  YELLOW400: '#FF991F',
  YELLOW500: '#FF8B00',
};

export enum VendorEnvironmentEnum {
  PROD = 'prod',
  SANDBOX = 'sandbox',
  INTERNAL_TEST = 'internal test',
  DEMO = 'demo',
  DEV = 'dev',
  CUSTOMER_TEST = 'customer test',
}
export enum VendorEnvironmentLabelEnum {
  PROD = 'Production',
  SANDBOX = 'Test',
  INTERNAL_TEST = 'Internal test',
  DEMO = 'Demo',
  DEV = 'Development',
  CUSTOMER_TEST = 'Customer test',
}

const getEnvironmentKey = (environment: string): string | undefined =>
  Object.entries(VendorEnvironmentEnum).find(
    ([_, value]) => value === environment,
  )?.[0];

export const getVendorEnvironmentLabel = (environment: string): string => {
  const key = getEnvironmentKey(environment);
  return typeof key === 'string'
    ? (VendorEnvironmentLabelEnum as Record<string, string>)[key] || environment
    : environment;
};

type environmentColors = {
  [key: string]: string;
};
// Using legacy theme.palette
const environmentColorMap: environmentColors = {
  PROD: 'GREEN400',
  SANDBOX: 'YELLOW300',
  INTERNAL_TEST: 'BLUE075',
  DEMO: 'GREEN075',
  DEV: 'BLUE300',
};

const environmentHoverColorMap: environmentColors = {
  PROD: 'GREEN500',
  SANDBOX: 'YELLOW500',
  INTERNAL_TEST: 'BLUE300',
  DEMO: 'GREEN400',
  DEV: 'BLUE700',
};

function useEnvironmentColor(environment: string): string {
  if (typeof environment === 'string') {
    const key = getEnvironmentKey(environment);
    const colorString = key ? environmentColorMap[key] : 'NEUTRAL080';
    const returnColor = themeColors[colorString];
    return returnColor;
  } else {
    throw new Error('Invalid input: Please provide a valid string.');
  }
}

function useEnvironmentHoverColor(environment: string): string {
  if (typeof environment === 'string') {
    const colorString =
      environmentHoverColorMap[environment.toLowerCase()] || 'NEUTRAL080';
    const returnColor = themeColors[colorString];
    return returnColor;
  } else {
    throw new Error('Invalid input: Please provide a valid string.');
  }
}

export { useEnvironmentColor, useEnvironmentHoverColor };
