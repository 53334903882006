import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@tackle-io/platform-ui';
import { downloadJsonFile } from 'pages/Vendor/tabs/VendorConfig/helpers';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  exportJsonSpacing: {
    paddingRight: theme.spacing(2),
  },
}));

const MARKETPLACE_TABLE_MAPPER = {
  aws: 'tku-product',
  gcp: 'tku-gcp-product',
  azure: 'tku-azure-product',
  redhat: 'tku-redhat-product',
};
interface ExportListingToJsonButtonProps {
  productidInternal: string;
  marketplace: 'aws' | 'gcp' | 'azure' | 'redhat';
}

const ExportListingToJsonButton: React.FC<ExportListingToJsonButtonProps> = ({
  productidInternal,
  marketplace,
}) => {
  const classes = useStyles();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    function fetchAuthToken(): void {
      getAccessTokenSilently().then(
        (freshToken) => {
          setToken(freshToken);
        },
        (error) => {
          setToken(null);
          throw error;
        },
      );
    }

    if (!isAuthenticated && token) {
      setToken(null);
      return;
    }

    if (isAuthenticated && !token) {
      fetchAuthToken();
    }
  }, [getAccessTokenSilently, isAuthenticated, token]);

  const downloadListingJson = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    try {
      const data = await (
        await fetch(
          `${process.env.REACT_APP_API_URL}/product/${productidInternal}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
      ).json();

      downloadJsonFile(
        JSON.stringify(data, undefined, 2),
        `${
          MARKETPLACE_TABLE_MAPPER[marketplace]
        }-${productidInternal}-${new Date().toISOString()}.json`,
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={classes.exportJsonSpacing}>
      <Button
        size="small"
        variant="outlined"
        appearance="secondary"
        onClick={downloadListingJson}
        disabled={false}
      >
        Export JSON
      </Button>
    </div>
  );
};

export default ExportListingToJsonButton;
