import { gql } from '@apollo/client';
import { Banner, FormSection, Link } from '@tackle-io/platform-ui';
import { useGcpListingQuery } from 'generated/graphql';
import { zip } from 'lodash';
import {
  FaceAgent,
  FileDocument,
  FileFind,
  Google,
  License,
  PackageVariant,
} from 'mdi-material-ui';
import React from 'react';
import { Box, Grid } from 'vendor/material';

import InfoDiffFile from '../InfoDiffFile/InfoDiffFile';
import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';
import LoadingListingContent from '../LoadingListingContent/LoadingListingContent';
import SectionIcon from '../SectionIcon/SectionIcon';
import GcpDocumentation from './GcpDocumentation';
import GcpFeatures from './GcpFeatures';
import GcpKeywords from './GcpKeywords';
import GcpLicenseAgreements from './GcpLicenseAgreements';
import GcpPricing from './GcpPricing';

interface ListingContentGCPProps {
  productidInternal: string;
}

export const GCP_LISTING_QUERY = gql`
  fragment GcpListingFields on GcpListing {
    categories
    contact_email
    contact_support_url
    dashboard_url
    documentation {
      description
      resource_type
      url
    }
    eula_url
    features {
      description
      feature_id
      label
    }
    full_description
    gcp_service
    id
    license_agreements {
      title
      url
    }
    listing_sku
    listing_slug
    listing_type
    logo
    marketplace_id
    marketplace_url
    metadata_keywords
    metrics_pricing {
      name
      price_per_unit {
        plan_id
        price
      }
      sku
      unit
    }
    name
    overview_url
    packages
    plans {
      feature_ids
      name
      plan_id
      pricing
      public
      sku
    }
    search_keywords
    short_description
    solution_id
    support_description
    support_email
    support_url
    tax_category
    tax_witholding
    vendorid
    revision_of_product_id_internal
  }
  query GcpListing($id: ID!) {
    gcpListing(id: $id) {
      ...GcpListingFields
      revision_of_listing {
        ...GcpListingFields
      }
    }
  }
`;

const ListingContentGCP: React.FC<ListingContentGCPProps> = ({
  productidInternal,
}) => {
  const { data: listingData, loading: isFetching } = useGcpListingQuery({
    variables: {
      id: productidInternal,
    },
  });

  const sourceListingData = listingData?.gcpListing?.revision_of_listing?.id
    ? listingData?.gcpListing?.revision_of_listing
    : listingData?.gcpListing;

  if (isFetching) {
    return <LoadingListingContent />;
  }

  if (!listingData) {
    return <div>product info not found</div>;
  }

  return (
    <>
      {listingData?.gcpListing?.revision_of_product_id_internal && (
        <Box mb={3}>
          <Banner
            type="info"
            title={`Listing copied from ${sourceListingData?.name}`}
            body={
              <Link
                to={`/vendor/${listingData?.gcpListing?.vendorid}/listings/${listingData?.gcpListing?.revision_of_product_id_internal}`}
              >
                {sourceListingData?.name}
              </Link>
            }
          />
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={3} md={3} lg={3}>
          <InfoDiffItem
            title="Internal Product ID"
            sourceValue={sourceListingData?.id}
            revisionValue={listingData?.gcpListing?.id}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <InfoDiffItem
            title="Marketplace Product ID"
            sourceValue={sourceListingData?.marketplace_id}
            revisionValue={listingData?.gcpListing?.marketplace_id}
          />
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          <InfoDiffItem
            title="GCP Service"
            sourceValue={sourceListingData?.gcp_service}
            revisionValue={listingData?.gcpListing?.gcp_service}
          />
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          <InfoDiffItem
            title="SaaS URL"
            sourceValue={`https://upstream-api.tackle.io/v1/gcp/order/${sourceListingData?.vendorid}/${sourceListingData?.marketplace_id}`}
            revisionValue={`https://upstream-api.tackle.io/v1/gcp/order/${listingData?.gcpListing?.vendorid}/${listingData?.gcpListing?.marketplace_id}`}
          />
        </Grid>
      </Grid>
      <Box mt={3}>
        <FormSection
          title={
            <SectionIcon
              icon={<Google fontSize="inherit" />}
              title="Technical Setup"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <InfoDiffItem
                title="Solution ID"
                sourceValue={sourceListingData?.solution_id}
                revisionValue={listingData?.gcpListing?.solution_id}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <InfoDiffItem
                title="Dashboard URL"
                sourceValue={sourceListingData?.dashboard_url}
                revisionValue={listingData?.gcpListing?.dashboard_url}
              />
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      <Box mt={3}>
        <FormSection
          title={
            <SectionIcon
              icon={<FileDocument fontSize="inherit" />}
              title="Overview"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <InfoDiffFile
                title="Logo"
                type="picture"
                sourceFile={sourceListingData?.logo}
                sourceFileName={sourceListingData?.logo?.split('/')?.pop()}
                revisionFile={listingData?.gcpListing?.logo}
                revisionFileName={listingData?.gcpListing?.logo
                  ?.split('/')
                  .pop()}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InfoDiffItem
                title="Listing Name"
                sourceValue={sourceListingData?.name}
                revisionValue={listingData?.gcpListing?.name}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InfoDiffItem
                title="Short Description"
                sourceValue={sourceListingData?.short_description}
                revisionValue={listingData?.gcpListing?.short_description}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InfoDiffItem
                title="Long Description"
                sourceValue={sourceListingData?.full_description}
                revisionValue={listingData?.gcpListing?.full_description}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InfoDiffItem
                title="Useful Link"
                sourceValue={sourceListingData?.overview_url}
                revisionValue={listingData?.gcpListing?.overview_url}
              />
            </Grid>
            {zip(
              sourceListingData?.categories ?? [],
              listingData?.gcpListing?.categories ?? [],
            ).map(([sourceCategory, revisionCategory], index: number) => (
              <Grid
                item
                xs={6}
                md={6}
                lg={6}
                key={revisionCategory || sourceCategory}
              >
                <InfoDiffItem
                  title={`Product Category ${index + 1}`}
                  sourceValue={sourceCategory}
                  revisionValue={revisionCategory}
                />
              </Grid>
            ))}
          </Grid>
        </FormSection>
      </Box>
      <Box mt={3}>
        <FormSection
          title={
            <SectionIcon
              icon={<FaceAgent fontSize="inherit" />}
              title="Documentation & Support"
            />
          }
          mb={1.5}
        >
          <GcpDocumentation
            sourceDocumentation={sourceListingData?.documentation}
            revisionDocumentation={listingData?.gcpListing?.documentation}
          />
          <Grid container spacing={2}>
            <Grid item xs={4} md={4} lg={4}>
              <InfoDiffItem
                title="Support Description"
                sourceValue={sourceListingData?.support_description}
                revisionValue={listingData?.gcpListing?.support_description}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <InfoDiffItem
                title="Support URL"
                sourceValue={sourceListingData?.support_url}
                revisionValue={listingData?.gcpListing?.support_url}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <InfoDiffItem
                title="Support Email"
                sourceValue={sourceListingData?.support_email}
                revisionValue={listingData?.gcpListing?.support_email}
              />
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      <Box mt={3}>
        <FormSection
          title={
            <SectionIcon
              icon={<License fontSize="inherit" />}
              title="License Agreement"
            />
          }
          mb={1.5}
        >
          <GcpLicenseAgreements
            sourceLicenseAgreements={
              listingData?.gcpListing?.license_agreements
            }
            revisionLicenseAgreements={
              listingData?.gcpListing?.license_agreements
            }
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InfoDiffItem
                title="Tax Category"
                sourceValue={sourceListingData?.tax_category}
                revisionValue={listingData?.gcpListing?.tax_category}
              />
            </Grid>
            <Grid item xs={6}>
              <InfoDiffItem
                title="Tax Witholding"
                sourceValue={sourceListingData?.tax_witholding}
                revisionValue={listingData?.gcpListing?.tax_witholding}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <InfoDiffItem
                title="End User License Agreement URL"
                sourceValue={sourceListingData?.eula_url}
                revisionValue={listingData?.gcpListing?.eula_url}
              />
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      <Box mt={3}>
        <FormSection
          title={
            <SectionIcon
              icon={<FileFind fontSize="inherit" />}
              title="Search Keywords"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              <InfoDiffItem
                title="Metadata Description"
                sourceValue={sourceListingData?.metadata_keywords}
                revisionValue={listingData?.gcpListing?.metadata_keywords}
              />
            </Grid>
            <Grid item xs={6}>
              <GcpKeywords
                sourceKeywords={sourceListingData?.search_keywords}
                revisionKeywords={listingData?.gcpListing?.search_keywords}
              />
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      <Box mt={3}>
        <FormSection
          title={
            <SectionIcon
              icon={<PackageVariant fontSize="inherit" />}
              title={'Features'}
            />
          }
          mb={1.5}
        >
          <GcpFeatures
            sourceFeatures={sourceListingData?.features}
            revisionFeatures={listingData?.gcpListing?.features}
          />
        </FormSection>
      </Box>
      <GcpPricing
        sourceFeatures={sourceListingData?.features}
        revisionFeatures={listingData?.gcpListing?.features}
        sourcePlans={sourceListingData?.plans}
        revisionPlans={listingData?.gcpListing?.plans}
        sourceMetrics={sourceListingData?.metrics_pricing}
        revisionMetrics={listingData?.gcpListing?.metrics_pricing}
      />
    </>
  );
};

export default ListingContentGCP;
