import React from 'react';

import useStyles from './InfoList.styles';

const InfoListItem = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();

  return <li className={classes.item}>{children}</li>;
};

const InfoList = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();

  return <ul className={classes.list}>{children}</ul>;
};

export { InfoListItem };
export default InfoList;
