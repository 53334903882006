import * as React from 'react';

function SvgLogo() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 72 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.041 0L0 64h15.576l20.465-36.425L56.506 64H72L36.041 0z"
        fill="#0052CC"
      />
      <path d="M21.956 64h28.088L36.042 39.064 21.956 64z" fill="#0065FF" />
    </svg>
  );
}

export default SvgLogo;
