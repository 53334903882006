import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: theme.palette.NEUTRAL010,
    border: `1px solid ${theme.palette.NEUTRAL030}`,
    padding: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    borderRadius: 4,
    cursor: 'pointer',
  },
  fileIcon: {
    color: theme.palette.NEUTRAL500,
    fontSize: theme.typography.pxToRem(24),
    marginRight: theme.spacing(1),
    display: 'flex',
  },
  text: {
    cursor: 'pointer',
  },
}));

export default useStyles;
