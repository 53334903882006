import { AzureCategory } from 'generated/graphql';
import { zip } from 'lodash';
import React from 'react';
import { Grid } from 'vendor/material';

import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';

type AzureCategoriesProps = {
  sourceCategories: Array<AzureCategory> | null | undefined;
  revisionCategories: Array<AzureCategory> | null | undefined;
};

const AzureCategories: React.FC<AzureCategoriesProps> = ({
  sourceCategories,
  revisionCategories,
}) => {
  const CATEGORY_NAMING: Record<number, string> = {
    0: 'Primary',
    1: 'Secondary',
  };

  const combinedCategories = zip(
    sourceCategories ?? [],
    revisionCategories ?? [],
  );

  if (!combinedCategories?.length) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <InfoDiffItem title="Categories" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {combinedCategories.map(([sourceCategory, revisionCategory], ind) => {
        return (
          <React.Fragment key={ind}>
            <Grid item xs={4} md={4} lg={4}>
              <InfoDiffItem
                title={`Product Category ${CATEGORY_NAMING[ind]}`}
                sourceValue={sourceCategory?.category}
                revisionValue={revisionCategory?.category}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <InfoDiffItem
                title={`Product ${CATEGORY_NAMING[ind]} Subcategory 1`}
                sourceValue={sourceCategory?.subcategory_1}
                revisionValue={revisionCategory?.subcategory_1}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <InfoDiffItem
                title={`Product ${CATEGORY_NAMING[ind]} Subcategory 2`}
                sourceValue={sourceCategory?.subcategory_2}
                revisionValue={revisionCategory?.subcategory_2}
              />
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

export default AzureCategories;
