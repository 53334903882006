// eslint-disable-next-line no-restricted-imports
import { DefaultTheme } from '@material-ui/styles';
import { makeStyles } from 'vendor/material';

const listBreakpoints = (theme: DefaultTheme) => ({
  '& :nth-child(5), & :nth-child(4)': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  '& :nth-child(3)': {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[1],
    marginBottom: theme.spacing(2),
  },
  listingEdit: {
    backgroundColor: theme.palette.YELLOW075,
  },
  listingName: {
    display: 'flex',
    alignItems: 'center',
  },
  opened: {
    boxShadow: theme.shadows[2],
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1px 0',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& p': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
    },
    ...listBreakpoints(theme),
  },
  subheader: {
    borderTop: `1px solid ${theme.palette.NEUTRAL040}`,
    backgroundColor: theme.palette.NEUTRAL010,
    display: 'flex',
    justifyContent: 'end',
    padding: theme.spacing(2, 3),
  },
  content: {
    borderTop: `1px solid ${theme.palette.NEUTRAL040}`,
  },
  contentInfo: {
    padding: theme.spacing(3),
    '& [data-breakpoint*="lg"]': {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    '& [data-breakpoint*="md"]': {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    '& [data-breakpoint*="sm"]': {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  },
  providerLogo: {
    fontSize: theme.typography.pxToRem(28),
    marginRight: theme.spacing(1),
  },
  configurationStatus: {
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(3),
    },
    '& > *:last-child': {
      marginRight: theme.spacing(0),
    },
  },
  marketplaceLink: {
    color: theme.palette.BLUE400,
  },
  productIdColumn: {
    overflowWrap: 'anywhere',
  },
  editTag: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.NEUTRAL030,
    color: theme.palette.NEUTRAL400,
  },
}));

export default useStyles;
