import { Vendor } from 'generated/graphql';
import { sortBy } from 'lodash';
import { VendorEnvironmentEnum } from 'pages/Vendor/VendorEnvironment.hooks';

export const getSortedSandboxes = (sandboxes: Array<Vendor>) => {
  const hasMultipleSandboxes = sandboxes.length > 1;
  const testSandboxName = '[Test]';
  const test1SandboxName = '[Test 1]';
  // Add altName for sorting [Test] before additional [Test n]
  const sandboxesWithAltName = sandboxes.map((sandbox) => ({
    ...sandbox,
    altName: hasMultipleSandboxes
      ? sandbox?.name?.replace(testSandboxName, test1SandboxName)
      : sandbox.name,
  }));

  const sortedSandboxes = sortBy(sandboxesWithAltName, 'altName').map(
    (sandbox) => ({
      ...sandbox,
      vendor_type: VendorEnvironmentEnum.SANDBOX,
    }),
  );
  return sortedSandboxes;
};
