import { gql } from '@apollo/client';
import { Alert, Button, TextField } from '@tackle-io/platform-ui';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { Box, Grid } from 'vendor/material';
import { object as yupObject, string as yupString } from 'yup';

import {
  RedhatVendorConfigurationFormQuery,
  useRedhatVendorConfigurationFormQuery,
  useUpdateRedhatVendorConfigurationFormMutation,
} from '../../../../generated/graphql';

type RedHatConfig = {
  redHatApiKey: string;
};

export const REDHAT_VENDOR_CONFIGURATION_FORM_QUERY = gql`
  query RedhatVendorConfigurationForm($id: ID!) {
    vendor(id: $id) {
      id
      configuration {
        id
        redhat {
          api_key
        }
      }
    }
  }
`;

export const REDHAT_VENDOR_CONFIGURATION_FORM_MUTATION = gql`
  mutation UpdateRedhatVendorConfigurationForm($updates: VendorInput!) {
    updateVendor(updates: $updates) {
      id
      configuration {
        id
        redhat {
          api_key
        }
      }
    }
  }
`;

const redHatApiToForm = (
  vendor: RedhatVendorConfigurationFormQuery | undefined,
): RedHatConfig => {
  return {
    redHatApiKey: vendor?.vendor?.configuration?.redhat?.api_key || '',
  };
};

type FormValues = {
  redHatApiKey: string;
};

const schema = yupObject().shape({
  api_key: yupString(),
});

const RedHatVendorConfigForm = ({ onClose }: { onClose: () => void }) => {
  const { vendorid } = useParams<{ vendorid: string }>();
  const { data: vendorData, loading: isQueryLoading } =
    useRedhatVendorConfigurationFormQuery({
      variables: {
        id: vendorid,
      },
    });

  const [setVendorConfig, { loading: isMutationLoading, error: isError }] =
    useUpdateRedhatVendorConfigurationFormMutation({
      onCompleted: onClose,
    });

  const isLoading = isQueryLoading || isMutationLoading;

  const handleOnSubmit = (values: RedHatConfig): void => {
    setVendorConfig({
      variables: {
        updates: {
          id: vendorid,
          configuration: {
            redhat: {
              api_key: values.redHatApiKey,
            },
          },
        },
      },
    });
  };

  const formik = useFormik<FormValues>({
    initialValues: redHatApiToForm(vendorData),
    validateOnBlur: true,
    validateOnChange: true,
    isInitialValid: true,
    validationSchema: schema,
    onSubmit: handleOnSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="API KEY VALUE *"
            {...formik.getFieldProps('redHatApiKey')}
            disabled={isLoading}
          />
        </Grid>
      </Grid>
      {isError && (
        <Box mt={4}>
          <Alert
            appearance="danger"
            noShadow
            hideIcon
            title="Error: Unsuccessful vendor update. Create a support ticket, assign it to pod:streetsharks, and the team will reach out when the issue has been resolved."
          />
        </Box>
      )}
      <Box display="flex" flexDirection="row-reverse" mt={4}>
        <Button
          type="submit"
          appearance="primary"
          variant="text"
          disabled={!formik.isValid || !formik.dirty}
          loading={isLoading}
        >
          Update
        </Button>
        <Button
          appearance="primary"
          disabled={false}
          variant="text"
          onClick={(): void => {
            formik.resetForm();
            onClose();
          }}
        >
          Cancel
        </Button>
      </Box>
    </form>
  );
};

export default RedHatVendorConfigForm;
