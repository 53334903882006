import { GcpFeature } from 'generated/graphql';
import { zip } from 'lodash';
import React from 'react';
import { Grid } from 'vendor/material';

import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';

const GcpFeatures = ({
  sourceFeatures = [],
  revisionFeatures = [],
}: {
  sourceFeatures: Array<GcpFeature> | null | undefined;
  revisionFeatures: Array<GcpFeature> | null | undefined;
}) => {
  const combinedFeatures = zip(sourceFeatures ?? [], revisionFeatures ?? []);

  if (!combinedFeatures?.length) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <InfoDiffItem title="Feature 1 Label" />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <InfoDiffItem title="Feature 1 Description" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {combinedFeatures.map(([sourceFeatures, revisionFeatures], index) => {
        return (
          <React.Fragment key={index}>
            <Grid item xs={6} md={6} lg={6}>
              <InfoDiffItem
                title={`Feature Label ${index + 1}`}
                sourceValue={sourceFeatures?.label}
                revisionValue={revisionFeatures?.label}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <InfoDiffItem
                title={`Feature Description ${index + 1}`}
                sourceValue={sourceFeatures?.description}
                revisionValue={revisionFeatures?.description}
              />
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

export default GcpFeatures;
