import { useHistory, useRouteMatch } from 'react-router-dom';

type VendorRouteParams = {
  vendorid: string;
  tab: string;
};

const useVendorRootMatch = () =>
  useRouteMatch<VendorRouteParams>('/vendor/:vendorid');

type VendorTab = {
  label: string;
  tabPath: string;
};

export type VendorTabs = Array<VendorTab>;

const VENDOR_TABS: VendorTabs = [
  { label: 'LISTINGS', tabPath: 'listings' },
  { label: 'VENDOR DETAILS', tabPath: 'config' },
  { label: 'SUPPORT', tabPath: 'support' },
  { label: 'TEST ENVIRONMENTS', tabPath: 'sandboxes' },
];

const useActiveVendorTab = () => {
  const history = useHistory();
  const rootMatch = useVendorRootMatch();

  const match = useRouteMatch<VendorRouteParams>('/vendor/:vendorid/:tab?');

  const activeTabIndex = match?.params.tab
    ? VENDOR_TABS.findIndex((tab) => tab.tabPath === match.params.tab)
    : null;

  const handleClickTab = (tabIndex: number): void => {
    const { tabPath } = VENDOR_TABS[tabIndex];

    history.push(`${rootMatch?.url}/${tabPath}`);
  };

  return { activeTabIndex, onClickTab: handleClickTab, tabs: VENDOR_TABS };
};

export { useActiveVendorTab, useVendorRootMatch };
