import { HtmlTooltip, InfoItem as PuiInfoItem } from '@tackle-io/platform-ui';
import InfoText from 'components/InfoText';
import { Check } from 'mdi-material-ui';
import { useState } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import clearClipboard from 'utils/clearClipboard';
import copyToClipboard from 'utils/copyToClipboard';
import { makeStyles, Typography } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  toolTipContainer: { display: 'flex', alignItems: 'center' },
  checkIcon: {
    color: theme.palette.GREEN400,
    fontSize: theme.typography.pxToRem(20),
    marginRight: theme.spacing(1),
    display: 'flex',
  },
  text: {
    cursor: 'pointer',
    wordBreak: 'break-word',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconWrapper: {
    marginLeft: theme.spacing(1),
  },
}));

const InfoDiffItem = ({
  title,
  sourceValue: oldValue = '',
  revisionValue: newValue = '',
}: {
  title?: string;
  sourceValue?: string | undefined | null;
  revisionValue?: string | undefined | null;
}) => {
  const [copied, setCopied] = useState(false);
  const classes = useStyles();

  const handleCopy = () => {
    if (!newValue) clearClipboard();
    copyToClipboard(newValue ?? '');
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  if (!oldValue && !newValue) {
    return <PuiInfoItem title={title}>{'-'}</PuiInfoItem>;
  }

  if (oldValue === newValue) {
    if (!title) {
      return <InfoText clipboard text={newValue ?? ''} />;
    }
    return (
      <PuiInfoItem title={title}>
        <InfoText clipboard text={newValue ?? ''} />
      </PuiInfoItem>
    );
  }

  const InfoDiffText = () => {
    return (
      <div className={classes.container}>
        <HtmlTooltip
          interactive
          placement="top"
          title={
            <div className={classes.toolTipContainer}>
              {copied ? (
                <>
                  <div className={classes.checkIcon}>
                    <Check fontSize="inherit" />
                  </div>
                  <Typography component="p">Copied to clipboard</Typography>
                </>
              ) : (
                <Typography component="p">Click to copy</Typography>
              )}
            </div>
          }
        >
          <span
            className={classes.text}
            onClick={handleCopy}
            role="presentation"
          >
            <ReactDiffViewer
              oldValue={oldValue ?? ''}
              newValue={newValue ?? ''}
              splitView={false}
              hideLineNumbers
              showDiffOnly={false}
              compareMethod={DiffMethod.WORDS}
            />
          </span>
        </HtmlTooltip>
      </div>
    );
  };

  return title ? (
    <PuiInfoItem title={title}>
      <InfoDiffText />
    </PuiInfoItem>
  ) : (
    <InfoDiffText />
  );
};

export default InfoDiffItem;
