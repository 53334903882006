import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { removeUser } from 'utils/datadog';

const Logout = (): React.ReactElement => {
  const auth0 = useAuth0();
  const isDev = process.env.NODE_ENV === 'development';

  useEffect(() => {
    localStorage.removeItem('tokenOverride');
    localStorage.removeItem('apollo-cache-persist');
    removeUser();
    auth0.logout({ localOnly: isDev, returnTo: window.location.origin });
  }, [auth0, isDev]);

  return <Redirect to="/" />;
};

export default Logout;
