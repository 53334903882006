import { gql } from '@apollo/client';
import { Button, Modal, Select, TextField } from '@tackle-io/platform-ui';
import WarningAlertBox from 'components/WarningAlertIcon';
import {
  useGoToDownstreamModalButtonQuery,
  useSwitchVendorMutation,
} from 'generated/graphql';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Grid, makeStyles, Typography } from 'vendor/material';

gql`
  query GoToDownstreamModalButton($vendorId: ID!) {
    vendor(id: $vendorId) {
      id
      vendor_type
    }
    currentUser {
      id
      vendorId
    }
  }
`;

gql`
  mutation SwitchVendor(
    $vendorId: String!
    $reason: String
    $caseNumber: String
  ) {
    switchVendors(
      vendorid: $vendorId
      reason: $reason
      case_number: $caseNumber
    ) {
      id
      vendorId
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  subtext: {
    color: theme.palette.NEUTRAL300,
    marginBottom: theme.spacing(1),
  },
}));

const getLocalStorageVendorSwitchValue = (vendorSwitchKey: string) => {
  const storedDataJson = localStorage.getItem('switchVendorData');
  if (storedDataJson) {
    try {
      const storedData = JSON.parse(storedDataJson);
      return storedData[vendorSwitchKey] || '';
    } catch (error) {
      console.error('Error parsing switchVendorData from localStorage:', error);
      return '';
    }
  }
  return '';
};

const GoToDownstreamModalButton = ({
  cloud = null, // We have to pass the cloud, because we don't have it in the url.
  label,
}: {
  cloud?: 'aws' | 'azure' | 'gcp' | 'redhat' | null;
  label: string;
}) => {
  const classes = useStyles();
  const match = useRouteMatch<{ vendorId: string; listingId?: string }>(
    '/vendor/:vendorId/(\\w+)?/:listingId?',
  );

  const { vendorId, listingId } = match?.params! || {};

  const { data, loading: queryLoading } = useGoToDownstreamModalButtonQuery({
    variables: { vendorId },
  });

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [switchVendorReason, setSwitchVendorReason] = useState<string>(() =>
    getLocalStorageVendorSwitchValue('switchVendorReason'),
  );
  const [switchVendorSupportCase, setSwitchVendorSupportCase] =
    useState<string>(() =>
      getLocalStorageVendorSwitchValue('switchVendorSupportCase'),
    );

  const [switchVendor, { loading: mutationLoading }] = useSwitchVendorMutation({
    update(cache, result) {
      cache.modify({
        id: cache.identify(data?.currentUser!),
        fields: {
          vendorId() {
            return result.data?.switchVendors.vendorId;
          },
        },
      });
    },
    onCompleted() {
      if (listingId) {
        window.open(
          `${process.env.REACT_APP_DOWNSTREAM_URL}/listings/${cloud}/${listingId}/?renewAuth=true`,
        );
      } else {
        window.open(`${process.env.REACT_APP_DOWNSTREAM_URL}?renewAuth=true`);
      }
    },
  });

  return (
    <>
      <Modal
        open={isOpenModal}
        title=""
        width="medium"
        onClose={() => {
          setIsOpenModal(false);
        }}
        footerActions={
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                appearance="primary"
                disabled={false}
                variant="text"
                onClick={() => setIsOpenModal(false)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                appearance="primary"
                onClick={async () => {
                  await switchVendor({
                    variables: {
                      vendorId: vendorId,
                      reason: switchVendorReason || undefined,
                      caseNumber: switchVendorSupportCase || undefined,
                    },
                  });

                  setIsOpenModal(false);

                  if (switchVendorReason || switchVendorSupportCase) {
                    // Set the reason and support case values in localStorage
                    localStorage.setItem(
                      'switchVendorData',
                      JSON.stringify({
                        switchVendorReason: switchVendorReason || undefined,
                        switchVendorSupportCase:
                          switchVendorSupportCase || undefined,
                      }),
                    );
                  } else {
                    localStorage.removeItem('switchVendorData');
                  }
                }}
                disabled={!Boolean(switchVendorReason) || mutationLoading}
                loading={mutationLoading}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid container item>
            <WarningAlertBox size="medium" />
            <Typography variant="h6">Warning</Typography>
          </Grid>
          <Grid item className={classes.subtext}>
            <Typography variant="subtitle2" component="p">
              You are about to enter a Tackle customers environment
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="p">
              By clicking “Continue”, you will enter the customers environment
              in Tackle. Your activity may be visible to the customer via SIEM
              logging and other reporting.
            </Typography>
          </Grid>
          <Grid item>
            <Select
              name="reason"
              label="Please select your reason for access:"
              disabled={mutationLoading}
              defaultValue={switchVendorReason}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setSwitchVendorReason(e.target.value)
              }
            >
              <option value="">Choose</option>
              <option value="create-customer-asset">
                Create customer asset
              </option>
              <option value="live-demo">Live demo</option>
              <option value="pre-call-research">Pre-call research</option>
              <option value="post-call-follow-up">Post-call follow-up</option>
              <option value="QA">QA</option>
              <option value="user-management">User management</option>
              <option value="in-an-active-implementation">
                In an active implementation
              </option>
              <option value="troubleshooting-customer-issue">
                Troubleshooting customer issue
              </option>
            </Select>
          </Grid>
          <Grid item>
            <TextField
              label="Support case number (optional)"
              name="caseNumber"
              value={switchVendorSupportCase}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSwitchVendorSupportCase(e.target.value)
              }
              disabled={mutationLoading}
            />
          </Grid>
        </Grid>
      </Modal>
      <Button
        variant="outlined"
        size="small"
        appearance="secondary"
        onClick={() => {
          if (data?.vendor?.vendor_type === 'prod') {
            // we need to ask for the reason and support case
            setIsOpenModal(true);
          } else {
            // not a production vendor so switch but dont ask for reason or support case
            switchVendor({ variables: { vendorId: vendorId } });
          }
        }}
        disabled={queryLoading}
        loading={queryLoading}
      >
        {label}
      </Button>
    </>
  );
};

export default GoToDownstreamModalButton;
