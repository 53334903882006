import React from 'react';

import useStyles from './SectionIcon.styles';

interface SectionIconProps {
  icon: React.ReactNode;
  title: string;
}

const SectionIcon: React.FC<SectionIconProps> = ({ icon, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.icon}>{icon}</div>
      <span className={classes.title}>{title}</span>
    </div>
  );
};

export default SectionIcon;
