import { useAuth0 } from '@auth0/auth0-react';
import { Button, Link } from '@tackle-io/platform-ui';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Box, makeStyles } from 'vendor/material';

import LogoAdmin from '../images/logo';
import TackleLogo from '../images/tackle-logo';

const useStyles = makeStyles((theme) => ({
  container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    overflow: 'hidden',
  },
  loginPage: {
    height: '100%',
    width: '100vw',
    backgroundColor: theme.palette.NEUTRAL010,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.NEUTRAL000,
    overflow: 'hidden',
    '&::before': {
      position: 'absolute',
      top: -750,
      left: -180,
      backgroundColor: theme.palette.NEUTRAL020,
      height: '100%',
      width: '150%',
      display: 'block',
      content: "''",
      transform: 'rotate(-15deg)',
      [theme.breakpoints.up(2559)]: {
        top: -1190,
      },
      [theme.breakpoints.down(1920)]: {
        top: -650,
      },
      [theme.breakpoints.down(1680)]: {
        top: -500,
      },
      [theme.breakpoints.down(1440)]: {
        top: -400,
      },
      [theme.breakpoints.down(1024)]: {
        top: -500,
      },
      [theme.breakpoints.down(768)]: {
        top: -900,
      },
    },
  },
  loginPageContainer: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    zIndex: 9,
    width: 308,
    height: 491,
    backgroundColor: theme.palette.NEUTRAL000,
    boxShadow: theme.shadows[4],
    borderRadius: 4,
    overflow: 'hidden',
  },
  loginBox: {
    backgroundColor: theme.palette.NEUTRAL000,
    padding: theme.spacing(4),
  },
  tackleLogoContainer: {
    zIndex: 9,
    marginTop: theme.spacing(8),
  },
  logoContainer: {
    width: '100%',
    height: 225,
    borderBottomLeftRadius: '50% 15%',
    borderBottomRightRadius: '50% 15%',
    transform: 'scale(1.4)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background:
      'linear-gradient(272.44deg, #0065FF 19.16%, #0052CC 79.78%), #C4C4C4',
  },
  logo: {
    backgroundColor: theme.palette.NEUTRAL000,
    width: 116,
    height: 116,
    borderRadius: 25,
    boxShadow: theme.shadows[4],
    transform: 'scale(0.6) translateY(30px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      width: 72,
      height: 64,
    },
  },
  loginActions: {
    marginTop: theme.spacing(12),
  },
}));

const Login = () => {
  const classes = useStyles();
  const auth0 = useAuth0();
  const { isLoading, loginWithRedirect, isAuthenticated } = auth0;

  if (isLoading) {
    return null;
  }

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className={classes.container}>
      <Box className={classes.loginPage}>
        <div className={classes.tackleLogoContainer}>
          <TackleLogo />
        </div>
        <div className={classes.loginPageContainer}>
          <div className={classes.logoContainer}>
            <div className={classes.logo}>
              <LogoAdmin />
            </div>
          </div>
          <div className={classes.loginActions}>
            <Button
              fullWidth
              appearance="primary"
              onClick={(): Promise<void> => loginWithRedirect()}
            >
              Log in with Google
            </Button>
            <Box mt={4}>
              <Link external to="#.">
                Need more help? Get support.
              </Link>
            </Box>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Login;
