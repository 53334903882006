import { AzureLicenseAgreement as AzureLicenseAgreementType } from 'generated/graphql';
import React from 'react';
import { Grid } from 'vendor/material';

import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';

type AzureLicenseAgreementProps = {
  sourceLicenseAgreement: AzureLicenseAgreementType | null | undefined;
  revisionLicenseAgreement: AzureLicenseAgreementType | null | undefined;
};

const AzureLicenseAgreement: React.FC<AzureLicenseAgreementProps> = ({
  sourceLicenseAgreement,
  revisionLicenseAgreement,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <InfoDiffItem
          title="End User License Agreement Type"
          sourceValue={sourceLicenseAgreement?.contract_type}
          revisionValue={revisionLicenseAgreement?.contract_type}
        />
      </Grid>
      <Grid item>
        <InfoDiffItem
          title={
            revisionLicenseAgreement?.contract_type === 'Standard Contract'
              ? 'EULA Universal Amendment Terms'
              : 'Terms and Conditions'
          }
          sourceValue={sourceLicenseAgreement?.contract_terms}
          revisionValue={revisionLicenseAgreement?.contract_terms}
        />
      </Grid>
      <Grid item>
        <InfoDiffItem
          title="Custom EULA URL"
          sourceValue={sourceLicenseAgreement?.eula_url}
          revisionValue={revisionLicenseAgreement?.eula_url}
        />
      </Grid>
    </Grid>
  );
};

export default AzureLicenseAgreement;
