import { AzureSupportDocument } from 'generated/graphql';
import { zip } from 'lodash';
import React from 'react';
import { Grid } from 'vendor/material';

import InfoDiffFile from '../InfoDiffFile/InfoDiffFile';
import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';

type AzureSupportDocumentsProps = {
  sourceSupportDocuments: Array<AzureSupportDocument> | null | undefined;
  revisionSupportDocuments: Array<AzureSupportDocument> | null | undefined;
};

const AzureSupportDocuments: React.FC<AzureSupportDocumentsProps> = ({
  sourceSupportDocuments = [],
  revisionSupportDocuments = [],
}) => {
  const combinedSupportDocuments = zip(
    sourceSupportDocuments ?? [],
    revisionSupportDocuments ?? [],
  );

  if (!combinedSupportDocuments?.length) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <InfoDiffItem title="Supporting Document Name 1" />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <InfoDiffItem title="Supporting Document Downloadable PDF 1" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {combinedSupportDocuments.map(([source, revision], index) => (
        <React.Fragment key={index}>
          <Grid item xs={6} md={6} lg={6}>
            <InfoDiffItem
              title={`Supporting Document Name ${index + 1}`}
              sourceValue={source?.name}
              revisionValue={revision?.name}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <InfoDiffFile
              title={`Supporting Document Downloadable PDF ${index + 1}`}
              sourceFile={source?.document_url}
              sourceFileName={source?.document_url?.split('/').pop()}
              revisionFile={revision?.document_url}
              revisionFileName={revision?.document_url?.split('/').pop()}
              type="pdf"
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default AzureSupportDocuments;
