import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { Container } from 'vendor/material';

import AuthErrorPage from './components/AuthError';
import DatadogUser from './components/DatadogUser';
import Header from './components/Header';
import Login from './components/Login';
import Logout from './components/Logout';
import Vendor from './pages/Vendor/Vendor';
import VendorListPage from './pages/VendorListPage';

interface CustomRouteProps extends RouteProps {
  path?: string;
}

const ProtectedRoute = (props: CustomRouteProps): React.ReactElement => {
  const { component, location, render, path, exact, sensitive, strict } = props;

  // @ts-ignore not really sure how to solve this easily, and not sure it matters
  const Wrapped = withAuthenticationRequired(component);
  return (
    <Route
      location={location}
      render={render}
      path={path}
      exact={exact}
      sensitive={sensitive}
      strict={strict}
      component={Wrapped}
    />
  );
};

const withAppChrome =
  <T,>(Component: React.ComponentType<T>) =>
  (props: React.PropsWithChildren<T>) =>
    (
      <>
        <Header />
        <Container>
          <Component {...props} />
        </Container>
      </>
    );

const Root: React.FC = () => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return null;
  }

  const rootRedirectPath = isAuthenticated ? '/vendors' : '/auth/callback';

  return <Redirect to={rootRedirectPath} />;
};

/**
 * TODO what the heck is up with this? if we remove this nothing renders
 * before trying to remove this, try to log in fresh from an incognito window
 */
const WhyDoWeNeedThisWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently();
  }, [getAccessTokenSilently]);

  return <>{children}</>;
};

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Root} />
      <Route path="/login" component={Login} />
      <Route path="/logout" component={Logout} />
      <Route path="/auth/callback" component={AuthErrorPage} />
      <WhyDoWeNeedThisWrapper>
        <DatadogUser />
        <ProtectedRoute
          path="/vendors"
          component={withAppChrome(VendorListPage)}
        />
        <ProtectedRoute
          path="/vendor/:vendorid"
          component={withAppChrome(Vendor)}
        />
      </WhyDoWeNeedThisWrapper>
    </Switch>
  );
};
export default Routes;
