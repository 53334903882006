import { gql } from '@apollo/client';
import { Button, Code, Modal, TextField } from '@tackle-io/platform-ui';
import { Field, FieldProps, Form, Formik } from 'formik';
import {
  useCreateSandboxVendorMutation,
  useVendorQuery,
  useVendorWithSandboxesQuery,
  Vendor,
} from 'generated/graphql';
import { useVendorRootMatch } from 'pages/Vendor/Vendor.hooks';
import { VendorEnvironmentEnum } from 'pages/Vendor/VendorEnvironment.hooks';
import { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from 'vendor/material';

import SandboxesTable from './components/SandboxesTable/SandboxesTable';
import { getSortedSandboxes } from './utils';

gql`
  mutation CreateSandboxVendor($vendor: CreateVendorInput!) {
    createVendor(data: $vendor) {
      id
      name
    }
  }
`;

gql`
  query VendorWithSandboxes($vendorid: ID!) {
    vendor(id: $vendorid) {
      id
      vendor_type
      name
      linkedSandboxes {
        id
        name
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1200,
    margin: '0 auto',
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down(1441)]: {
      maxWidth: '95%',
    },
    [theme.breakpoints.down(1367)]: {
      maxWidth: '90%',
    },
  },
}));

const CreateSandboxButton = ({
  productionVendorName,
  productionVendorId,
  sandboxes,
}: {
  productionVendorName?: string | null;
  productionVendorId?: string;
  sandboxes: Array<Vendor>;
}) => {
  const [isCreateSandboxModalOpen, setIsCreateSandboxModalOpen] =
    useState(false);
  const history = useHistory();
  const closeModal = (): void => setIsCreateSandboxModalOpen(false);
  const [createSandboxVendor, { loading, error }] =
    useCreateSandboxVendorMutation({
      update: (cache, { data }) => {
        const newVendor = data?.createVendor;
        if (newVendor) {
          cache.modify({
            id: cache.identify({
              __typename: 'Vendor',
              id: productionVendorId,
            }),
            fields: {
              linkedSandboxes(existingSandboxes = []) {
                const newSandboxRef = cache.writeFragment({
                  data: newVendor,
                  fragment: gql`
                    # eslint-disable-next-line @graphql-eslint/no-unused-fragments
                    fragment NewSandbox on Vendor {
                      id
                      name
                    }
                  `,
                });
                return [...existingSandboxes, newSandboxRef];
              },
            },
          });
        }
      },
    });
  const existingSandboxesCount = sandboxes.length;
  const defaultSandboxName = productionVendorName
    ? `${productionVendorName} [Test${
        existingSandboxesCount ? ` ${existingSandboxesCount + 1}` : ''
      }]`
    : '';

  return (
    <>
      <Button
        appearance={error ? 'destructive' : 'secondary'}
        variant="outlined"
        loading={loading}
        onClick={() => {
          setIsCreateSandboxModalOpen(!isCreateSandboxModalOpen);
        }}
      >
        CREATE TEST ENVIRONMENT
      </Button>
      {isCreateSandboxModalOpen && (
        <Modal
          open={isCreateSandboxModalOpen}
          title="Create a test environment"
          width="medium"
          onClose={closeModal}
        >
          <Formik
            initialValues={{
              name: defaultSandboxName,
              productionVendorId,
            }}
            onSubmit={async (values) => {
              const { data } = await createSandboxVendor({
                variables: {
                  vendor: {
                    name: values.name,
                    production_vendor_id: values.productionVendorId,
                    vendor_type: VendorEnvironmentEnum.SANDBOX,
                  },
                },
              });
              if (data?.createVendor?.id) {
                history.push(`/vendor/${data?.createVendor?.id}`);
              }
            }}
          >
            {({ errors }) => (
              <Form>
                <Grid container direction="column" spacing={2}>
                  {error && (
                    <Grid item>
                      <Typography color="error">
                        There was an error, if this persist please open a
                        support ticket: {error.message}
                      </Typography>
                      <Box maxHeight={200} width="100%" overflow="scroll">
                        <Code language="json">
                          {JSON.stringify(error, undefined, 2)}
                        </Code>
                      </Box>
                    </Grid>
                  )}

                  <Grid item container direction="column" spacing={2}>
                    <Grid item>
                      <p>
                        This test environment will be created for{' '}
                        {productionVendorName} ({productionVendorId})
                      </p>
                      <Field
                        name="name"
                        error={errors?.name}
                        default={productionVendorName}
                      >
                        {({ field }: FieldProps) => (
                          <TextField
                            {...field}
                            label="Test Environment Name"
                            disabled={true}
                          />
                        )}
                      </Field>
                      {errors?.name && (
                        <Typography color="error">{errors?.name}</Typography>
                      )}
                      <p>
                        Note: Feature flags will be inherited from the
                        production vendor
                      </p>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} container justify="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        appearance="primary"
                        disabled={loading}
                        variant="text"
                        onClick={closeModal}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        appearance="primary"
                        disabled={loading}
                        type="submit"
                        variant="text"
                        loading={loading}
                      >
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

const VendorSandboxes = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { params: rootParams } = useVendorRootMatch()!;
  const { vendorid } = rootParams;
  const { data, loading, error } = useVendorQuery({
    variables: { id: vendorid },
  });

  const productionVendorName = data?.vendor?.name;
  const productionVendorId = data?.vendor?.id;

  const variables = {
    vendorid: productionVendorId as string,
  };

  const { data: vendorWithSandboxes, loading: loadingVendorWithSandboxes } =
    useVendorWithSandboxesQuery({
      variables,
      notifyOnNetworkStatusChange: true,
      skip: !productionVendorId,
    });

  const sandboxes = (vendorWithSandboxes?.vendor?.linkedSandboxes ||
    []) as Array<Vendor>;

  if (loading) {
    return (
      <div className={classes.container}>
        <Box px={6} py={3} textAlign="center">
          <CircularProgress
            size={40}
            style={{ color: theme.palette.BLUE400 }}
          />
        </Box>
      </div>
    );
  }
  if (error || !data?.vendor) {
    return <Redirect to="/vendors" />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CreateSandboxButton
          productionVendorName={productionVendorName}
          productionVendorId={productionVendorId}
          sandboxes={sandboxes}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {!loadingVendorWithSandboxes && sandboxes?.length ? (
          <SandboxesTable data={getSortedSandboxes(sandboxes)} />
        ) : null}
        {!loadingVendorWithSandboxes && sandboxes.length === 0 && (
          <Typography variant="subtitle1">
            No test environments to display
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default VendorSandboxes;
