import { AzureOverviewUrl } from 'generated/graphql';
import { zip } from 'lodash';
import React from 'react';
import { Grid } from 'vendor/material';

import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';

type AzureOverviewUrlsProps = {
  sourceOverviewUrls: Array<AzureOverviewUrl> | null | undefined;
  revisionOverviewUrls: Array<AzureOverviewUrl> | null | undefined;
};

const AzureOverviewUrls: React.FC<AzureOverviewUrlsProps> = ({
  sourceOverviewUrls,
  revisionOverviewUrls,
}) => {
  const combinedOverviewUrls = zip(
    sourceOverviewUrls ?? [],
    revisionOverviewUrls ?? [],
  );

  if (!combinedOverviewUrls?.length) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <InfoDiffItem title="Useful Link Title 1" />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <InfoDiffItem title="Useful Link URL 1" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {combinedOverviewUrls?.map(
        ([sourceOverviewUrl, revisionOverviewUrl], ind) => (
          <React.Fragment key={ind}>
            <Grid item xs={6} md={6} lg={6}>
              <InfoDiffItem
                title={`Useful Link Title ${ind + 1}`}
                sourceValue={sourceOverviewUrl?.title}
                revisionValue={revisionOverviewUrl?.title}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <InfoDiffItem
                title={`Useful Link URL ${ind + 1}`}
                sourceValue={sourceOverviewUrl?.url}
                revisionValue={revisionOverviewUrl?.url}
              />
            </Grid>
          </React.Fragment>
        ),
      )}
    </Grid>
  );
};

export default AzureOverviewUrls;
