import { gql } from '@apollo/client';
import { Button, Modal } from '@tackle-io/platform-ui';
import {
  useMergeListingButtonMergeAwsListingMutation,
  useMergeListingButtonMergeAzureListingMutation,
  useMergeListingButtonMergeGcpListingMutation,
} from 'generated/graphql';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, makeStyles, Typography } from 'vendor/material';

gql`
  mutation MergeListingButtonMergeAwsListing($id: ID!) {
    mergeAwsListingRevision(id: $id) {
      id
    }
  }
`;

gql`
  mutation MergeListingButtonMergeAzureListing($id: ID!) {
    mergeAzureListingRevision(id: $id) {
      id
    }
  }
`;

gql`
  mutation MergeListingButtonMergeGcpListing($id: ID!) {
    mergeGcpListingRevision(id: $id) {
      id
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    paddingRight: theme.spacing(2),
  },
}));

interface MergeListingButtonProps {
  productidInternal: string;
  marketplace: 'aws' | 'gcp' | 'azure' | 'redhat';
}

const MergeListingButton = ({
  productidInternal,
  marketplace,
}: MergeListingButtonProps) => {
  const history = useHistory();
  const { vendorid } = useParams<{
    listingid: string;
    vendorid: string;
  }>()!;
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const refetchQueries = ['vendorListings'];

  const [
    mergeAwsListing,
    { loading: loadingMergeAwsListing, error: errorMergeAwsListing },
  ] = useMergeListingButtonMergeAwsListingMutation({
    variables: {
      id: productidInternal,
    },
    update(cache, { data }) {
      if (!data?.mergeAwsListingRevision) return;
      cache.evict({
        id: cache.identify(data?.mergeAwsListingRevision),
      });
      cache.gc();
    },
    refetchQueries,
  });

  const [
    mergeAzureListing,
    { loading: loadingMergeAzureListing, error: errorMergeAzureListing },
  ] = useMergeListingButtonMergeAzureListingMutation({
    variables: {
      id: productidInternal,
    },
    update(cache, { data }) {
      if (!data?.mergeAzureListingRevision) return;
      cache.evict({
        id: cache.identify(data?.mergeAzureListingRevision),
      });
      cache.gc();
    },
    refetchQueries,
  });

  const [
    mergeGcpListing,
    { loading: loadingMergeGcpListing, error: errorMergeGcpListing },
  ] = useMergeListingButtonMergeGcpListingMutation({
    variables: {
      id: productidInternal,
    },
    update(cache, { data }) {
      if (!data?.mergeGcpListingRevision) return;
      cache.evict({
        id: cache.identify(data?.mergeGcpListingRevision),
      });
      cache.gc();
    },
    refetchQueries,
  });

  const loading =
    loadingMergeAwsListing ||
    loadingMergeAzureListing ||
    loadingMergeGcpListing;

  const hasMergeError =
    errorMergeAwsListing || errorMergeAzureListing || errorMergeGcpListing;

  if (marketplace === 'redhat') {
    return null;
  }

  const handleMergeListing = async () => {
    let id;
    if (marketplace === 'aws') {
      const { data } = await mergeAwsListing();
      id = data?.mergeAwsListingRevision?.id;
    }

    if (marketplace === 'azure') {
      const { data } = await mergeAzureListing();
      id = data?.mergeAzureListingRevision?.id;
    }

    if (marketplace === 'gcp') {
      const { data } = await mergeGcpListing();
      id = data?.mergeGcpListingRevision?.id;
    }

    history.push(`/vendor/${vendorid}/listings/${id}`);
  };

  return (
    <Box className={classes.buttonContainer}>
      <Modal
        title="Confirmation Modal"
        width="medium"
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        footerActions={
          <>
            <Button
              disabled={loading}
              appearance="primary"
              variant="text"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              type="submit"
              appearance={hasMergeError ? 'destructive' : 'success'}
              variant="text"
              onClick={handleMergeListing}
            >
              {hasMergeError ? 'Try again' : 'Yes'}
            </Button>
          </>
        }
      >
        <>
          <Typography>
            You are about to merge these edits with it source listing, after
            merging the listing revision will be hard deleted, Do you want to
            proceed?
          </Typography>
        </>
      </Modal>
      <Button
        size="small"
        variant="outlined"
        appearance="success"
        onClick={() => setIsModalOpen(true)}
      >
        Merge Edits
      </Button>
    </Box>
  );
};

export default MergeListingButton;
