import { gql } from '@apollo/client';
import { Button, ProviderIcon } from '@tackle-io/platform-ui';
import {
  useCreateAwsConsoleUrlMutation,
  useVendorForAwsConsoleButtonQuery,
} from 'generated/graphql';
import { useParams } from 'react-router-dom';

export const VENDOR_FOR_AWS_CONSOLE_BUTTON_QUERY = gql`
  query VendorForAwsConsoleButton($id: ID!) {
    vendor(id: $id) {
      id
      canAccessAwsConsole
    }
  }
`;

export const CREATE_AWS_CONSOLE_URL_MUTATION = gql`
  mutation CreateAwsConsoleUrl($vendorid: ID!, $targetUrl: String) {
    createAwsConsoleUrl(vendorId: $vendorid, targetUrl: $targetUrl)
  }
`;

const AwsConsoleButton = () => {
  const { vendorid } = useParams<{ vendorid: string }>();
  const { data } = useVendorForAwsConsoleButtonQuery({
    variables: {
      id: vendorid,
    },
  });

  const [mutateAsync, { loading, error }] = useCreateAwsConsoleUrlMutation();

  if (!data?.vendor?.canAccessAwsConsole) {
    return null; // only show this button if the vendor has access to the AWS console
  }

  return (
    <Button
      startIcon={<ProviderIcon provider="aws" />}
      onClick={async (): Promise<void> => {
        try {
          const response = await mutateAsync({
            variables: {
              vendorid,
              targetUrl:
                'https://aws.amazon.com/marketplace/management/homepage',
            },
          });
          if (response.data?.createAwsConsoleUrl) {
            window.open(response.data?.createAwsConsoleUrl, '_blank');
          }
        } catch (error) {
          console.error(error);
        }
      }}
      size="small"
      variant="outlined"
      appearance={error ? 'destructive' : 'secondary'}
      disabled={loading}
      loading={loading}
    >
      MARKETPLACE PORTAL
    </Button>
  );
};

export default AwsConsoleButton;
