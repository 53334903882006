import { AwsUsageFee } from 'generated/graphql';
import { zip } from 'lodash';
import React from 'react';
import { Grid } from 'vendor/material';

import { formatPrice } from '../../VendorListings.helpers';
import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';

type AwsUsageFeesProps = {
  sourceUsageFees: Array<AwsUsageFee> | null | undefined;
  revisionUsageFees: Array<AwsUsageFee> | null | undefined;
};

const AwsUsageFees: React.FC<AwsUsageFeesProps> = ({
  sourceUsageFees,
  revisionUsageFees,
}) => {
  const awsUsageFees = zip(sourceUsageFees ?? [], revisionUsageFees ?? []);

  if (!awsUsageFees?.length) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4} md={4} lg={4}>
          <InfoDiffItem title="Usage Fees" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {awsUsageFees.map(([source, revision], ind) => {
        return (
          <React.Fragment key={source?.sku || revision?.sku}>
            <Grid item xs={4} md={4} lg={4}>
              <InfoDiffItem
                title={`Usage Fee SKU ${ind + 1}`}
                sourceValue={source?.sku}
                revisionValue={revision?.sku}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <InfoDiffItem
                title={`Usage Fee Description ${ind + 1}`}
                sourceValue={source?.description}
                revisionValue={revision?.description}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <InfoDiffItem
                title={`Usage Fee Price ${ind + 1}`}
                sourceValue={
                  source?.price ? formatPrice(source?.price ?? '') : ''
                }
                revisionValue={
                  revision?.price ? formatPrice(revision?.price ?? '') : ''
                }
              />
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

export default AwsUsageFees;
