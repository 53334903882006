import { HtmlTooltip, InfoItem as PuiInfoItem } from '@tackle-io/platform-ui';
import { FilePdfBox, ImageArea } from 'mdi-material-ui';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import forceBrowserDownload from 'utils/forceBrowserDownload';
import { Typography } from 'vendor/material';
import { makeStyles, useTheme } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: theme.palette.NEUTRAL010,
    border: `1px solid ${theme.palette.NEUTRAL030}`,
    padding: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    borderRadius: 4,
    cursor: 'pointer',
  },
  fileIcon: {
    color: theme.palette.NEUTRAL500,
    fontSize: theme.typography.pxToRem(24),
    marginRight: theme.spacing(1),
    display: 'flex',
  },
  text: {
    cursor: 'pointer',
  },
}));

const PopoverTitle = ({
  file,
  name,
  type,
}: {
  type: string;
  file: string;
  name: string;
}) => {
  if (type === 'pdf') {
    return <Typography component="p">Click to download</Typography>;
  }

  return (
    <div>
      <img
        alt={name}
        src={file}
        style={{ maxWidth: '50vw', maxHeight: '50wh' }}
      />
    </div>
  );
};

const InfoDiffFile = ({
  type,
  sourceFile,
  revisionFile,
  sourceFileName,
  revisionFileName,
  title,
}: {
  type: 'pdf' | 'picture';
  sourceFileName: string | null | undefined;
  sourceFile: string | null | undefined;
  revisionFileName: string | null | undefined;
  revisionFile: string | null | undefined;
  title: string;
}) => {
  const theme = useTheme();
  const classes = useStyles();
  if ((!sourceFileName || !sourceFile) && (!revisionFile || !revisionFileName))
    return null;

  const handleDownload = async (): Promise<void> => {
    if (revisionFile && type === 'pdf') {
      await forceBrowserDownload(
        revisionFile,
        revisionFileName || undefined,
        'pdf',
      );
    }
  };

  return (
    <PuiInfoItem title={title}>
      <HtmlTooltip
        interactive
        placement="bottom"
        title={
          <PopoverTitle
            type={type}
            name={revisionFileName ?? ''}
            file={revisionFile ?? ''}
          />
        }
      >
        <div
          className={classes.container}
          onClick={handleDownload}
          role="presentation"
        >
          <div className={classes.fileIcon}>
            {type === 'picture' && <ImageArea fontSize="inherit" />}
            {type === 'pdf' && <FilePdfBox fontSize="inherit" />}
          </div>
          <ReactDiffViewer
            oldValue={sourceFileName ?? ''}
            newValue={revisionFileName ?? ''}
            splitView={false}
            hideLineNumbers
            showDiffOnly={false}
            compareMethod={DiffMethod.WORDS}
            styles={{
              variables: {
                light: {
                  diffViewerBackground: theme.palette.NEUTRAL010,
                  diffViewerColor: theme.palette.NEUTRAL500,
                },
              },
            }}
          />
        </div>
      </HtmlTooltip>
    </PuiInfoItem>
  );
};

export default InfoDiffFile;
