export const AWS_AMI_LISTING_TYPES: string[] = [
  'ami-contract',
  'ami-hourly-annual',
  'single-ami-with-cft',
];

const AWS: string = 'aws';
const AZURE: string = 'azure';
const REDHAT: string = 'redhat';
const GCP: string = 'gcp';

export const MARKETPLACE_NAMES_MAP = {
  [AWS]: 'Amazon',
  [AZURE]: 'Azure',
  [REDHAT]: 'Red Hat',
  [GCP]: 'GCM',
};

export const MARKETPLACES: Array<string> = [AWS, AZURE, REDHAT, GCP];
