import { AWS_AMI_LISTING_TYPES } from './constant';

export const isAwsAmiListing = (listingType: string | null | undefined) =>
  Boolean(listingType && AWS_AMI_LISTING_TYPES.includes(listingType));

// Create and download a CSV file given a String
// as found in SO: https://stackoverflow.com/questions/62591592/how-to-download-a-json-object-as-file
export const downloadCsvFile = (
  data: string | undefined | null,
  fileName: string,
): void => {
  if (!data) {
    return;
  }
  const blob = new Blob([data], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const downloadedFileName = fileName;
  link.download = downloadedFileName;
  link.click();
  link.remove();
};
