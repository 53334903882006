import {
  AwsListing,
  AzureListing,
  GcpListing,
  RedHatListing,
} from 'generated/graphql';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { VendorRouteParams } from './VendorListings.types';

const useVendorListingsRootMatch = () =>
  useRouteMatch<VendorRouteParams>('/vendor/:vendorid/listings');

export type UseActiveListingProps = {
  listings: Array<AwsListing | AzureListing | RedHatListing | GcpListing>;
};

export type UseActiveListingResult = {
  pathListingId: string | null;
  activeListingId: string | null;
  onClickListing: (listingId: string) => void;
};

const useActiveListing = ({
  listings,
}: UseActiveListingProps): UseActiveListingResult => {
  const history = useHistory();
  const rootMatch = useVendorListingsRootMatch();
  const listingidMatch = useRouteMatch<VendorRouteParams>(
    '/vendor/:vendorid/listings/:listingid',
  );

  const pathListingId = listingidMatch?.params?.listingid || null;

  const activeListing = pathListingId
    ? listings.find(
        (listing) =>
          listing.id === pathListingId ||
          listing.marketplace_id === pathListingId,
      )
    : null;

  const activeListingId = activeListing?.id || null;

  const handleToggleListing = (listingId: string): void => {
    if (listingId === activeListingId) {
      history.push(rootMatch?.url);
    } else {
      history.push(`${rootMatch?.url}/${listingId}`);
    }
  };

  return {
    pathListingId,
    activeListingId,
    onClickListing: handleToggleListing,
  };
};

export { useActiveListing, useVendorListingsRootMatch };
