import { GcpLicenseAgreement } from 'generated/graphql';
import { zip } from 'lodash';
import React from 'react';
import { Grid } from 'vendor/material';

import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';

type GcpLicenseAgreementsProps = {
  sourceLicenseAgreements: Array<GcpLicenseAgreement> | null | undefined;
  revisionLicenseAgreements: Array<GcpLicenseAgreement> | null | undefined;
};

const GcpLicenseAgreements: React.FC<GcpLicenseAgreementsProps> = ({
  sourceLicenseAgreements = [],
  revisionLicenseAgreements = [],
}) => {
  const combinedAgreements = zip(
    sourceLicenseAgreements ?? [],
    revisionLicenseAgreements ?? [],
  );

  if (!combinedAgreements || combinedAgreements.length === 0) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <InfoDiffItem title="Additional License Agreement 1 Title" />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <InfoDiffItem title="Additional License Agreement 1 Description" />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={2}>
      {combinedAgreements.map(([source, revision], index) => (
        <React.Fragment key={index}>
          <Grid item xs={6} md={6} lg={6}>
            <InfoDiffItem
              title={`Additional License Agreement ${index + 1} Title`}
              sourceValue={source?.title}
              revisionValue={revision?.title}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <InfoDiffItem
              title={`Additional License Agreement ${index + 1} Description`}
              sourceValue={source?.url}
              revisionValue={revision?.url}
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default GcpLicenseAgreements;
