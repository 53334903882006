import { Divider, FormSection } from '@tackle-io/platform-ui';
import {
  AzurePlan,
  AzurePricing as AzurePricingType,
  Maybe,
} from 'generated/graphql';
import { zip } from 'lodash';
import { findIndex } from 'lodash-es';
import { PackageVariant } from 'mdi-material-ui';
import React from 'react';
import { Box, Grid } from 'vendor/material';

import { formatPrice } from '../../VendorListings.helpers';
import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';
import SectionIcon from '../SectionIcon/SectionIcon';

type AzurePricingProps = {
  sourcePricing: AzurePricingType | null | undefined;
  revisionPricing: AzurePricingType | null | undefined;
};

const AzureMetrics: React.FC<AzurePricingProps> = ({
  sourcePricing,
  revisionPricing,
}) => {
  const getPlanNameForMetric = (
    plans: AzurePricingType['plans'],
    plan_uuid: Maybe<string> | undefined,
  ): string => {
    const planForMetric = plans?.find((plan) => plan.plan_uuid === plan_uuid);

    if (planForMetric?.name) return planForMetric.name;

    const planIndex: number = findIndex(
      plans as AzurePlan[],
      (p) => p.plan_uuid === plan_uuid,
    );

    return `Plan ${planIndex + 1}`;
  };

  const combinedMetrics = zip(
    sourcePricing?.metrics ?? [],
    revisionPricing?.metrics ?? [],
  );

  return (
    <>
      {combinedMetrics?.map(([sourceMetric, revisionMetric], index) => {
        return (
          <Box mt={3} key={index}>
            <FormSection
              title={
                <SectionIcon
                  icon={<PackageVariant fontSize="inherit" />}
                  title={`Metric - ${
                    revisionMetric?.display_name ||
                    sourceMetric?.display_name ||
                    `Metric ${index + 1}`
                  }`}
                />
              }
              mb={1.5}
            >
              <Grid container spacing={2}>
                <Grid item xs={6} md={4} lg={3}>
                  <InfoDiffItem
                    title="Metric ID"
                    sourceValue={sourceMetric?.sku}
                    revisionValue={revisionMetric?.sku}
                  />
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                  <InfoDiffItem
                    title="Display Name"
                    sourceValue={sourceMetric?.display_name}
                    revisionValue={revisionMetric?.display_name}
                  />
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                  <InfoDiffItem
                    title="Unit of Measure"
                    sourceValue={sourceMetric?.product_unit}
                    revisionValue={revisionMetric?.product_unit}
                  />
                </Grid>
              </Grid>
              {zip(
                sourceMetric?.plan_pricing ?? [],
                revisionMetric?.plan_pricing ?? [],
              ).map(([sourcePlanPricing, revisionPlanPricing]) => {
                return (
                  <React.Fragment
                    key={
                      revisionPlanPricing?.plan_uuid ||
                      sourcePlanPricing?.plan_uuid
                    }
                  >
                    <Box mt={2} mb={2}>
                      <Divider />
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <InfoDiffItem
                          title="Plan Name"
                          sourceValue={getPlanNameForMetric(
                            sourcePricing?.plans,
                            sourcePlanPricing?.plan_uuid,
                          )}
                          revisionValue={getPlanNameForMetric(
                            revisionPricing?.plans,
                            revisionPlanPricing?.plan_uuid,
                          )}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <InfoDiffItem
                          title="Price per Unit"
                          sourceValue={
                            sourcePlanPricing?.price_per_unit
                              ? formatPrice(sourcePlanPricing?.price_per_unit)
                              : '-'
                          }
                          revisionValue={
                            revisionPlanPricing?.price_per_unit
                              ? formatPrice(revisionPlanPricing?.price_per_unit)
                              : '-'
                          }
                        />
                      </Grid>
                      {[
                        {
                          title: '1-month',
                          value: 1,
                          type: 'month',
                        },
                        {
                          title: '1-year',
                          value: 1,
                          type: 'year',
                        },
                        {
                          title: '2-year',
                          value: 2,
                          type: 'year',
                        },
                        {
                          title: '3-year',
                          value: 3,
                          type: 'year',
                        },
                      ]?.map((bq, index) => {
                        const sourceBaseQuantity =
                          sourcePlanPricing?.base_quantity?.find(
                            (b) =>
                              b?.type === bq?.type && b?.value === bq?.value,
                          );
                        const revisionBaseQuantity =
                          revisionPlanPricing?.base_quantity?.find(
                            (b) =>
                              b?.type === bq?.type && b?.value === bq?.value,
                          );

                        return (
                          <Grid item xs key={index}>
                            <InfoDiffItem
                              title={`${bq?.title} quantity included in base`}
                              sourceValue={
                                sourceBaseQuantity?.unlimited
                                  ? 'Unlimited'
                                  : sourceBaseQuantity?.quantity?.toString()
                              }
                              revisionValue={
                                revisionBaseQuantity?.unlimited
                                  ? 'Unlimited'
                                  : revisionBaseQuantity?.quantity?.toString()
                              }
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </React.Fragment>
                );
              })}
            </FormSection>
          </Box>
        );
      })}
    </>
  );
};

export default AzureMetrics;
