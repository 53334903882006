import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: theme.typography.pxToRem(20),
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  title: {},
}));

export default useStyles;
