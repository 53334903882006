import { gql } from '@apollo/client';
import { Button, Checkbox, Code, Link, Modal } from '@tackle-io/platform-ui';
import {
  useCreateNewAwsProductAndOfferFromTackleMutation,
  useDescribeAwsChangeSetLazyQuery,
  useUpdateAwsProductAndOfferFromTackleMutation,
  useUpdateListingWithCapiModalButtonQuery,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
} from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    paddingRight: theme.spacing(2),
  },
  codeContainer: { maxHeight: 400, overflow: 'auto', maxWidth: '100%' },
  checkboxWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
}));

gql`
  query UpdateListingWithCapiModalButton($listingId: ID!) {
    awsListing(id: $listingId) {
      id
      revision_of_product_id_internal
      capi_change_set_id
    }
  }
`;

gql`
  query DescribeAwsChangeSet($vendorId: ID!, $changeSetId: ID!) {
    describeAwsChangeSet(vendor_id: $vendorId, change_set_id: $changeSetId) {
      id: ChangeSetId
      ChangeSetId
      ChangeSetArn
      ChangeSetName
      Intent
      StartTime
      EndTime
      Status
      FailureCode
      FailureDescription
      ChangeSet {
        ChangeType
        Entity {
          Identifier
          Type
        }
        DetailsDocument
        ErrorDetailList {
          ErrorCode
          ErrorMessage
        }
        ChangeName
      }
    }
  }
`;

gql`
  mutation CreateNewAwsProductAndOfferFromTackle(
    $listingId: ID!
    $input: CreateOrUpdateListingWithCapiInput!
  ) {
    createNewAwsProductAndOfferFromTackle(id: $listingId, input: $input) {
      id: ChangeSetId
      ChangeSetId
      ChangeSetArn
    }
  }
`;

gql`
  mutation UpdateAwsProductAndOfferFromTackle(
    $listingId: ID!
    $input: CreateOrUpdateListingWithCapiInput!
  ) {
    updateAwsProductAndOfferFromTackle(id: $listingId, input: $input) {
      id: ChangeSetId
      ChangeSetId
      ChangeSetArn
    }
  }
`;

const UpdateListingWithCapiModalButton = () => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const match = useRouteMatch<{ vendorId: string; listingId: string }>(
    '/vendor/:vendorId/listings/:listingId',
  );

  const { data, loading: queryLoading } =
    useUpdateListingWithCapiModalButtonQuery({
      variables: { listingId: match?.params.listingId! },
    });

  const isRevision = Boolean(data?.awsListing?.revision_of_product_id_internal);

  const [overrideToPennyPricing, setOverrideToPennyPricing] =
    useState<boolean>(false);
  useEffect(() => {
    setOverrideToPennyPricing(!isRevision);
  }, [isRevision]);
  const handlePennyCheckBoxChange = () => {
    overrideToPennyPricing
      ? setOverrideToPennyPricing(false)
      : setOverrideToPennyPricing(true);
  };

  const [
    createProductWithCapi,
    {
      data: createData,
      loading: createLoading,
      error: createError,
      called: createCalled,
    },
  ] = useCreateNewAwsProductAndOfferFromTackleMutation({
    variables: {
      listingId: match?.params.listingId!,
      input: {
        overrideToPennyPricing,
      },
    },
  });
  const [
    updateProductWithCapi,
    {
      data: updateData,
      loading: updateLoading,
      error: updateError,
      called: updateCalled,
    },
  ] = useUpdateAwsProductAndOfferFromTackleMutation({
    variables: {
      listingId: match?.params.listingId!,
      input: {
        overrideToPennyPricing,
      },
    },
  });

  const loading = queryLoading || createLoading || updateLoading;
  const mutationError = createError || updateError;
  const mutationCalled = createCalled || updateCalled;
  const ChangeSetId =
    data?.awsListing?.capi_change_set_id ||
    (
      createData?.createNewAwsProductAndOfferFromTackle ||
      updateData?.updateAwsProductAndOfferFromTackle
    )?.ChangeSetId;

  const [isPolling, setIsPolling] = useState(false);
  const [
    ,
    { data: describeData, error: describeError, startPolling, stopPolling },
  ] = useDescribeAwsChangeSetLazyQuery({
    variables: {
      vendorId: match?.params.vendorId!,
      changeSetId: ChangeSetId!,
    },
  });

  useEffect(() => {
    if (ChangeSetId) {
      setIsPolling(true);
      startPolling(3000);
    }
  }, [ChangeSetId, startPolling]);

  useEffect(() => {
    if (
      describeError ||
      describeData?.describeAwsChangeSet?.Status === 'SUCCEEDED' ||
      describeData?.describeAwsChangeSet?.Status === 'FAILED' ||
      describeData?.describeAwsChangeSet?.Status === 'CANCELLED'
    ) {
      setIsPolling(false);
      stopPolling();
    }
  }, [describeData?.describeAwsChangeSet?.Status, describeError, stopPolling]);

  if (queryLoading) {
    return null;
  }

  return (
    <Box className={classes.buttonContainer}>
      <Modal
        showCloseButton
        title={`${isRevision ? 'Update' : 'Create new'} listing with CAPI`}
        width="xlarge"
        open={isModalOpen}
        // @ts-ignore
        onClose={(
          event: React.MouseEvent<Element, MouseEvent>,
          reason: 'escapeKeyDown' | 'backdropClick',
        ) => {
          if (reason !== 'backdropClick') {
            setIsModalOpen(false);
          }
        }}
        footerActions={
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item>
              <FormControlLabel
                label="Override pricing to tenth of a penny"
                disabled={loading || mutationCalled}
                control={
                  <Box mx={1} className={classes.checkboxWrapper}>
                    <Checkbox
                      onChange={handlePennyCheckBoxChange}
                      disabled={loading || mutationCalled}
                      checked={overrideToPennyPricing}
                      disableRipple={false}
                      aria-label="Override pricing to tenth of a penny"
                    />
                  </Box>
                }
              />
            </Grid>
            <Grid item>
              <Button
                appearance="primary"
                variant="text"
                onClick={() => setIsModalOpen(false)}
              >
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button
                loading={loading}
                disabled={loading || Boolean(ChangeSetId) || mutationCalled}
                type="submit"
                appearance={mutationError ? 'destructive' : 'success'}
                variant="text"
                onClick={() =>
                  isRevision ? updateProductWithCapi() : createProductWithCapi()
                }
              >
                {isRevision ? 'Update with CAPI' : 'Create with CAPI'}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography>
              After proceeding we will submit the data to AWS and display the
              response below. Some of the updates take a while to process. Once
              it has completed or failed please{' '}
              <strong>
                log into AMMP and double check that everything updated as
                expected
              </strong>
              . If you have questions or concerns please reach out to the{' '}
              <Link
                href="https://tackleio.slack.com/archives/CQKRCUBJS"
                external
              >
                #pod-street-sharks
              </Link>{' '}
              in slack.
            </Typography>
          </Grid>
          <hr />
          <Grid item container spacing={2}>
            <Grid item>
              Change Set Id:{' '}
              {loading ? <CircularProgress size={14} /> : ChangeSetId ?? '--'}
            </Grid>
            <Grid item>
              Change Set Status:{' '}
              {describeData?.describeAwsChangeSet?.Status ?? '--'}{' '}
              {isPolling && <CircularProgress size={14} />}
            </Grid>
          </Grid>

          {mutationError && (
            <>
              <Grid item>
                <Typography color="error" variant="caption">
                  Creation Error
                </Typography>
              </Grid>
              <Grid item className={classes.codeContainer}>
                <Code language="json">
                  {JSON.stringify(mutationError, undefined, 2)}
                </Code>
              </Grid>
            </>
          )}
          {describeError && (
            <>
              <Grid item>
                <Typography color="error" variant="caption">
                  Describe Error
                </Typography>
              </Grid>
              <Grid item className={classes.codeContainer}>
                <Code language="json">
                  {JSON.stringify(describeError, undefined, 2)}
                </Code>
              </Grid>
            </>
          )}
          {describeData?.describeAwsChangeSet && (
            <>
              <Grid item>
                <Typography variant="caption">Change Set Details</Typography>
              </Grid>
              <Grid item className={classes.codeContainer}>
                <Code language="json">
                  {JSON.stringify(
                    describeData?.describeAwsChangeSet,
                    undefined,
                    2,
                  )}
                </Code>
              </Grid>
            </>
          )}
        </Grid>
      </Modal>
      <Button
        size="small"
        variant="outlined"
        appearance="secondary"
        onClick={() => setIsModalOpen(true)}
      >
        {isRevision
          ? 'Update listing with CAPI'
          : 'Create new listing with CAPI'}
      </Button>
    </Box>
  );
};

export default UpdateListingWithCapiModalButton;
