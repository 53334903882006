import { AwsSupportItem } from 'generated/graphql';
import { zip } from 'lodash';
import React from 'react';
import { Grid } from 'vendor/material';

import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';

type AwsSupportItemsProps = {
  sourceSupportItems?: Array<AwsSupportItem> | null | undefined;
  revisionSupportItems?: Array<AwsSupportItem> | null | undefined;
};

const AwsSupportItems: React.FC<AwsSupportItemsProps> = ({
  sourceSupportItems,
  revisionSupportItems,
}) => {
  const combinedAwsSupportItems = zip(
    sourceSupportItems ?? [],
    revisionSupportItems ?? [],
  );

  if (!combinedAwsSupportItems?.length) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <InfoDiffItem title="Additional Support Item (Learning Resource) Names" />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <InfoDiffItem title="Additional Support Item (Learning Resource) URLs" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {combinedAwsSupportItems?.map(([source, revision], ind) => {
        return (
          <React.Fragment key={ind}>
            <Grid item xs={6} md={6} lg={6}>
              <InfoDiffItem
                title={`Additional Support Item (Learning Resource) Name ${
                  ind + 1
                }`}
                sourceValue={source?.display_name}
                revisionValue={revision?.display_name}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <InfoDiffItem
                title={`Additional Support Item (Learning Resource) URL ${
                  ind + 1
                }`}
                sourceValue={source?.support_resource}
                revisionValue={revision?.support_resource}
              />
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

export default AwsSupportItems;
