import { datadogRum } from '@datadog/browser-rum';
import { User } from 'generated/graphql';

import buildEnvironment from './buildEnvironment';

const datadogEnabled = (): boolean => {
  const applicationId = process.env.REACT_APP_DATADOG_APP_ID;
  const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;

  return Boolean(applicationId && clientToken);
};

const initDatadog = (): void => {
  const applicationId = process.env.REACT_APP_DATADOG_APP_ID!;
  const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN!;
  const version = process.env.REACT_APP_RELEASE_SHA!;
  const env = buildEnvironment.environment;
  const upstreamApiUrl = process.env.REACT_APP_API_URL!;
  const tackleGqlUrl = process.env.REACT_APP_GRAPHQL_SERVER_URL!;

  if (!datadogEnabled()) return;
  datadogRum.init({
    applicationId,
    clientToken,
    env,
    site: 'datadoghq.com',
    service: 'admin-dashboard',
    version,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [upstreamApiUrl, tackleGqlUrl],
  });

  datadogRum.startSessionReplayRecording();
};

const setUser = (user: User): void => {
  if (!datadogEnabled()) return;

  datadogRum.setUser({
    ...user,
    id: user.id,
    email: user.email ?? undefined,
    name: user.name ?? undefined,
  });
};

const removeUser = (): void => {
  if (!datadogEnabled()) return;

  datadogRum.removeUser();
};

export { initDatadog, removeUser, setUser };
