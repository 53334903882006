import classNames from 'classnames';
import { makeStyles } from 'vendor/material';

import {
  getVendorEnvironmentLabel,
  themeColors,
  useEnvironmentColor,
  VendorEnvironmentEnum,
} from '../VendorEnvironment.hooks';

const useStyles = makeStyles((theme) => ({
  img: { objectFit: 'contain' },
  card: { height: '100%' },
  lightText: {
    color: themeColors.NEUTRAL000,
  },
  darkText: {
    color: themeColors.TEAL900,
  },
}));

export const EnvironmentTag = ({ environment }: { environment: string }) => {
  const tagColor = useEnvironmentColor(environment);
  const classes = useStyles();

  const isLightText =
    environment === VendorEnvironmentEnum.PROD ||
    environment === VendorEnvironmentEnum.DEV;
  const cssEnvironmentTag = classNames({
    [classes.lightText]: isLightText,
    [classes.darkText]: !isLightText,
  });

  return (
    <div
      className={cssEnvironmentTag}
      style={{
        backgroundColor: tagColor,
        padding: '4px 8px',
        borderRadius: '3px',
        fontSize: '12px',
        fontWeight: 600,
      }}
    >
      {getVendorEnvironmentLabel(environment)}
    </div>
  );
};
