import { AwsCategory } from 'generated/graphql';
import { zip } from 'lodash';
import React from 'react';
import { Grid, GridSize } from 'vendor/material';

import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';

type AwsCategoriesProps = {
  sourceCategories: Array<AwsCategory> | null | undefined;
  revisionCategories: Array<AwsCategory> | null | undefined;
};

const AwsCategories: React.FC<AwsCategoriesProps> = ({
  sourceCategories,
  revisionCategories,
}) => {
  const combinedAwsCategories = zip(
    sourceCategories ?? [],
    revisionCategories ?? [],
  );

  if (!combinedAwsCategories?.length) {
    return (
      <Grid item xs={12} md={12} lg={12}>
        <InfoDiffItem title="Product categories" />
        <br />
        <InfoDiffItem title="Product subcategories" />
      </Grid>
    );
  }

  const width: GridSize = (12 /
    (((sourceCategories?.length || 0) > (revisionCategories?.length || 0)
      ? sourceCategories?.length
      : revisionCategories?.length) ?? 1)) as GridSize;
  return (
    <>
      {combinedAwsCategories?.map(([source, revision], ind) => {
        return (
          <Grid key={ind} item xs={width} md={width} lg={width}>
            <InfoDiffItem
              title={`Product category ${ind + 1}`}
              sourceValue={source?.category}
              revisionValue={revision?.category}
            />
            <br />
            <InfoDiffItem
              title={`Product subcategory ${ind + 1}`}
              sourceValue={source?.subcategory}
              revisionValue={revision?.subcategory}
            />
          </Grid>
        );
      })}
    </>
  );
};

export default AwsCategories;
