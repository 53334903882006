import { FormSection } from '@tackle-io/platform-ui';
import { InfoItem as PuiInfoItem } from '@tackle-io/platform-ui';
import { AzureBillingTerm, AzurePricing } from 'generated/graphql';
import { zip } from 'lodash';
import { PackageVariant } from 'mdi-material-ui';
import React from 'react';
import { Box, Grid } from 'vendor/material';

import { formatPrice } from '../../VendorListings.helpers';
import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';
import InfoList, { InfoListItem } from '../InfoList/InfoList';
import SectionIcon from '../SectionIcon/SectionIcon';

const getPricePerPaymentDisplay = (bt: AzureBillingTerm | undefined) =>
  bt?.price_per_payment_in_usd
    ? formatPrice(bt?.price_per_payment_in_usd)
    : '—';

const getPmtOptionDisplay = (
  bt: AzureBillingTerm | undefined,
): 'One-time' | 'Per-month' | 'Per-year' | '' => {
  const { type, value, payment_option } = bt || {};
  if (payment_option?.value === value && payment_option?.type === type) {
    return 'One-time';
  } else if (payment_option?.value === 1 && payment_option?.type === 'month') {
    return 'Per-month';
  } else if (payment_option?.value === 1 && payment_option?.type === 'year') {
    return 'Per-year';
  }
  return '';
};

type AzurePricingProps = {
  sourcePricing: AzurePricing | null | undefined;
  revisionPricing: AzurePricing | null | undefined;
};

const AzurePlans: React.FC<AzurePricingProps> = ({
  sourcePricing,
  revisionPricing,
}) => {
  const isPerUserPricing =
    sourcePricing?.pricing_type === 'per_user' ||
    revisionPricing?.pricing_type === 'per_user';

  const combinedPlans = zip(
    sourcePricing?.plans ?? [],
    revisionPricing?.plans ?? [],
  );

  return (
    <>
      {combinedPlans?.map(([sourcePlan, revisionPlan], index) => {
        return (
          <Box mt={3} key={index}>
            <FormSection
              title={
                <SectionIcon
                  icon={<PackageVariant fontSize="inherit" />}
                  title={`Plan - ${
                    revisionPlan?.name ||
                    sourcePlan?.name ||
                    `Plan ${index + 1}`
                  }`}
                />
              }
              mb={1.5}
            >
              <Grid container spacing={2}>
                <Grid item xs={6} md={4} lg={4}>
                  <InfoDiffItem
                    title="Plan ID"
                    sourceValue={sourcePlan?.plan_id}
                    revisionValue={revisionPlan?.plan_id}
                  />
                </Grid>
                <Grid item xs={6} md={4} lg={4}>
                  <InfoDiffItem
                    title="Plan Name"
                    sourceValue={sourcePlan?.name}
                    revisionValue={revisionPlan?.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InfoDiffItem
                    title="Plan Description"
                    sourceValue={sourcePlan?.description}
                    revisionValue={revisionPlan?.description}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InfoDiffItem
                    title="Markets"
                    sourceValue={sourcePlan?.markets?.join(', ')}
                    revisionValue={revisionPlan?.markets?.join(', ')}
                  />
                </Grid>
                <Grid item xs={2}>
                  <InfoDiffItem
                    title="Pricing Model"
                    sourceValue={
                      sourcePricing?.pricing_type === 'flat_rate'
                        ? 'Flat Rate'
                        : 'Per User'
                    }
                    revisionValue={
                      revisionPricing?.pricing_type === 'flat_rate'
                        ? 'Flat Rate'
                        : 'Per User'
                    }
                  />
                </Grid>
                {zip(
                  sourcePlan?.billing_terms ?? [],
                  revisionPlan?.billing_terms ?? [],
                )?.map(([sourceBt, revisionBt], termIndex) => {
                  return (
                    <Grid item container spacing={2} key={termIndex}>
                      <Grid item xs={6} md={4} lg={4}>
                        <InfoDiffItem
                          title={`Billing Term ${termIndex + 1}`}
                          sourceValue={`${sourceBt?.value}-${sourceBt?.type}`}
                          revisionValue={`${revisionBt?.value}-${revisionBt?.type}`}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={4}>
                        <InfoDiffItem
                          title={`Payment Option ${termIndex + 1}`}
                          sourceValue={getPmtOptionDisplay(sourceBt)}
                          revisionValue={getPmtOptionDisplay(revisionBt)}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={4}>
                        <InfoDiffItem
                          title={`Price Per Payment ${termIndex + 1}`}
                          sourceValue={getPricePerPaymentDisplay(sourceBt)}
                          revisionValue={getPricePerPaymentDisplay(revisionBt)}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid item xs={3} md={3} lg={2}>
                  <InfoDiffItem
                    title="Free Trial"
                    sourceValue={sourcePlan?.free_trial ? 'Yes' : 'No'}
                    revisionValue={revisionPlan?.free_trial ? 'Yes' : 'No'}
                  />
                </Grid>
                <Grid item xs={3} md={3} lg={2}>
                  {isPerUserPricing && (
                    <PuiInfoItem title="User Limits">
                      {(sourcePlan?.user_limits?.min ||
                        revisionPlan?.user_limits?.min) && (
                        <InfoList>
                          {(sourcePlan?.user_limits?.min ||
                            revisionPlan?.user_limits?.min) && (
                            <InfoListItem>
                              <strong>Minimum:</strong>{' '}
                              <InfoDiffItem
                                sourceValue={String(
                                  sourcePlan?.user_limits?.min,
                                )}
                                revisionValue={String(
                                  revisionPlan?.user_limits?.min,
                                )}
                              />
                            </InfoListItem>
                          )}
                          {(sourcePlan?.user_limits?.max ||
                            revisionPlan?.user_limits?.max) && (
                            <InfoListItem>
                              <strong>Maximum:</strong>{' '}
                              <InfoDiffItem
                                sourceValue={String(
                                  sourcePlan?.user_limits?.max,
                                )}
                                revisionValue={String(
                                  revisionPlan?.user_limits?.max,
                                )}
                              />
                            </InfoListItem>
                          )}
                        </InfoList>
                      )}
                    </PuiInfoItem>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InfoDiffItem
                    title="Plan Visibility"
                    sourceValue={
                      sourcePlan?.visibility?.public ? 'Public' : 'Private'
                    }
                    revisionValue={
                      revisionPlan?.visibility?.public ? 'Public' : 'Private'
                    }
                  />
                </Grid>
                {zip(
                  sourcePlan?.visibility?.allowed_tenants ?? [],
                  revisionPlan?.visibility?.allowed_tenants ?? [],
                )?.map(([sourceTenant, revisionTenant], tenantIndex) => {
                  return (
                    <React.Fragment key={tenantIndex}>
                      <Grid item xs={3}>
                        <InfoDiffItem
                          title={`Tenant ID ${tenantIndex + 1}`}
                          sourceValue={sourceTenant?.tenant_id}
                          revisionValue={revisionTenant?.tenant_id}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <InfoDiffItem
                          title={`Tenant Description ${tenantIndex + 1}`}
                          sourceValue={sourceTenant?.description}
                          revisionValue={revisionTenant?.description}
                        />
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </FormSection>
          </Box>
        );
      })}
    </>
  );
};

export default AzurePlans;
