import classnames from 'classnames';
import React from 'react';
import { Grid, Typography } from 'vendor/material';

import useStyles from './ListingListHeader.styles';

const ListingListHeader: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={9} sm={6} md={6} lg={6}>
        <div className={classes.headerColumn}>
          <Typography className={classes.headerText}>Listing</Typography>
        </div>
      </Grid>
      <Grid item xs={3} sm={2} md={2} lg={2}>
        <div className={classes.headerColumn}>
          <Typography className={classnames(classes.headerText)}>
            Status
          </Typography>
        </div>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <div className={classes.headerColumn}>
          <Typography className={classnames(classes.headerText)}>
            Product ID
          </Typography>
        </div>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <div className={classes.headerColumn}>
          <Typography className={classnames(classes.headerText)}>
            Marketplace URL
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default ListingListHeader;
