import { gql } from '@apollo/client';
import { Banner, FormSection, Link } from '@tackle-io/platform-ui';
import { InfoItem as PuiInfoItem } from '@tackle-io/platform-ui';
import InfoItem from 'components/InfoItem';
import { useListingContentAwsListingQuery } from 'generated/graphql';
import { indexOf, sortBy } from 'lodash';
import { zip } from 'lodash';
import {
  Aws,
  CurrencyUsd,
  FaceAgent,
  FileDocument,
  FileFind,
  License,
  Server,
  Star,
} from 'mdi-material-ui';
import React from 'react';
import { Box, Grid, Typography } from 'vendor/material';

import InfoText from '../../../../../../components/InfoText';
import { isAwsAmiListing } from '../../../../../../utils/utils';
import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';
import InfoList, { InfoListItem } from '../InfoList/InfoList';
import LoadingListingContent from '../LoadingListingContent/LoadingListingContent';
import SectionIcon from '../SectionIcon/SectionIcon';
import AwsCategories from './AwsCategories';
import AwsDimensions from './AwsDimensions';
import AwsSupportItems from './AwsSupportItems';
import AwsUsageFees from './AwsUsageFees';

const TACKLE_AWS_ACCOUNT_IDS = ['003526222725', '533037066560'];
interface ListingContentAWSProps {
  productidInternal: string;
}

export const AWS_LISTING_QUERY = gql`
  fragment AwsListingFields on AwsListing {
    ami_pricing {
      pricing_setting
      pricing_template_url
    }
    categories {
      category
      subcategory
    }
    contact_email
    demo {
      email
      registration
      homepage
      product_feature
    }
    encrypted_productid
    full_description
    highlights
    id
    legal {
      contract_type
      contract_url
    }
    listing_type
    logo
    marketplace_id
    marketplace_url
    name
    pricing {
      buyer_contract_type
      contract_dimensions {
        description
        name
        sku
        position
        prices {
          monthly
          one_year
          two_year
          three_year
        }
      }
      contract_durations
      product_unit
      usage_fees {
        description
        position
        price
        sku
      }
      markets {
        countries
        target_type
      }
    }
    product_video_url
    search_keywords
    short_description
    support {
      details
      refund_policy
      support_items {
        display_name
        support_resource
      }
    }
    vendorid
    version_detail {
      access_instructions
      access_role_arn
      ami_id
      architecture
      os_type
      os_version
      os_version_title
      release_notes
      scanning_port
      security_groups
      third_party_software
      usage_instructions
      user_name
    }
    revision_of_product_id_internal
  }
  query ListingContentAwsListing($id: ID!) {
    awsListing(id: $id) {
      ...AwsListingFields
      revision_of_listing {
        ...AwsListingFields
      }
    }
  }
`;

const SORTED_DURATIONS = ['Monthly', '1-year', '2-year', '3-year'];

const ListingContentAWS: React.FC<ListingContentAWSProps> = ({
  productidInternal,
}) => {
  const { data: listingData, loading: isFetching } =
    useListingContentAwsListingQuery({
      variables: {
        id: productidInternal,
      },
    });

  const sourceListingData = listingData?.awsListing?.revision_of_listing?.id
    ? listingData?.awsListing?.revision_of_listing
    : listingData?.awsListing;

  const markets = listingData?.awsListing?.pricing?.markets?.countries || [];
  const sortedMarkets = [...markets].sort();

  const combinedSecurityGroups = zip(
    sourceListingData?.version_detail?.security_groups ?? [],
    listingData?.awsListing?.version_detail?.security_groups ?? [],
  );

  if (isFetching) {
    return <LoadingListingContent />;
  }

  if (!listingData) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" py={6}>
        <Typography color="error">Unable to load product</Typography>
      </Box>
    );
  }

  return (
    <>
      {listingData?.awsListing?.revision_of_product_id_internal && (
        <Box mb={3}>
          <Banner
            type="info"
            title={`Listing copied from ${sourceListingData?.name}`}
            body={
              <Link
                to={`/vendor/${listingData?.awsListing?.vendorid}/listings/${listingData?.awsListing?.revision_of_product_id_internal}`}
              >
                {sourceListingData?.name}
              </Link>
            }
          />
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InfoDiffItem
            title="Internal Product ID"
            sourceValue={sourceListingData?.id}
            revisionValue={listingData?.awsListing?.id}
          />
        </Grid>
        <Grid item xs={4}>
          <InfoDiffItem
            title="Marketplace Product ID"
            sourceValue={sourceListingData?.marketplace_id}
            revisionValue={listingData?.awsListing?.marketplace_id}
          />
        </Grid>
        <Grid item xs={4}>
          <InfoDiffItem
            title="Encrypted Product ID"
            sourceValue={sourceListingData?.encrypted_productid}
            revisionValue={listingData?.awsListing?.encrypted_productid}
          />
        </Grid>
        {!isAwsAmiListing(listingData?.awsListing?.listing_type) && (
          <Grid item xs={4}>
            <InfoDiffItem
              title="SaaS URL"
              sourceValue={`https://upstream-api.tackle.io/order/${sourceListingData?.vendorid}`}
              revisionValue={`https://upstream-api.tackle.io/order/${listingData?.awsListing?.vendorid}`}
            />
          </Grid>
        )}
        <Grid item xs={4}>
          <InfoDiffItem
            title="Listing Type"
            sourceValue={sourceListingData?.listing_type}
            revisionValue={listingData?.awsListing?.listing_type}
          />
        </Grid>
      </Grid>
      <Box mt={5}>
        <FormSection
          title={
            <SectionIcon
              icon={<FileDocument fontSize="inherit" />}
              title="Overview"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InfoDiffItem
                title="Listing Name"
                sourceValue={sourceListingData?.name}
                revisionValue={listingData?.awsListing?.name}
              />
            </Grid>
            <Grid item xs={12}>
              <InfoDiffItem
                title="Long Description"
                sourceValue={sourceListingData?.full_description}
                revisionValue={listingData?.awsListing?.full_description}
              />
            </Grid>
            <Grid item xs={12}>
              <InfoDiffItem
                title="Short Description"
                sourceValue={sourceListingData?.short_description}
                revisionValue={listingData?.awsListing?.short_description}
              />
            </Grid>
            <Grid item xs={6}>
              <InfoDiffItem
                title="Logo URL"
                sourceValue={sourceListingData?.logo}
                revisionValue={listingData?.awsListing?.logo}
              />
            </Grid>
            <Grid item xs={6}>
              <InfoDiffItem
                title="Product Video URL"
                sourceValue={sourceListingData?.product_video_url}
                revisionValue={listingData?.awsListing?.product_video_url}
              />
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      <Box mt={5}>
        <FormSection
          title={
            <SectionIcon
              icon={<License fontSize="inherit" />}
              title="License Agreement"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <InfoDiffItem
                title="End User License Agreement"
                sourceValue={sourceListingData?.legal?.contract_url}
                revisionValue={listingData?.awsListing?.legal?.contract_url}
              />
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      <Box mt={5}>
        <FormSection
          title={
            <SectionIcon
              icon={<Star fontSize="inherit" />}
              title="Highlights"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InfoDiffItem
                title="Highlight 1"
                sourceValue={
                  sourceListingData?.highlights &&
                  sourceListingData?.highlights[0]
                }
                revisionValue={
                  listingData?.awsListing?.highlights &&
                  listingData?.awsListing?.highlights[0]
                }
              />
            </Grid>
            <Grid item xs={4}>
              <InfoDiffItem
                title="Highlight 2"
                sourceValue={
                  sourceListingData?.highlights &&
                  sourceListingData?.highlights[1]
                }
                revisionValue={
                  listingData?.awsListing?.highlights &&
                  listingData?.awsListing?.highlights[1]
                }
              />
            </Grid>
            <Grid item xs={4}>
              <InfoDiffItem
                title="Highlight 3"
                sourceValue={
                  sourceListingData?.highlights &&
                  sourceListingData?.highlights[2]
                }
                revisionValue={
                  listingData?.awsListing?.highlights &&
                  listingData?.awsListing?.highlights[2]
                }
              />
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      <Box mt={5}>
        <FormSection
          title={
            <SectionIcon
              icon={<FileFind fontSize="inherit" />}
              title="Keywords & Product Categories"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <AwsCategories
              sourceCategories={sourceListingData?.categories}
              revisionCategories={listingData?.awsListing?.categories}
            />
            <Grid item xs={12} md={12} lg={12}>
              <InfoDiffItem
                title="Search Keywords"
                sourceValue={sourceListingData?.search_keywords}
                revisionValue={listingData?.awsListing?.search_keywords}
              />
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      <Box mt={5}>
        <FormSection
          title={
            <SectionIcon
              icon={<FaceAgent fontSize="inherit" />}
              title="Support"
            />
          }
          mb={1.5}
        >
          <AwsSupportItems
            sourceSupportItems={sourceListingData?.support?.support_items}
            revisionSupportItems={
              listingData?.awsListing?.support?.support_items
            }
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InfoDiffItem
                title="Support details"
                sourceValue={sourceListingData?.support?.details}
                revisionValue={listingData?.awsListing?.support?.details}
              />
            </Grid>
            <Grid item xs={6}>
              <InfoDiffItem
                title="Order Notification Email"
                sourceValue={sourceListingData?.contact_email}
                revisionValue={listingData?.awsListing?.contact_email}
              />
            </Grid>
            <Grid item xs={12}>
              <InfoDiffItem
                title="Refund Policy"
                sourceValue={sourceListingData?.support?.refund_policy}
                revisionValue={listingData?.awsListing?.support?.refund_policy}
              />
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      {isAwsAmiListing(listingData?.awsListing?.listing_type) ? (
        <Box mt={5}>
          <FormSection
            title={
              <SectionIcon
                icon={<CurrencyUsd fontSize="inherit" />}
                title="Pricing"
              />
            }
            mb={1.5}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InfoDiffItem
                  title="Pricing Setting"
                  sourceValue={sourceListingData?.ami_pricing?.pricing_setting}
                  revisionValue={
                    listingData?.awsListing?.ami_pricing?.pricing_setting
                  }
                />
              </Grid>
              {listingData?.awsListing?.ami_pricing?.pricing_template_url && (
                <Grid item xs={6}>
                  {/* TODO: Check this link functionality */}
                  <Link
                    external
                    to={
                      listingData?.awsListing?.ami_pricing?.pricing_template_url
                    }
                    disableStyles
                  >
                    {listingData?.awsListing?.ami_pricing?.pricing_template_url}
                  </Link>
                  <InfoDiffItem
                    title="Pricing URL"
                    sourceValue={
                      sourceListingData?.ami_pricing?.pricing_template_url
                    }
                    revisionValue={
                      listingData?.awsListing?.ami_pricing?.pricing_template_url
                    }
                  />
                </Grid>
              )}
            </Grid>
          </FormSection>
        </Box>
      ) : (
        <Box mt={5}>
          <FormSection
            title={
              <SectionIcon
                icon={<CurrencyUsd fontSize="inherit" />}
                title="Pricing"
              />
            }
            mb={1.5}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/*
                Corner case conditional for if a listing has no pricing information saved yet.
                This shouldn't be the case, since we are defaulting new listings to worldwide on creation.
                */}
                {listingData?.awsListing?.pricing === null ||
                listingData?.awsListing?.pricing?.markets === null ? (
                  <InfoDiffItem title="Markets" />
                ) : (
                  <InfoDiffItem
                    title="Markets"
                    sourceValue={
                      sourceListingData?.pricing?.markets?.target_type ===
                      'worldwide'
                        ? 'WORLDWIDE'
                        : sourceListingData?.pricing?.markets?.target_type
                        ? `${sourceListingData?.pricing?.markets?.target_type?.toUpperCase()}: ${sortedMarkets?.join(
                            ', ',
                          )}`
                        : ''
                    }
                    revisionValue={
                      listingData?.awsListing?.pricing?.markets?.target_type ===
                      'worldwide'
                        ? 'WORLDWIDE'
                        : `${listingData?.awsListing?.pricing?.markets?.target_type?.toUpperCase()}: ${sortedMarkets?.join(
                            ', ',
                          )}`
                    }
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <InfoDiffItem
                  title="Buyer Contract Type"
                  sourceValue={sourceListingData?.pricing?.buyer_contract_type}
                  revisionValue={
                    listingData?.awsListing?.pricing?.buyer_contract_type
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InfoDiffItem
                  title="Product Unit"
                  sourceValue={sourceListingData?.pricing?.product_unit}
                  revisionValue={listingData?.awsListing?.pricing?.product_unit}
                />
              </Grid>
              <Grid item xs={4}>
                <InfoDiffItem
                  title="Contract Duration"
                  sourceValue={
                    sourceListingData?.pricing?.contract_durations &&
                    sortBy(
                      sourceListingData?.pricing.contract_durations,
                      (duration) => indexOf(SORTED_DURATIONS, duration),
                    ).join(', ')
                  }
                  revisionValue={
                    listingData?.awsListing?.pricing?.contract_durations &&
                    sortBy(
                      listingData?.awsListing?.pricing.contract_durations,
                      (duration) => indexOf(SORTED_DURATIONS, duration),
                    ).join(', ')
                  }
                />
              </Grid>
            </Grid>
            <AwsDimensions
              sourceDimensions={sourceListingData?.pricing?.contract_dimensions}
              revisionDimensions={
                listingData?.awsListing?.pricing?.contract_dimensions
              }
            />
            <AwsUsageFees
              sourceUsageFees={sourceListingData?.pricing?.usage_fees}
              revisionUsageFees={listingData?.awsListing?.pricing?.usage_fees}
            />
          </FormSection>
        </Box>
      )}
      {isAwsAmiListing(listingData?.awsListing?.listing_type) && (
        <Box mt={5}>
          <FormSection
            title={
              <SectionIcon
                title="Version Details"
                icon={<Server fontSize="inherit" />}
              />
            }
            mb={1.5}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InfoDiffItem
                  title="Amazon Machine Image (AMI) ID"
                  sourceValue={sourceListingData?.version_detail?.ami_id}
                  revisionValue={
                    listingData?.awsListing?.version_detail?.ami_id
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InfoDiffItem
                  title="Architecture"
                  sourceValue={sourceListingData?.version_detail?.architecture}
                  revisionValue={
                    listingData?.awsListing?.version_detail?.architecture
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InfoDiffItem
                  title="IAM access role ARN"
                  sourceValue={
                    sourceListingData?.version_detail?.access_role_arn
                  }
                  revisionValue={
                    listingData?.awsListing?.version_detail?.access_role_arn
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InfoDiffItem
                  title="OS Type"
                  sourceValue={sourceListingData?.version_detail?.os_type}
                  revisionValue={
                    listingData?.awsListing?.version_detail?.os_type
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InfoDiffItem
                  title="OS Version"
                  sourceValue={sourceListingData?.version_detail?.os_version}
                  revisionValue={
                    listingData?.awsListing?.version_detail?.os_version
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InfoDiffItem
                  title="OS Version Title"
                  sourceValue={
                    sourceListingData?.version_detail?.os_version_title
                  }
                  revisionValue={
                    listingData?.awsListing?.version_detail?.os_version_title
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InfoDiffItem
                  title="User Name"
                  sourceValue={sourceListingData?.version_detail?.user_name}
                  revisionValue={
                    listingData?.awsListing?.version_detail?.user_name
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InfoDiffItem
                  title="Scanning Port"
                  sourceValue={sourceListingData?.version_detail?.scanning_port}
                  revisionValue={
                    listingData?.awsListing?.version_detail?.scanning_port
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InfoDiffItem
                  title="AMI Virtualization Type"
                  sourceValue="HVM"
                  revisionValue="HVM"
                />
              </Grid>
              <Grid item xs={12}>
                <PuiInfoItem title="Security Groups">
                  {combinedSecurityGroups && (
                    <InfoList>
                      {combinedSecurityGroups?.map(
                        (
                          [sourceSecurityGroups, revisionSecurityGroups],
                          ind: number,
                        ) => {
                          return (
                            <InfoListItem key={ind}>
                              <InfoDiffItem
                                sourceValue={sourceSecurityGroups}
                                revisionValue={revisionSecurityGroups}
                              />
                            </InfoListItem>
                          );
                        },
                      )}
                    </InfoList>
                  )}
                </PuiInfoItem>
              </Grid>
            </Grid>
            <Box mt={1}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InfoDiffItem
                    title="Release Notes"
                    sourceValue={
                      sourceListingData?.version_detail?.release_notes
                    }
                    revisionValue={
                      listingData?.awsListing?.version_detail?.release_notes
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <InfoDiffItem
                    title="Product Access Instructions"
                    sourceValue={
                      sourceListingData?.version_detail?.access_instructions
                    }
                    revisionValue={
                      listingData?.awsListing?.version_detail
                        ?.access_instructions
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <InfoDiffItem
                    title="Usage Instructions"
                    sourceValue={
                      sourceListingData?.version_detail?.usage_instructions
                    }
                    revisionValue={
                      listingData?.awsListing?.version_detail
                        ?.usage_instructions
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <InfoDiffItem
                    title="Third Party Software"
                    sourceValue={
                      sourceListingData?.version_detail?.third_party_software
                    }
                    revisionValue={
                      listingData?.awsListing?.version_detail
                        ?.third_party_software
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </FormSection>
        </Box>
      )}
      <Box mt={5}>
        <FormSection
          title={
            <SectionIcon
              icon={<Aws fontSize="inherit" />}
              title="Whitelist AWS Account ID"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PuiInfoItem title="Tackle AWS account IDS">
                <InfoList>
                  {TACKLE_AWS_ACCOUNT_IDS.map((id) => {
                    return (
                      <InfoListItem key={id}>
                        <InfoText clipboard text={id} />
                      </InfoListItem>
                    );
                  })}
                </InfoList>
              </PuiInfoItem>
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      <Box mt={5}>
        <FormSection
          title={
            <SectionIcon
              icon={<Aws fontSize="inherit" />}
              title="Product Demo"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <Grid item>
              <Box sx={{ color: '#505F79' }}>
                <Typography variant="body2">
                  <b>cmd + click (Mac)</b> will open the link in a new tab,
                  without navigating to it.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <InfoItem title="Email">
                <InfoListItem>
                  <Link external to={listingData?.awsListing?.demo?.email}>
                    {listingData?.awsListing?.demo?.email}
                  </Link>
                </InfoListItem>
              </InfoItem>
            </Grid>
            <Grid item xs={12}>
              <InfoItem title="Login/Registration">
                <InfoListItem>
                  <Link
                    external
                    to={listingData?.awsListing?.demo?.registration}
                  >
                    {listingData?.awsListing?.demo?.registration}
                  </Link>
                </InfoListItem>
              </InfoItem>
            </Grid>
            <Grid item xs={12}>
              <InfoItem title="Homepage">
                <InfoListItem>
                  <Link external to={listingData?.awsListing?.demo?.homepage}>
                    {listingData?.awsListing?.demo?.homepage}
                  </Link>
                </InfoListItem>
              </InfoItem>
            </Grid>
            <Grid item xs={12}>
              <InfoItem title="Product Feature">
                <InfoListItem>
                  <Link
                    external
                    to={listingData?.awsListing?.demo?.product_feature}
                  >
                    {listingData?.awsListing?.demo?.product_feature}
                  </Link>
                </InfoListItem>
              </InfoItem>
            </Grid>
          </Grid>
        </FormSection>
      </Box>
    </>
  );
};

export default ListingContentAWS;
