import { gql } from '@apollo/client';
// eslint-disable-next-line no-restricted-imports
import { DefaultTheme } from '@material-ui/styles';
import { useVendorListingsQuery } from 'generated/graphql';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, makeStyles, Typography } from 'vendor/material';

import { LISTING_STATUSES } from '../../../../constants';
import ListingListHeader from './components/ListingListHeader/ListingListHeader';
import ListingListItem from './components/ListingListItem/ListingListItem';
import { useActiveListing } from './VendorListings.hooks';
import { VendorRouteParams } from './VendorListings.types';

const CLOUD_TO_TYPE_NAME_MAPPER: Record<
  string,
  'aws' | 'azure' | 'redhat' | 'gcp'
> = {
  AwsListing: 'aws',
  GcpListing: 'gcp',
  RedHatListing: 'redhat',
  AzureListing: 'azure',
};

export const VENDOR_LISTINGS_QUERY = gql`
  query VendorListings($id: ID!) {
    vendor(id: $id) {
      id
      vendor_type
    }
    vendorListingsByChannel(id: $id) {
      id
      aws {
        id
        marketplace_url
        name
        status
        marketplace_id
        encrypted_productid
        revision_of_product_id_internal
      }
      azure {
        id
        marketplace_url
        name
        status
        marketplace_id
        revision_of_product_id_internal
      }
      gcp {
        id
        marketplace_url
        name
        status
        marketplace_id
        gcp_service
        revision_of_product_id_internal
      }
      redhat {
        id
        marketplace_url
        name
        status
        marketplace_id
        revision_of_product_id_internal
      }
    }
  }
`;

const listBreakpoints = (theme: DefaultTheme) => ({
  '& :nth-child(5), & :nth-child(4)': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  '& :nth-child(3)': {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  listHeader: {
    boxShadow: `-1px 0 0 #f5f5f5, 1px 0 0 #f5f5f5`,
    marginBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    top: 0,
    zIndex: 9,
    ...listBreakpoints(theme),
  },
}));

const localCompareWithDefaults = (
  a: string | null | undefined,
  b: string | null | undefined,
) => (a ?? '').localeCompare(b ?? '');

const VendorListings: React.FC = () => {
  const classes = useStyles();
  const { vendorid } = useParams<VendorRouteParams>();

  const { data, loading, error } = useVendorListingsQuery({
    variables: {
      id: vendorid,
    },
  });

  const awsListings = data?.vendorListingsByChannel?.aws ?? [];
  const azureListings = data?.vendorListingsByChannel?.azure ?? [];
  const gcpListings = data?.vendorListingsByChannel?.gcp ?? [];
  const redhatListings = data?.vendorListingsByChannel?.redhat ?? [];

  const listings = [
    ...awsListings,
    ...azureListings,
    ...gcpListings,
    ...redhatListings,
  ]
    .sort((a, b) =>
      localCompareWithDefaults(
        a?.revision_of_product_id_internal,
        b?.revision_of_product_id_internal,
      ),
    )
    .sort((a, b) => localCompareWithDefaults(a?.name, b?.name))
    .sort((a, b) => localCompareWithDefaults(a.__typename, b.__typename));

  const { activeListingId, onClickListing } = useActiveListing({
    listings,
  });

  if (loading || error) {
    return null;
  }

  const isProductionVendor = data?.vendor?.vendor_type === 'prod';

  const curryHandleOpenListing = (id: string) => (): void => {
    onClickListing(id);
  };

  return (
    <>
      {listings.length > 0 && (
        <>
          <div className={classes.listHeader}>
            <ListingListHeader />
          </div>
          <div>
            {listings.map((listing) => {
              const { id, marketplace_id, marketplace_url, name, __typename } =
                listing;
              const encryptedProductid =
                listing?.__typename === 'AwsListing'
                  ? listing.encrypted_productid
                  : undefined;
              const gcpService =
                listing.__typename === 'GcpListing'
                  ? listing.gcp_service
                  : undefined;
              return (
                <ListingListItem
                  isProductionVendor={isProductionVendor}
                  encryptedProductid={encryptedProductid}
                  gcpService={gcpService}
                  key={`${name}-${CLOUD_TO_TYPE_NAME_MAPPER[__typename!]}`}
                  marketplaceUrl={marketplace_url}
                  name={name!}
                  onToggleOpen={curryHandleOpenListing(id)}
                  open={activeListingId === id}
                  marketplaceId={marketplace_id!}
                  productidInternal={id}
                  provider={CLOUD_TO_TYPE_NAME_MAPPER[__typename!]}
                  status={listing.status as (typeof LISTING_STATUSES)[number]}
                  vendorid={vendorid}
                  isARevision={Boolean(
                    listing?.revision_of_product_id_internal,
                  )}
                />
              );
            })}
          </div>
        </>
      )}
      {!listings.length && (
        <Grid container spacing={2}>
          <Grid item>
            <Box ml={2}>
              <Typography variant="subtitle1">
                No listings to display
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default VendorListings;
