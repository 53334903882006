import { FormSection } from '@tackle-io/platform-ui';
import InfoItem from 'components/InfoItem';
import { PackageVariant } from 'mdi-material-ui';
import { Grid } from 'vendor/material';

import { RedHatPricingEdition as RedHatPricingEditionType } from '../../../../../../../generated/graphql';
import SectionIcon from '../../SectionIcon/SectionIcon';
import RedHatPricingEditionCharge from './RedHatPricingEditionCharge';

interface RedHatPricingEditionProps {
  edition: RedHatPricingEditionType;
  index: number;
}

const RedHatPricingEdition = ({
  edition,
  index,
}: RedHatPricingEditionProps) => {
  const editionCount = index + 1;
  return (
    <FormSection
      title={
        <SectionIcon
          icon={<PackageVariant fontSize="inherit" />}
          title={`Edition ${editionCount}`}
        />
      }
      mb={1.5}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem title="Name">{edition.name}</InfoItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem title="Description">{edition.description}</InfoItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem title="Audience">{edition.audience}</InfoItem>
        </Grid>
        {edition?.details?.map((detail, detail_index) => {
          const detailCount = detail_index + 1;
          return (
            <Grid key={detail} item xs={12} md={4}>
              <InfoItem title={`Details ${detailCount}`}>{detail}</InfoItem>
            </Grid>
          );
        })}
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem title="Delivery Method">
            {edition?.delivery_method}
          </InfoItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem title="Launch URL">{edition?.launch_url}</InfoItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem title="Commerce Modal">{edition?.commerce_model}</InfoItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem title="Trial Length">{edition?.trial_length}</InfoItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem title="Grace Period">{edition?.grace_period}</InfoItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem title="Vendor Charge ID">
            {edition?.vendor_chargeid}
          </InfoItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem title="Allowed Billing Frequency">
            {edition?.billing_frequency
              ? Object.values(edition?.billing_frequency).sort().join(', ')
              : null}
          </InfoItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem title="Allowed Subscription Terms">
            {edition?.subscription_terms_in_months
              ?.map(Number)
              .sort((a, b) => a - b)
              .join(', ')}
          </InfoItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem title="Auto Renewal">
            {edition?.allow_auto_renew ? 'Yes' : 'No'}
          </InfoItem>
        </Grid>
        {edition?.edition_charges
          ? edition.edition_charges.map((editionCharge, chargeIndex) => (
              <Grid
                container
                item
                key={`${editionCharge?.name}-${editionCharge?.price}`}
                xs={12}
              >
                <RedHatPricingEditionCharge
                  charge={editionCharge}
                  index={chargeIndex}
                />
              </Grid>
            ))
          : null}
      </Grid>
    </FormSection>
  );
};

export default RedHatPricingEdition;
