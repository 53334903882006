import classNames from 'classnames';
import { AlertBox } from 'mdi-material-ui';
import { makeStyles } from 'vendor/material';

interface IconWrapperProps {
  size?: 'small' | 'medium';
}

const useStyles = makeStyles((theme: any) => ({
  icon: {
    alignSelf: 'center',
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.YELLOW200,
    display: 'flex',
    position: 'relative',
    '&::before': {
      content: "''",
      width: 8,
      height: 8,
      backgroundColor: theme.palette.NEUTRAL900,
      position: 'absolute',
      zIndex: 0,
      left: 4,
      top: 4,
      borderRadius: 4,
    },
    '& svg': {
      position: 'relative',
    },
    verticalAlign: 'middle',
  },
  iconMedium: {
    fontSize: theme.typography.pxToRem(22),
    '&::before': {
      width: 14,
      height: 14,
      left: 4,
      top: 4,
    },
  },
}));

const WarningAlertBox = ({ size }: IconWrapperProps) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.icon, {
        [classes.iconMedium]: size === 'medium',
      })}
    >
      <AlertBox fontSize="inherit" />
    </div>
  );
};

export default WarningAlertBox;
