import DocumentTitleContext from 'contexts/DocumentTitleContext';
import { useContext, useEffect } from 'react';

function useDocumentTitle(title: string, retainOnUnmount = false): void {
  const defaultDocumentTitle = useContext(DocumentTitleContext);

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  useEffect(() => {
    return (): void => {
      if (!retainOnUnmount) {
        document.title = defaultDocumentTitle;
      }
    };
  }, [defaultDocumentTitle, retainOnUnmount]);
}
export default useDocumentTitle;
