import React from 'react';
import { LinearProgress, Paper } from 'vendor/material';

import useStyles from './VendorConfig.styles';

type ConfigCardProps = {
  Icon: JSX.Element;
  RenderRight?: JSX.Element;
  title: string;
  loading: boolean;
  children: React.ReactNode;
};

const ConfigCard = ({
  Icon,
  title,
  children,
  RenderRight,
  loading = false,
}: ConfigCardProps) => {
  const classes = useStyles();
  return (
    <div className={classes.cardWrapper}>
      <Paper>
        <div className={classes.cardHeader}>
          <div className={classes.cardHeaderLeft}>
            <div className={classes.cardHeaderIcon}>{Icon}</div>
            <div className={classes.cardHeaderTitle}>{title}</div>
          </div>
          {RenderRight}
          {loading && <LinearProgress className={classes.loadingBar} />}
        </div>
        <div className={classes.cardBody}>
          <div className={classes.cardBodyWrapper}>{children}</div>
        </div>
      </Paper>
    </div>
  );
};

export default ConfigCard;
