import { gql } from '@apollo/client';
import { Button, ProviderIcon } from '@tackle-io/platform-ui';
import { useVendorForAzurePartnerCenterQuery } from 'generated/graphql';
import { useParams } from 'react-router-dom';

gql`
  query VendorForAzurePartnerCenter($id: ID!) {
    vendor(id: $id) {
      id
      configuration {
        id
        azure {
          tenant_id
        }
      }
    }
    currentUser {
      id
      isConsoleUser
    }
  }
`;

const AzurePartnerCenterButton = () => {
  const { vendorid } = useParams<{ vendorid: string }>();
  const { data, error } = useVendorForAzurePartnerCenterQuery({
    variables: {
      id: vendorid,
    },
  });

  if (
    !data?.currentUser?.isConsoleUser ||
    !data?.vendor?.configuration?.azure?.tenant_id
  ) {
    return null; // only show this button if the vendor has Azure Tenant Id configured
  }

  return (
    <Button
      startIcon={<ProviderIcon provider="azure" />}
      size="small"
      variant="outlined"
      appearance={error ? 'destructive' : 'secondary'}
      onClick={() => {
        window.open(
          `https://partner.microsoft.com/dashboard/account/select?accountid=0&aadtenantid=${data?.vendor?.configuration?.azure?.tenant_id}&signinreturnpath=/dashboard`,
          '_blank',
        );
      }}
    >
      Partner Center
    </Button>
  );
};

export default AzurePartnerCenterButton;
