import { makeStyles } from 'vendor/material';

export default makeStyles((theme) => ({
  list: {
    margin: 0,
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  item: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));
