import { gql } from '@apollo/client';
import {
  Alert,
  Button,
  Checkbox,
  Select,
  TextField,
} from '@tackle-io/platform-ui';
import { Field, FieldProps, Form, Formik } from 'formik';
import {
  AwsListingInput,
  AwsListingUpdateModalContentsQuery,
  useAwsListingUpdateModalContentsQuery,
  useUpdateAwsListingUpdateModalContentsMutation,
} from 'generated/graphql';
import { useMemo } from 'react';
import getProgressTitleFromStatus from 'utils/getProgressTitleFromStatus';
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
} from 'vendor/material';
import {
  boolean as yupBoolean,
  object as yupObject,
  string as yupString,
} from 'yup';

import { LISTING_STATUSES } from '../../../../../constants';

type Values = {
  marketplaceId: string;
  marketplaceUrl: string | null | undefined;
  status: string;
  listingType: string | null | undefined;
  encryptedProductid: string | null | undefined;
  meteringEnabled: boolean;
  sendCustomerEmail: boolean;
};

const schema = yupObject()
  .shape({
    listingType: yupString().nullable(),
    encryptedProductid: yupString().nullable(),
    marketplaceUrl: yupString().url('Must be a valid URL.').nullable(),
    marketplaceId: yupString()
      .required('Marketplace ID cannot be empty')
      .matches(
        /^[^\\/?]+$/,
        'Marketplace ID cannot contain certain special characters',
      ),
    status: yupString().required(),
    meteringEnabled: yupBoolean().required(),
    sendCustomerEmail: yupBoolean().required(),
  })
  .required();

const valuesToListingUpdate = (
  values: Values,
): Omit<AwsListingInput, 'id'> => ({
  marketplace_id: values.marketplaceId || null,
  marketplace_url: values.marketplaceUrl || null,
  status: values.status || null,
  listing_type: values.listingType || null,
  encrypted_productid: values.encryptedProductid || null,
  metering_enabled: values.meteringEnabled || false,
  send_customer_email: values.sendCustomerEmail,
});

const listingToValues = (
  awsListing: AwsListingUpdateModalContentsQuery['awsListing'] | null,
): Values => ({
  marketplaceId: awsListing?.marketplace_id ?? '',
  marketplaceUrl: awsListing?.marketplace_url ?? '',
  status: awsListing?.status ?? '',
  listingType: awsListing?.listing_type ?? '',
  encryptedProductid: awsListing?.encrypted_productid ?? '',
  meteringEnabled: awsListing?.metering_enabled ?? false,
  sendCustomerEmail: awsListing?.send_customer_email ?? true,
});

gql`
  query AwsListingUpdateModalContents($id: ID!) {
    awsListing(id: $id) {
      id
      marketplace_id
      marketplace_url
      status
      listing_type
      encrypted_productid
      metering_enabled
      send_customer_email
    }
  }
`;

gql`
  mutation UpdateAwsListingUpdateModalContents($updates: AwsListingInput!) {
    updateAwsListing(updates: $updates) {
      id
      marketplace_id
      marketplace_url
      status
      listing_type
      encrypted_productid
      metering_enabled
      send_customer_email
    }
  }
`;

const validAwsListingTypeValues = [
  'ami-contract',
  'ami-hourly-annual',
  'byol',
  'ccp',
  'saas',
  'saas-subscription',
  'single-ami-with-cft',
];

const AwsListingUpdateModalContents = ({
  onClose,
  listingId,
}: {
  onClose: () => void;
  listingId: string;
}) => {
  const { data, loading, error } = useAwsListingUpdateModalContentsQuery({
    variables: { id: listingId },
  });
  const [updateValues, { error: mutationError, loading: mutationLoading }] =
    useUpdateAwsListingUpdateModalContentsMutation({
      onCompleted: () => onClose(),
    });

  const initialValues = useMemo<Values>(
    () => listingToValues(data?.awsListing),
    [data?.awsListing],
  );
  if (loading) return <span>loading</span>;
  return (
    <Formik<Values>
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={async (values) =>
        updateValues({
          variables: {
            updates: {
              id: listingId,
              ...valuesToListingUpdate(values),
            },
          },
        })
      }
    >
      {({ dirty, errors, isValid }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                as={TextField}
                error={errors.marketplaceId}
                label="Marketplace ID"
                name="marketplaceId"
                helperText="AWS Product Code"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                error={errors.marketplaceUrl}
                label="Marketplace URL"
                name="marketplaceUrl"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={Select}
                error={errors.status}
                label="Status"
                name="status"
              >
                <>
                  <option disabled>Choose a value</option>
                  {LISTING_STATUSES.map((value) => {
                    return (
                      <option key={value} value={value}>
                        {getProgressTitleFromStatus(value)}
                      </option>
                    );
                  })}
                </>
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="listingType">
                {({ field, meta }: FieldProps<Values['listingType']>) => (
                  <Select {...field} label="AWS Listing Type">
                    <option disabled value="">
                      Choose a value
                    </option>
                    <option value="saas">SaaS</option>
                    <option value="saas-subscription">
                      SaaS subscriptions (pay-as-you-go)
                    </option>
                    <option value="ccp">
                      Contracts with Consumption Pricing
                    </option>
                    <option value="byol">Bring Your Own License</option>
                    <option value="ami-hourly-annual">AMI Hourly/Annual</option>
                    <option value="ami-contract">AMI Contract</option>
                    <option value="single-ami-with-cft">
                      Single AMI with CFT
                    </option>
                    {meta.initialValue &&
                      !validAwsListingTypeValues.includes(
                        meta.initialValue,
                      ) && (
                        <option value={meta.initialValue}>
                          {meta.initialValue} (Unknown type)
                        </option>
                      )}
                  </Select>
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Encrypted Product ID"
                name="encryptedProductid"
                helperText="AWS Product ID"
              />
            </Grid>
            <Grid item xs={12}>
              <Field name="meteringEnabled">
                {({ field }: FieldProps<boolean>) => {
                  return (
                    <div>
                      <FormControlLabel
                        label="Enable metering"
                        control={
                          <Box mx={1}>
                            <Checkbox
                              {...field}
                              onChange={field.onChange}
                              checked={field.value}
                              aria-label="Enable metering"
                            />
                          </Box>
                        }
                      />
                      <FormHelperText>
                        Only enable metering if the product is going through pre
                        go-live testing or is published
                      </FormHelperText>
                    </div>
                  );
                }}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="sendCustomerEmail">
                {({ field }: FieldProps<boolean>) => {
                  return (
                    <div>
                      <FormControlLabel
                        label="Send Customer Welcome Email"
                        control={
                          <Box mx={1}>
                            <Checkbox
                              {...field}
                              onChange={field.onChange}
                              checked={field.value}
                              aria-label="Customer Welcome Email"
                            />
                          </Box>
                        }
                      />
                      <FormHelperText>
                        Send the customer Welcome Email to the buyer at offer
                        acceptance
                      </FormHelperText>
                    </div>
                  );
                }}
              </Field>
            </Grid>
            {mutationError && (
              <Box mt={4}>
                <Alert
                  appearance="danger"
                  noShadow
                  hideIcon
                  title="Error: Unsuccessful product update. Create a support ticket, assign it to pod:streetsharks, and the team will reach out when the issue has been resolved."
                />
              </Box>
            )}
            <Grid item container spacing={2} alignItems="center">
              <Grid item xs>
                {error && (
                  <Typography color="error">There was an error</Typography>
                )}
              </Grid>
              <Grid item>
                <Button appearance="primary" onClick={onClose} variant="text">
                  Close
                </Button>
              </Grid>
              <Grid item>
                <Button
                  appearance="primary"
                  disabled={!isValid || !dirty}
                  type="submit"
                  variant="text"
                  loading={loading || mutationLoading}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default AwsListingUpdateModalContents;
