import { gql } from '@apollo/client';
import {
  Button,
  Checkbox,
  Code,
  Modal,
  Select,
  TextField,
} from '@tackle-io/platform-ui';
import { Field, FieldProps, Form, Formik } from 'formik';
import {
  useGenerateVendorSupportAzureSubscriptionsMutation,
  useGenerateVendorSupportGcpInspectListOrdersMutation,
  useVendorSupportQuery,
} from 'generated/graphql';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { downloadCsvFile } from 'utils/utils';
import { Box, FormControlLabel, Grid, Typography } from 'vendor/material';
import { bool as yupBool, object as yupObject, string as yupString } from 'yup';

export const VENDOR_SUPPORT_QUERY = gql`
  query VendorSupport($id: ID!) {
    vendorListingsByChannel(id: $id) {
      id
      gcp {
        id
        marketplace_id
        name
      }
    }
  }
`;

gql`
  mutation GenerateVendorSupportAzureSubscriptions($id: ID!) {
    supportApiGenerateVendorAzureSubscriptions(id: $id)
  }
`;

gql`
  mutation GenerateVendorSupportGcpInspectListOrders(
    $id: ID!
    $productId: ID!
    $customerId: ID
    $raw: Boolean
  ) {
    supportApiGenerateVendorGcpInspectListOrders(
      vendorId: $id
      productId: $productId
      customerId: $customerId
      raw: $raw
    )
  }
`;

const VendorAzureSubscriptionButton = () => {
  const { vendorid } = useParams<{ vendorid: string }>();
  const [generateAzureSubs, { loading, error }] =
    useGenerateVendorSupportAzureSubscriptionsMutation();

  return (
    <Button
      appearance={error ? 'destructive' : 'secondary'}
      variant="outlined"
      loading={loading}
      onClick={async () => {
        const response = await generateAzureSubs({
          variables: {
            id: vendorid,
          },
        });

        const dateNow = new Date();
        const fileName = `${vendorid}_azure_subscription_${dateNow.getFullYear()}_${
          dateNow.getMonth() + 1
        }_${dateNow.getDate()}_${dateNow.getHours()}_${dateNow.getMinutes()}.csv`;

        downloadCsvFile(
          response.data?.supportApiGenerateVendorAzureSubscriptions,
          fileName,
        );
      }}
    >
      Generate Azure Subscriptions CSV
    </Button>
  );
};

const vendorSupportGcpInspectListOrdersSchema = yupObject().shape({
  productId: yupString().required(),
  customerId: yupString(),
  raw: yupBool(),
});

const VendorSupportGcpInspectListOrdersButton = () => {
  const [
    isGenerateGcpListOrdersModalOpen,
    setIsGenerateGcpListOrdersModalOpen,
  ] = useState(false);

  const [responseData, setResponseData] = useState<string | null | undefined>(
    '',
  );
  const { vendorid } = useParams<{ vendorid: string }>();
  const [generateGcpInspectListOrders, { loading, error }] =
    useGenerateVendorSupportGcpInspectListOrdersMutation();

  const { data, loading: useVendorSupportLoading } = useVendorSupportQuery({
    variables: { id: vendorid },
  });

  return (
    <>
      <Button
        appearance={error ? 'destructive' : 'secondary'}
        variant="outlined"
        loading={loading}
        onClick={() => {
          setIsGenerateGcpListOrdersModalOpen(
            !isGenerateGcpListOrdersModalOpen,
          );
        }}
      >
        Generate GCP Inspect List Orders
      </Button>
      {isGenerateGcpListOrdersModalOpen && (
        <Modal
          open={isGenerateGcpListOrdersModalOpen}
          title="GCP Inspect List Orders"
          width="xlarge"
          onClose={() =>
            setIsGenerateGcpListOrdersModalOpen(
              !isGenerateGcpListOrdersModalOpen,
            )
          }
        >
          <Box maxHeight="100vh">
            <Formik
              initialValues={{
                vendorId: vendorid,
                productId: '',
                customerId: '',
                raw: false,
              }}
              onSubmit={async (values) => {
                const response = await generateGcpInspectListOrders({
                  variables: {
                    id: vendorid,
                    productId: values.productId, // required
                    customerId: values.customerId || undefined,
                    raw: values.raw || false,
                  },
                });
                setResponseData(
                  response?.data?.supportApiGenerateVendorGcpInspectListOrders,
                );
              }}
              validationSchema={vendorSupportGcpInspectListOrdersSchema}
            >
              {(props) => (
                <Form>
                  <Grid container direction="column" spacing={2}>
                    <Grid item container direction="column" spacing={2}>
                      <Grid item>
                        <Field name="productId">
                          {({ field }: FieldProps) => (
                            <Select {...field} label="Products">
                              <option value="" disabled>
                                Select a Product
                              </option>
                              {data?.vendorListingsByChannel?.gcp?.map(
                                (listing, index) => (
                                  <option
                                    key={index}
                                    value={listing.marketplace_id!}
                                  >
                                    {listing?.name}
                                  </option>
                                ),
                              )}
                            </Select>
                          )}
                        </Field>
                      </Grid>
                      <Grid item>
                        <Field name="customerId">
                          {({ field }: FieldProps) => (
                            <TextField
                              {...field}
                              label="Customer ID"
                            ></TextField>
                          )}
                        </Field>
                      </Grid>
                      <Grid item>
                        <Box ml={1}>
                          <Field name="raw">
                            {({ field, meta, form }: FieldProps) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...field}
                                    checked={field.value}
                                    color="primary"
                                    onChange={(e) =>
                                      form.setFieldValue(
                                        field.name,
                                        e.target.checked,
                                      )
                                    }
                                  />
                                }
                                label="Raw"
                              />
                            )}
                          </Field>
                        </Box>
                      </Grid>
                    </Grid>
                    {error && (
                      <Grid item>
                        <Typography color="error">
                          There was an error, if this persist please open a
                          support ticket: {error.message}
                        </Typography>
                        <Box maxHeight={200} width="100%" overflow="scroll">
                          <Code language="json">
                            {JSON.stringify(error, undefined, 2)}
                          </Code>
                        </Box>
                      </Grid>
                    )}
                    {responseData && (
                      <Grid item>
                        <textarea
                          disabled
                          value={responseData}
                          style={{
                            minHeight: '200px',
                            width: '100%',
                            resize: 'vertical',
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item container justifyContent="space-between">
                      <Grid item>
                        <Button
                          appearance="primary"
                          type="submit"
                          disabled={
                            props.values.productId.length < 1 ||
                            useVendorSupportLoading ||
                            loading
                          }
                          loading={loading}
                        >
                          Generate Report
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          appearance="destructive"
                          disabled={Boolean(!responseData)}
                          onClick={() => setResponseData(null)}
                        >
                          Close Report
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      )}
    </>
  );
};

const VendorSupport = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <VendorAzureSubscriptionButton />
      </Grid>
      <Grid item xs={12} md={4}>
        <VendorSupportGcpInspectListOrdersButton />
      </Grid>
    </Grid>
  );
};

export default VendorSupport;
