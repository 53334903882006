import { gql } from '@apollo/client';
import { Alert, Button, TextField } from '@tackle-io/platform-ui';
import { FieldInputProps, useFormik } from 'formik';
import {
  AzureVendorConfigurationFormQuery,
  useAzureVendorConfigurationFormQuery,
  useUpdateAzureVendorConfigurationFormMutation,
} from 'generated/graphql';
import { useParams } from 'react-router-dom';
import { Box, Grid } from 'vendor/material';
import { date as yupDate, object as yupObject, string as yupString } from 'yup';

const SAVE_FAILED_ERROR_MESSAGE =
  'Error: Unsuccessful vendor update. Create a support ticket, assign it to pod:streetsharks, and the team will reach out when the issue has been resolved.';

// 8char-4char-4char-4char-12char (alphanumeric)
const AZURE_IDS_PATTERN =
  /^[\w\d]{8}-[\w\d]{4}-[\w\d]{4}-[\w\d]{4}-[\w\d]{12}$/m;
const AZURE_SECRET_PATTERN = /^\S+$/;

export const AZURE_VENDOR_CONFIGURATION_FORM_QUERY = gql`
  query AzureVendorConfigurationForm($id: ID!) {
    vendor(id: $id) {
      id
      configuration {
        id
        azure {
          app_id
          app_secret
          exp_date
          tenant_id
        }
      }
    }
  }
`;

export const AZURE_VENDOR_CONFIGURATION_FORM_MUTATION = gql`
  mutation UpdateAzureVendorConfigurationForm($updates: VendorInput!) {
    updateVendor(updates: $updates) {
      id
      configuration {
        id
        azure {
          app_id
          app_secret
          exp_date
          tenant_id
        }
      }
    }
  }
`;

export const AZURE_TENANT_ID_FIELD_NAME = 'azureTenantId';
export const AZURE_APP_ID_FIELD_NAME = 'azureAppId';
export const AZURE_SECRET_KEY_FIELD_NAME = 'azureSecretKey';
export const AZURE_EXP_DATE_FIELD_NAME = 'azureExpDate';

const AZURE_TENANT_ID_LABEL = 'Azure Tenant ID';
const AZURE_APP_ID_LABEL = 'Azure Application ID';
const AZURE_SECRET_KEY_LABEL = 'Azure Application Secret';
const AZURE_EXP_DATE_LABEL = 'Key Expiration Date';

export const FIELD_NAMES = {
  AZURE_TENANT_ID: AZURE_TENANT_ID_FIELD_NAME,
  AZURE_APP_ID: AZURE_APP_ID_FIELD_NAME,
  AZURE_SECRET_KEY: AZURE_SECRET_KEY_FIELD_NAME,
  AZURE_EXP_DATE: AZURE_EXP_DATE_FIELD_NAME,
};

const FIELD_NAME_TO_LABEL_MAPPING: Record<string, string> = {
  [AZURE_TENANT_ID_FIELD_NAME]: AZURE_TENANT_ID_LABEL,
  [AZURE_APP_ID_FIELD_NAME]: AZURE_APP_ID_LABEL,
  [AZURE_SECRET_KEY_FIELD_NAME]: AZURE_SECRET_KEY_LABEL,
  [AZURE_EXP_DATE_FIELD_NAME]: AZURE_EXP_DATE_LABEL,
};

const DateInput = ({
  id,
  name,
  label,
  onBlur,
  onChange,
  min,
  max,
  disabled = false,
  error,
  value,
}: {
  id?: string;
  name: string;
  label: string;
  disabled?: boolean;
  error?: string;
  max?: string;
  min?: string;
  value?: string;
} & Pick<FieldInputProps<string>, 'onChange' | 'onBlur'>) => (
  <TextField
    type="date"
    name={name}
    label={label}
    disabled={disabled}
    placeholder="MM/DD/YYYY"
    onChange={onChange}
    onBlur={onBlur}
    id={id ?? undefined}
    error={error}
    inputProps={{
      min,
      max,
    }}
    value={value}
  />
);

export const getFieldLabel = (fieldName: string): string => {
  const label = FIELD_NAME_TO_LABEL_MAPPING[fieldName];

  if (!label) {
    throw new Error(`Invalid field name, ${fieldName}`);
  }

  return label;
};

const patternError = (fieldName: string): string =>
  `${getFieldLabel(
    fieldName,
  )} must be alphanumeric and match the pattern: 8char-4char-4char-4char-12char`;

const FIELDS = [
  AZURE_TENANT_ID_FIELD_NAME,
  AZURE_APP_ID_FIELD_NAME,
  AZURE_SECRET_KEY_FIELD_NAME,
] as const;

export const getRequiredFieldsWarning = (
  values: AzureConfig,
): string | null => {
  const missingFields = FIELDS.filter((fieldName) => !values[fieldName]);

  if (missingFields.length === 0) {
    return null;
  }

  const fieldNames = missingFields
    .map((fieldName) => FIELD_NAME_TO_LABEL_MAPPING[fieldName])
    .join(', ');

  const isOrAre = missingFields.length === 1 ? 'is' : 'are';

  return `${fieldNames} ${isOrAre} required to connect to Azure.`;
};

const schema = yupObject().shape({
  [AZURE_TENANT_ID_FIELD_NAME]: yupString().matches(AZURE_IDS_PATTERN, {
    excludeEmptyString: true,
    message: patternError(AZURE_TENANT_ID_FIELD_NAME),
  }),
  [AZURE_APP_ID_FIELD_NAME]: yupString().matches(AZURE_IDS_PATTERN, {
    excludeEmptyString: true,
    message: patternError(AZURE_APP_ID_FIELD_NAME),
  }),
  [AZURE_SECRET_KEY_FIELD_NAME]: yupString().matches(AZURE_SECRET_PATTERN, {
    excludeEmptyString: true,
    message: `${getFieldLabel(
      AZURE_SECRET_KEY_FIELD_NAME,
    )} must not include whitespace.`,
  }),
  [AZURE_EXP_DATE_FIELD_NAME]: yupDate(),
});

type AzureConfig = {
  azureTenantId: string;
  azureAppId: string;
  azureSecretKey: string;
  azureExpDate: string;
};

const apiToForm = (
  vendor: AzureVendorConfigurationFormQuery | undefined,
): AzureConfig => {
  return {
    azureTenantId: vendor?.vendor?.configuration?.azure?.tenant_id || '',
    azureAppId: vendor?.vendor?.configuration?.azure?.app_id || '',
    azureSecretKey: vendor?.vendor?.configuration?.azure?.app_secret || '',
    azureExpDate: vendor?.vendor?.configuration?.azure?.exp_date || '',
  };
};

const formToApi = (azureConfig: AzureConfig) => ({
  azure: {
    app_id: azureConfig?.azureAppId,
    app_secret: azureConfig?.azureSecretKey,
    exp_date: azureConfig?.azureExpDate,
    tenant_id: azureConfig?.azureTenantId,
  },
});

type FormValues = {
  azureTenantId: string;
  azureAppId: string;
  azureSecretKey: string;
  azureExpDate: string;
};

const AzureVendorConfigForm = ({ onClose }: { onClose: () => void }) => {
  const { vendorid } = useParams<{ vendorid: string }>();

  const { data: vendorData, loading } = useAzureVendorConfigurationFormQuery({
    variables: {
      id: vendorid,
    },
  });

  const [setVendorConfig, { loading: isLoading, error: isError }] =
    useUpdateAzureVendorConfigurationFormMutation({
      onCompleted: onClose,
    });

  const handleOnSubmit = (values: AzureConfig): void => {
    setVendorConfig({
      variables: {
        updates: {
          id: vendorData?.vendor?.id!,
          configuration: {
            ...formToApi(values),
          },
        },
      },
    });
  };

  const formik = useFormik<FormValues>({
    initialValues: apiToForm(vendorData),
    validateOnBlur: true,
    validateOnChange: true,
    isInitialValid: true,
    validationSchema: schema,
    onSubmit: handleOnSubmit,
  });

  const requiredWarning = getRequiredFieldsWarning(formik.values);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={`${getFieldLabel(FIELD_NAMES.AZURE_TENANT_ID)} *`}
            {...formik.getFieldProps(FIELD_NAMES.AZURE_TENANT_ID)}
            error={formik.errors.azureTenantId}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={`${getFieldLabel(FIELD_NAMES.AZURE_APP_ID)} *`}
            {...formik.getFieldProps(FIELD_NAMES.AZURE_APP_ID)}
            error={formik.errors.azureAppId}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={`${getFieldLabel(FIELD_NAMES.AZURE_SECRET_KEY)} *`}
            {...formik.getFieldProps(FIELD_NAMES.AZURE_SECRET_KEY)}
            error={formik.errors.azureSecretKey}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <DateInput
            label={`${getFieldLabel(FIELD_NAMES.AZURE_EXP_DATE)} *`}
            {...formik.getFieldProps(FIELD_NAMES.AZURE_EXP_DATE)}
            error={formik.errors.azureExpDate}
            disabled={loading}
          />
        </Grid>
      </Grid>
      {requiredWarning && (
        <Box mt={4}>
          <Alert
            appearance="warning"
            noShadow
            hideIcon
            size="small"
            title={requiredWarning}
          />
        </Box>
      )}
      {isError && (
        <Box mt={4}>
          <Alert
            appearance="danger"
            noShadow
            hideIcon
            title={SAVE_FAILED_ERROR_MESSAGE}
          />
        </Box>
      )}
      <Box display="flex" flexDirection="row-reverse" mt={4}>
        <Button
          type="submit"
          appearance="primary"
          variant="text"
          disabled={!formik.isValid || !formik.dirty}
          loading={isLoading}
        >
          Update
        </Button>
        <Button
          appearance="primary"
          disabled={loading}
          variant="text"
          onClick={(): void => {
            formik.resetForm();
            onClose();
          }}
        >
          Cancel
        </Button>
      </Box>
    </form>
  );
};

export default AzureVendorConfigForm;
