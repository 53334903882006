import { AzureIndustry } from 'generated/graphql';
import { zip } from 'lodash';
import React from 'react';
import { Grid } from 'vendor/material';

import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';

type AzureIndustriesProps = {
  sourceIndustries: Array<AzureIndustry> | null | undefined;
  revisionIndustries: Array<AzureIndustry> | null | undefined;
};

const AzureIndustries: React.FC<AzureIndustriesProps> = ({
  sourceIndustries,
  revisionIndustries,
}) => {
  const combinedIndustries = zip(
    sourceIndustries ?? [],
    revisionIndustries ?? [],
  );

  if (!combinedIndustries?.length) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <InfoDiffItem title="Industries" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {combinedIndustries.map(([sourceIndustry, revisionIndustry], ind) => {
        return (
          <React.Fragment key={ind}>
            <Grid item xs={4} md={4} lg={4}>
              <InfoDiffItem
                title={`Industry ${ind + 1}`}
                sourceValue={sourceIndustry?.industry}
                revisionValue={revisionIndustry?.industry}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <InfoDiffItem
                title={`Vertical ${ind + 1}.1`}
                sourceValue={sourceIndustry?.vertical_1}
                revisionValue={revisionIndustry?.vertical_1}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <InfoDiffItem
                title={`Vertical ${ind + 1}.2`}
                sourceValue={sourceIndustry?.vertical_2}
                revisionValue={revisionIndustry?.vertical_2}
              />
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

export default AzureIndustries;
