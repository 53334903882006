import { GcpDocumentationResource } from 'generated/graphql';
import { zip } from 'lodash';
import React from 'react';
import { Grid } from 'vendor/material';

import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';

type GcpDocumentationProps = {
  sourceDocumentation: Array<GcpDocumentationResource> | null | undefined;
  revisionDocumentation: Array<GcpDocumentationResource> | null | undefined;
};

const GcpDocumentation: React.FC<GcpDocumentationProps> = ({
  sourceDocumentation = [],
  revisionDocumentation = [],
}) => {
  const combinedGcpDocumentation = zip(
    sourceDocumentation ?? [],
    revisionDocumentation ?? [],
  );

  if (!combinedGcpDocumentation?.length) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4} md={4} lg={4}>
          <InfoDiffItem title="Tutorials and Documentation 1 Title" />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <InfoDiffItem title="Tutorials and Documentation 1 Description" />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <InfoDiffItem title="Tutorials and Documentation 1 URL" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {combinedGcpDocumentation.map(
        ([sourceDocumentation, revisionDocumentation], index) => (
          <React.Fragment key={index}>
            <Grid item xs={4} md={4} lg={4}>
              <InfoDiffItem
                title={`Tutorials and Documentation ${index + 1} Title`}
                sourceValue={sourceDocumentation?.resource_type}
                revisionValue={revisionDocumentation?.resource_type}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <InfoDiffItem
                title={`Tutorials and Documentation ${index + 1} Description`}
                sourceValue={sourceDocumentation?.description}
                revisionValue={revisionDocumentation?.description}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <InfoDiffItem
                title={`Tutorials and Documentation ${index + 1} URL`}
                sourceValue={sourceDocumentation?.url}
                revisionValue={revisionDocumentation?.url}
              />
            </Grid>
          </React.Fragment>
        ),
      )}
    </Grid>
  );
};

export default GcpDocumentation;
