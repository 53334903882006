import { HtmlTooltip } from '@tackle-io/platform-ui';
import { Check, Eye, EyeOutline } from 'mdi-material-ui';
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import copyToClipboard from 'utils/copyToClipboard';
import { IconButton, Typography } from 'vendor/material';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  toolTipContainer: { display: 'flex', alignItems: 'center' },
  checkIcon: {
    color: theme.palette.GREEN400,
    fontSize: theme.typography.pxToRem(20),
    marginRight: theme.spacing(1),
    display: 'flex',
  },
  text: {
    cursor: 'pointer',
    wordBreak: 'break-word',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconWrapper: {
    marginLeft: theme.spacing(1),
  },
}));

interface InfoTextProps {
  text: string;
  clipboard: boolean;
  sensitive?: boolean;
}

const InfoText: React.FC<InfoTextProps> = ({ text, sensitive = false }) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [masked, setMasked] = useState(true);

  const toggleMasked = (): void => {
    setMasked(!masked);
  };

  const handleCopy: MouseEventHandler<HTMLSpanElement> = () => {
    copyToClipboard(text);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  useEffect(() => {
    if (!masked) {
      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        setMasked(true);
      }, 60000);
    }

    return (): void => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [masked]);

  return (
    <div className={classes.container}>
      <HtmlTooltip
        interactive
        placement="top"
        title={
          <div className={classes.toolTipContainer}>
            {copied ? (
              <>
                <div className={classes.checkIcon}>
                  <Check fontSize="inherit" />
                </div>
                <Typography component="p">Copied to clipboard</Typography>
              </>
            ) : (
              <Typography component="p">Click to copy</Typography>
            )}
          </div>
        }
      >
        <span className={classes.text} onClick={handleCopy} role="presentation">
          {sensitive && masked ? '●●●●●●●●' : text}
        </span>
      </HtmlTooltip>
      {sensitive && (
        <div className={classes.iconWrapper}>
          <IconButton size="small" onClick={toggleMasked}>
            {masked ? <EyeOutline /> : <Eye />}
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default InfoText;
