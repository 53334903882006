interface BuildEnvironment {
  environment: 'production' | 'staging' | 'local/unknown';
}

const buildEnvironment = {
  environment:
    (process.env.REACT_APP_BUILD_CIRCLECI && 'production') ||
    (process.env.REACT_APP_BUILD_CLOUDFLARE && 'staging') ||
    'local/unknown',
} as BuildEnvironment;

export default buildEnvironment;
