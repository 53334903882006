import { gql } from '@apollo/client';
import { Button } from '@tackle-io/platform-ui';
import { useGenerateVendorSupportAwsEntitlementsMutation } from 'generated/graphql';
import { downloadCsvFile } from 'utils/utils';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  exportJsonSpacing: {
    paddingRight: theme.spacing(2),
  },
  fetchError: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    color: 'red',
  },
}));

gql`
  mutation GenerateVendorSupportAwsEntitlements(
    $vendorId: ID!
    $productId: ID!
  ) {
    supportApiGenerateVendorAwsEntitlements(
      vendorId: $vendorId
      productId: $productId
    )
  }
`;

interface ExportEntitlementsCsvButtonProps {
  productId: string;
  vendorId: string;
}

const ExportEntitlementsCsvButton: React.FC<
  ExportEntitlementsCsvButtonProps
> = ({ productId, vendorId }) => {
  const [generateAwsEntitlements, { loading, error }] =
    useGenerateVendorSupportAwsEntitlementsMutation();

  const classes = useStyles();

  return (
    <>
      {error && (
        <div className={classes.fetchError}>
          <div>{error.message}</div>
        </div>
      )}
      <div className={classes.exportJsonSpacing}>
        <Button
          size="small"
          appearance={error ? 'destructive' : 'secondary'}
          variant="outlined"
          loading={loading}
          disabled={false}
          onClick={async () => {
            const response = await generateAwsEntitlements({
              variables: {
                vendorId,
                productId,
              },
            });
            const fileName = `aws-entitlements-${productId}.csv`;
            downloadCsvFile(
              response.data?.supportApiGenerateVendorAwsEntitlements,
              fileName,
            );
          }}
        >
          Generate Entitlements CSV
        </Button>
      </div>
    </>
  );
};

export default ExportEntitlementsCsvButton;
