import { gql } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { AccountAvatar, Link } from '@tackle-io/platform-ui';
import { useHeaderQuery, User } from 'generated/graphql';
import { Restart } from 'mdi-material-ui';
import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  makeStyles,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
  withStyles,
} from 'vendor/material';

import Logo from '../images/logo';

const BACKGROUND_COLOR = '#23263B';
const WHITE = '#FFFFFF';
const DIVIDER_COLOR = '#C4C4C4';
const AVATAR_DIAMETER = '30px';

export const HEADER_QUERY = gql`
  query Header {
    currentUser {
      id
      role
      vendorId
      picture
      name
      email
    }
  }
`;

const LightSkeleton = withStyles({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
})(Skeleton);

const useStyles = makeStyles((theme) => ({
  headerBackground: {
    height: '63px',
    backgroundColor: BACKGROUND_COLOR,
    color: WHITE,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  logo: {
    backgroundColor: theme.palette.NEUTRAL000,
    flex: '0 0 auto',
    height: '30px',
    width: '30px',
    borderRadius: '5px',
    boxShadow: theme.shadows[4],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      width: 20,
      height: 20,
    },
  },
  title: {
    fontSize: theme.spacing(2.5),
    marginLeft: theme.spacing(2),
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center',
  },
  vendorid: {
    fontSize: theme.spacing(2),
    fontWeight: 500,
  },
  divider: {
    margin: 0,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    border: 0,
    height: '31px',
    borderLeft: `1px solid ${DIVIDER_COLOR}`,
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  avatar: {
    height: AVATAR_DIAMETER,
    width: AVATAR_DIAMETER,
  },
  userName: {
    fontSize: theme.spacing(2),
    fontWeight: 500,
    marginLeft: theme.spacing(1),
  },
  loadingUserName: {
    marginLeft: theme.spacing(1),
  },
  retryButton: {
    display: 'flex',
  },
  vendoridLink: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const tokenOverride = localStorage.getItem('tokenOverride');

interface UserInfoProps {
  isError: boolean;
  isLoading: boolean;
  onRefetch: () => void;
  user: User | null | undefined;
}

const Header: React.FC = () => {
  const classes = useStyles();
  const { isLoading, isAuthenticated } = useAuth0();

  const showHeaderRight = !isLoading && isAuthenticated;

  return (
    <div className={classes.headerBackground}>
      <Container className={classes.headerContainer}>
        <Link
          to="/"
          disableStyles
          onClick={(e: React.MouseEvent) => {
            if (process.env.NODE_ENV === 'development' && e.metaKey) {
              e.preventDefault();
              if (localStorage.getItem('tokenOverride')) {
                localStorage.removeItem('tokenOverride');
                alert('tokenOverride cleared');
              } else {
                const tokenOverride = prompt('Paste in a token to use instead');
                if (tokenOverride) {
                  localStorage.setItem(
                    'tokenOverride',
                    tokenOverride.replace(/^Bearer\s+/i, ''),
                  );
                } else {
                  localStorage.removeItem('tokenOverride');
                  alert('tokenOverride cleared');
                }
              }
              window.location.reload();
            }
          }}
        >
          <div className={classes.headerLeft}>
            <div className={classes.logo}>
              <Logo />
            </div>
            <Typography component="h1" className={classes.title}>
              Admin Dashboard
            </Typography>
          </div>
        </Link>
        {tokenOverride && (
          <Typography
            color="error"
            onClick={() => {
              localStorage.removeItem('tokenOverride');
              alert('tokenOverride cleared');
              window.location.reload();
            }}
          >
            you are in production - BE CAREFUL!
          </Typography>
        )}
        <div className={classes.headerRight}>
          {showHeaderRight && <HeaderRight />}
        </div>
      </Container>
    </div>
  );
};

const HeaderRight: React.FC = () => {
  const { data, error, refetch, loading: isLoading } = useHeaderQuery();

  const user = data?.currentUser;

  const classes = useStyles();
  const menuAnchorRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const handleOpenMenu = (): void => setMenuOpen(true);
  const handleCloseMenu = (): void => setMenuOpen(false);
  const handleToggleMenu = (): void => setMenuOpen(!menuOpen);

  useEffect(() => {
    if (isLoading || error) {
      setMenuOpen(false);
    }
  }, [isLoading, error, setMenuOpen]);

  const Vendorid: React.FC = () => {
    if (isLoading) {
      return <LightSkeleton width="120px" height="24px" variant="text" />;
    }

    if (error) {
      return (
        <Typography className={classes.vendorid}>
          Failed to load user
        </Typography>
      );
    }

    return (
      <Link
        to={`/vendor/${user?.vendorId}`}
        disableStyles
        className={classes.vendoridLink}
      >
        <Typography className={classes.vendorid}>{user?.vendorId}</Typography>
      </Link>
    );
  };

  return (
    <>
      <Vendorid />
      <div className={classes.divider} />
      <div
        className={classes.menuContainer}
        role="button"
        tabIndex={0}
        onClick={handleOpenMenu}
        onKeyPress={handleToggleMenu}
        ref={menuAnchorRef}
      >
        <UserInfo
          isError={Boolean(error)}
          isLoading={isLoading}
          onRefetch={refetch}
          user={user}
        />
      </div>
      {!error && !isLoading && (
        <Menu
          id="user-menu"
          anchorEl={menuAnchorRef.current}
          keepMounted
          open={menuOpen}
          onClose={handleCloseMenu}
          variant="menu"
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Link to="/logout" disableStyles>
            <MenuItem>Logout</MenuItem>
          </Link>
        </Menu>
      )}
    </>
  );
};

const UserInfo: React.FC<UserInfoProps> = ({
  isError,
  isLoading,
  onRefetch,
  user,
}) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <>
        <LightSkeleton
          variant="circle"
          height={AVATAR_DIAMETER}
          width={AVATAR_DIAMETER}
        />
        <LightSkeleton
          className={classes.loadingUserName}
          height="24px"
          width="100px"
          variant="text"
        />
      </>
    );
  }

  if (isError) {
    return (
      <div
        className={classes.retryButton}
        role="button"
        tabIndex={0}
        onClick={onRefetch}
        onKeyPress={onRefetch}
      >
        <Restart />
        <Typography className={classes.userName}>Retry</Typography>
      </div>
    );
  }

  return (
    <>
      <AccountAvatar
        className={classes.avatar}
        src={user?.picture!}
        alt={user?.name!}
      />
      <Typography className={classes.userName}>{user?.name}</Typography>
    </>
  );
};

export default Header;
