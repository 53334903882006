import { gql } from '@apollo/client';
import { Tag } from '@tackle-io/platform-ui';
import {
  useAceVersionGetVendorQuery,
  useAceVersionQuery,
} from 'generated/graphql';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'vendor/material';

gql`
  query AceVersion($id: ID!) {
    coSellVendorForAdmin(id: $id) {
      id
      ace_version
    }
  }
`;

gql`
  query AceVersionGetVendor($id: ID!) {
    vendor(id: $id) {
      id
      name
      feature_flags {
        is_co_sell_enabled
        is_co_sell_enabled_for_microsoft
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  aceTag: {
    height: '100%',
    '& span': {
      alignSelf: 'center',
    },
  },
}));

const AceVersion = () => {
  const classes = useStyles();
  const [fetchCoSellVendor, setFetchCoSellVendor] = useState<boolean>(true);
  const { vendorid } = useParams<{ vendorid: string }>();

  useAceVersionGetVendorQuery({
    variables: {
      id: vendorid,
    },
    onCompleted: (data) => {
      if (
        data?.vendor?.feature_flags?.is_co_sell_enabled ||
        data?.vendor?.feature_flags?.is_co_sell_enabled_for_microsoft
      ) {
        setFetchCoSellVendor(false);
      }
    },
  });

  const { data } = useAceVersionQuery({
    variables: {
      id: vendorid,
    },
    skip: fetchCoSellVendor,
  });

  const aceVersion = data?.coSellVendorForAdmin?.ace_version;

  if (!aceVersion) return null;

  return (
    <Tag
      className={classes.aceTag}
      color={aceVersion === 14 ? 'green' : 'orange'}
    >
      ACE: v{aceVersion}
    </Tag>
  );
};

export default AceVersion;
