import { InfoItem as PuiInfoItem } from '@tackle-io/platform-ui';
import React from 'react';

import InfoText from './InfoText';

type InfoItemProps = {
  title: string;
  sensitive?: boolean;
  children?: React.ReactNode;
};

const EM_DASH = '—';

const InfoItem = ({ title, children, sensitive = false }: InfoItemProps) => {
  if (!children) {
    return <PuiInfoItem title={title}>{EM_DASH}</PuiInfoItem>;
  }

  if (typeof children === 'string') {
    const contents = (
      <InfoText sensitive={sensitive} clipboard text={children} />
    );

    return <PuiInfoItem title={title}>{contents}</PuiInfoItem>;
  }

  return <PuiInfoItem title={title}>{children}</PuiInfoItem>;
};

export default InfoItem;
