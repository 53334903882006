import { gql } from '@apollo/client';
import {
  Alert,
  Button,
  Checkbox,
  Select,
  TextField,
} from '@tackle-io/platform-ui';
import { Field, FieldProps, Form, Formik } from 'formik';
import {
  RedHatListingInput,
  RedHatListingUpdateModalContentsQuery,
  useRedHatListingUpdateModalContentsQuery,
  useUpdateRedHatListingUpdateModalContentsMutation,
} from 'generated/graphql';
import { useMemo } from 'react';
import getProgressTitleFromStatus from 'utils/getProgressTitleFromStatus';
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
} from 'vendor/material';
import { object as yupObject, string as yupString } from 'yup';

import { LISTING_STATUSES } from '../../../../../constants';

type Values = {
  marketplaceId: string;
  marketplaceUrl: string | null | undefined;
  status: string;
  redHatUsername: string | null | undefined;
  redHatPassword: string | null | undefined;
  sendCustomerEmail: boolean;
};

const containsColon = (str: string | undefined): boolean =>
  Boolean(str?.includes(':'));

const schema = yupObject().shape(
  {
    redHatUsername: yupString()
      .when('redHatPassword', {
        is: (redHatPassword: string) => redHatPassword,
        then: yupString().required('Username required to update').nullable(),
      })
      .test(
        'No colon',
        'Cannot contain a colon character',
        (s) => !containsColon(s),
      )
      .nullable(),
    redHatPassword: yupString()
      .when('redHatUsername', {
        is: (redHatUsername: string) => redHatUsername,
        then: yupString().required('Password required to update').nullable(),
      })
      .test(
        'No colon',
        'Cannot contain a colon character',
        (s) => !containsColon(s),
      )
      .nullable(),
    marketplaceUrl: yupString().url('Must be a valid URL.').nullable(),
    marketplaceId: yupString()
      .required('Marketplace ID cannot be empty')
      .matches(
        /^[^\\/?]+$/,
        'Marketplace ID cannot contain certain special characters',
      ),
    status: yupString().required(),
  },
  [['redHatUsername', 'redHatPassword']],
);

const valuesToListingUpdate = (
  values: Values,
): Omit<RedHatListingInput, 'id'> => ({
  marketplace_id: values.marketplaceId || null,
  marketplace_url: values.marketplaceUrl || null,
  status: values.status || null,
  red_hat_username: values.redHatUsername || null,
  red_hat_password: values.redHatPassword || null,
  send_customer_email: values.sendCustomerEmail,
});

const listingToValues = (
  redHatListing?: RedHatListingUpdateModalContentsQuery['redHatListing'],
): Values => ({
  marketplaceId: redHatListing?.marketplace_id ?? '',
  marketplaceUrl: redHatListing?.marketplace_url ?? '',
  status: redHatListing?.status ?? '',
  redHatUsername: redHatListing?.red_hat_username ?? '',
  redHatPassword: redHatListing?.red_hat_password ?? '',
  sendCustomerEmail: redHatListing?.send_customer_email ?? true,
});

gql`
  query RedHatListingUpdateModalContents($id: ID!) {
    redHatListing(id: $id) {
      id
      marketplace_id
      marketplace_url
      status
      red_hat_password
      red_hat_username
      send_customer_email
    }
  }
`;

gql`
  mutation UpdateRedHatListingUpdateModalContents(
    $updates: RedHatListingInput!
  ) {
    updateRedHatListing(updates: $updates) {
      id
      marketplace_id
      marketplace_url
      status
      red_hat_password
      red_hat_username
      send_customer_email
    }
  }
`;

const RedHatListingUpdateModalContents = ({
  onClose,
  listingId,
}: {
  onClose: () => void;
  listingId: string;
}) => {
  const { data, loading, error } = useRedHatListingUpdateModalContentsQuery({
    variables: { id: listingId },
  });
  const [updateValues, { error: mutationError, loading: mutationLoading }] =
    useUpdateRedHatListingUpdateModalContentsMutation({
      onCompleted: () => onClose(),
    });

  const initialValues = useMemo<Values>(
    () => listingToValues(data?.redHatListing),
    [data?.redHatListing],
  );
  if (loading) return <span>loading</span>;
  return (
    <Formik<Values>
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={async (values) =>
        updateValues({
          variables: {
            updates: {
              id: listingId,
              ...valuesToListingUpdate(values),
            },
          },
        })
      }
    >
      {({ dirty, errors, isValid }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                as={TextField}
                error={errors.marketplaceId}
                label="Marketplace ID"
                name="marketplaceId"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                error={errors.marketplaceUrl}
                label="Marketplace URL"
                name="marketplaceUrl"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={Select}
                error={errors.status}
                label="Status"
                name="status"
              >
                <>
                  <option disabled>Choose a value</option>
                  {LISTING_STATUSES.map((value) => {
                    return (
                      <option key={value} value={value}>
                        {getProgressTitleFromStatus(value)}
                      </option>
                    );
                  })}
                </>
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="redHatUsername">
                {({ field, meta }: FieldProps) => (
                  <TextField
                    {...field}
                    label="Red Hat Username"
                    error={meta.error}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="redHatPassword">
                {({ field, meta }: FieldProps) => (
                  <TextField
                    {...field}
                    type="password"
                    label="Red Hat Password"
                    error={meta.error}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="sendCustomerEmail">
                {({ field }: FieldProps<boolean>) => {
                  return (
                    <div>
                      <FormControlLabel
                        label="Send Customer Welcome Email"
                        control={
                          <Box mx={1}>
                            <Checkbox
                              {...field}
                              onChange={field.onChange}
                              checked={field.value}
                              aria-label="Customer Welcome Email"
                            />
                          </Box>
                        }
                      />
                      <FormHelperText>
                        Send the customer Welcome Email to the buyer at offer
                        acceptance
                      </FormHelperText>
                    </div>
                  );
                }}
              </Field>
            </Grid>
            {mutationError && (
              <Box mt={4}>
                <Alert
                  appearance="danger"
                  noShadow
                  hideIcon
                  title="Error: Unsuccessful product update. Create a support ticket, assign it to pod:streetsharks, and the team will reach out when the issue has been resolved."
                />
              </Box>
            )}
            <Grid item container spacing={2} alignItems="center">
              <Grid item xs>
                {error && (
                  <Typography color="error">There was an error</Typography>
                )}
              </Grid>
              <Grid item>
                <Button appearance="primary" onClick={onClose} variant="text">
                  Close
                </Button>
              </Grid>
              <Grid item>
                <Button
                  appearance="primary"
                  disabled={!isValid || !dirty}
                  type="submit"
                  variant="text"
                  loading={loading || mutationLoading}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default RedHatListingUpdateModalContents;
