import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, useLocation } from 'react-router-dom';
import { Box, Typography } from 'vendor/material';
import { makeStyles } from 'vendor/material';

type Error = {
  error: string;
  error_description: string;
};

const errorCodeDescriptionMap: { [index: string]: any } = {
  // Error code when `UnauthorizedError` is used in an auth0 rule callback.
  unauthorized: {
    user_not_invited:
      'You are not yet invited. Please contact your Tackle Administrator.',
    already_registered: "You're already registered. Please log in.",
  },
  // Error code when `Error` is used in a auth0 rule callback or
  // when `api.access.deny` is used in an auth0 action.
  access_denied: {
    user_not_invited:
      'You are not yet invited. Please contact your Tackle Administrator.',
    multiple_user_profiles_found: `We could not link this account because we found \
            multiple user profiles for this user.`,
    error_linking_azure_ad_user: `We could not link your Azure AD account.`,
    internal_error: `Something went wrong. Please try again.`,
    sso_connection_required: {
      okta: 'Please log in through your Okta Dashboard.',
    },
    sso_connection_not_found:
      'SSO Connection not found. Please contact your IT administrator.',
  },
};

const translateErrorToHumanReadable = (code: any, description: any) => {
  const unmappedErrorMessage = `${code}: ${description}`;
  const [desc, idp] = description.split(':');
  console.log(idp);
  const codeStr = code as string;
  if (errorCodeDescriptionMap && errorCodeDescriptionMap[codeStr]) {
    const description = errorCodeDescriptionMap[code][desc];
    if (idp !== undefined) {
      return description[idp];
    }
    if (description !== undefined) {
      return description;
    }
  }
  return unmappedErrorMessage;
};

// TODO: reused from login page. Replace w/ error page styles
const useErrorStyles = makeStyles((theme) => ({
  container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    overflow: 'hidden',
  },
  loginPage: {
    height: '100%',
    width: '100vw',
    backgroundColor: theme.palette.NEUTRAL010,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.NEUTRAL900,
    overflow: 'hidden',
    '&::before': {
      position: 'absolute',
      top: -750,
      left: -180,
      backgroundColor: theme.palette.NEUTRAL020,
      height: '100%',
      width: '150%',
      display: 'block',
      content: "''",
      transform: 'rotate(-15deg)',
      [theme.breakpoints.up(2559)]: {
        top: -1190,
      },
      [theme.breakpoints.down(1920)]: {
        top: -650,
      },
      [theme.breakpoints.down(1680)]: {
        top: -500,
      },
      [theme.breakpoints.down(1440)]: {
        top: -400,
      },
      [theme.breakpoints.down(1024)]: {
        top: -500,
      },
      [theme.breakpoints.down(768)]: {
        top: -900,
      },
    },
  },
  loginPageContainer: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    zIndex: 9,
    padding: theme.spacing(5),
    backgroundColor: theme.palette.NEUTRAL000,
    boxShadow: theme.shadows[4],
    borderRadius: 4,
    overflow: 'hidden',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  errorId: {
    color: theme.palette.NEUTRAL500,
  },
}));

const AuthErrorPage = () => {
  const classes = useErrorStyles();
  const auth0 = useAuth0();
  const { isLoading, isAuthenticated } = auth0;

  const { search } = useLocation();

  if (isLoading) {
    return null;
  }

  if (isAuthenticated) {
    return <Redirect to="/vendors" />;
  }

  const queryParams = new URLSearchParams(search);
  const error: Error = {
    error: queryParams.get('error') || '',
    error_description: queryParams.get('error_description') || '',
  };
  if (error.error) {
    return (
      <div className={classes.container}>
        <Box className={classes.loginPage}>
          <div className={classes.loginPageContainer}>
            <Typography variant="h4" className={classes.title}>
              Authentication Error:
            </Typography>
            <Typography variant="h5" className={classes.errorId}>
              {translateErrorToHumanReadable(
                error.error,
                error.error_description,
              )}
            </Typography>
          </div>
        </Box>
      </div>
    );
  } else {
    return <Redirect exact to="/vendors" />;
  }
};

export default AuthErrorPage;
