import { gql } from '@apollo/client';
import { Banner, FormSection, Link } from '@tackle-io/platform-ui';
import { useAzureListingQuery } from 'generated/graphql';
import {
  FaceAgent,
  FileDocument,
  FolderMultipleImage,
  License,
  Shape,
} from 'mdi-material-ui';
import React from 'react';
import { Box, Grid } from 'vendor/material';

import InfoDiffFile from '../InfoDiffFile/InfoDiffFile';
import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';
import LoadingListingContent from '../LoadingListingContent/LoadingListingContent';
import SectionIcon from '../SectionIcon/SectionIcon';
import AzureCategories from './AzureCategories';
import AzureIndustries from './AzureIndustries';
import AzureLicenseAgreement from './AzureLicenseAgreement';
import AzureMediaImages from './AzureMediaImages';
import AzureMediaVideos from './AzureMediaVideos';
import AzureMetrics from './AzureMetrics';
import AzureOverviewUrls from './AzureOverviewUrls';
import AzurePlans from './AzurePlans';
import AzureSupportDocuments from './AzureSupportDocuments';

interface ListingContentAzureProps {
  productidInternal: string;
}

export const AZURE_LISTING_QUERY = gql`
  fragment AzureListingFields on AzureListing {
    categories {
      category
      subcategory_1
      subcategory_2
    }
    full_description
    getting_started_instructions
    id
    industries {
      industry
      vertical_1
      vertical_2
    }
    license {
      contract_terms
      contract_type
      eula_url
    }
    logo
    marketplace_id
    media_image {
      caption
      image_url
    }
    media_video {
      thumbnail
      title
      video_url
    }
    name
    overview_urls {
      title
      url
    }
    pricing {
      metrics {
        display_name
        plan_pricing {
          base_quantity {
            quantity
            type
            unlimited
            value
          }
          plan_uuid
          price_per_unit
        }
        product_unit
        sku
      }
      plans {
        billing_terms {
          payment_option {
            type
            value
          }
          price_per_payment_in_usd
          type
          value
        }
        description
        free_trial
        markets
        name
        plan_id
        plan_uuid
        user_limits {
          max
          min
        }
        visibility {
          allowed_tenants {
            description
            tenant_id
          }
          public
        }
      }
      pricing_type
    }
    privacy_policy_link
    search_keywords
    short_description
    support {
      engineering_email
      engineering_name
      engineering_phone
      support_documents {
        document_url
        name
      }
      support_email
      support_name
      support_phone
      support_url
    }
    vendor {
      id
      configuration {
        id
        azure {
          app_id
          tenant_id
        }
      }
    }
    vendorid
    revision_of_product_id_internal
  }
  query AzureListing($id: ID!) {
    azureListing(id: $id) {
      ...AzureListingFields
      revision_of_listing {
        ...AzureListingFields
      }
    }
  }
`;

const ListingContentAzure: React.FC<ListingContentAzureProps> = ({
  productidInternal,
}) => {
  const { data: listingData, loading: isFetching } = useAzureListingQuery({
    variables: {
      id: productidInternal,
    },
  });

  const sourceListingData = listingData?.azureListing?.revision_of_listing?.id
    ? listingData?.azureListing?.revision_of_listing
    : listingData?.azureListing;

  if (isFetching) {
    return <LoadingListingContent />;
  }

  if (!listingData) {
    return <div>product info not found</div>;
  }

  return (
    <>
      {listingData?.azureListing?.revision_of_product_id_internal && (
        <Box mb={3}>
          <Banner
            type="info"
            title={`Listing copied from ${sourceListingData?.name}`}
            body={
              <Link
                to={`/vendor/${listingData?.azureListing?.vendorid}/listings/${listingData?.azureListing?.revision_of_product_id_internal}`}
              >
                {sourceListingData?.name}
              </Link>
            }
          />
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <InfoDiffItem
            title="Internal Product ID"
            sourceValue={sourceListingData?.id}
            revisionValue={listingData?.azureListing?.id}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <InfoDiffItem
            title="Marketplace Product ID"
            sourceValue={sourceListingData?.marketplace_id}
            revisionValue={listingData?.azureListing?.marketplace_id}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <InfoDiffItem
            title="Landing Page URL"
            sourceValue={`https://upstream-api.tackle.io/v1/azure/order/${sourceListingData?.vendorid}`}
            revisionValue={`https://upstream-api.tackle.io/v1/azure/order/${listingData?.azureListing?.vendorid}`}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <InfoDiffItem
            title="Connection Webhook"
            sourceValue={`https://upstream-api.tackle.io/v1/azure/update/${sourceListingData?.vendorid}`}
            revisionValue={`https://upstream-api.tackle.io/v1/azure/update/${listingData?.azureListing?.vendorid}`}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <InfoDiffItem
            title="Azure Active Directory Tenant ID"
            sourceValue={
              sourceListingData?.vendor?.configuration?.azure?.tenant_id
            }
            revisionValue={
              listingData?.azureListing?.vendor?.configuration?.azure?.tenant_id
            }
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <InfoDiffItem
            title="Azure Active Directory Application ID"
            sourceValue={
              sourceListingData?.vendor?.configuration?.azure?.app_id
            }
            revisionValue={
              listingData?.azureListing?.vendor?.configuration?.azure?.app_id
            }
          />
        </Grid>
      </Grid>
      <Box mt={3}>
        <FormSection
          title={
            <SectionIcon
              icon={<Shape fontSize="inherit" />}
              title="Product Categories"
            />
          }
          mb={1.5}
        >
          <AzureCategories
            sourceCategories={sourceListingData?.categories}
            revisionCategories={listingData?.azureListing?.categories}
          />
          <AzureIndustries
            sourceIndustries={sourceListingData?.industries}
            revisionIndustries={listingData?.azureListing?.industries}
          />
        </FormSection>
      </Box>
      <Box mt={3}>
        <FormSection
          title={
            <SectionIcon
              icon={<FileDocument fontSize="inherit" />}
              title="Overview"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <InfoDiffItem
                title="Listing Name"
                sourceValue={sourceListingData?.name}
                revisionValue={listingData?.azureListing?.name}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InfoDiffItem
                title="Short Description"
                sourceValue={sourceListingData?.short_description}
                revisionValue={listingData?.azureListing?.short_description}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InfoDiffItem
                title="Long Description"
                sourceValue={sourceListingData?.full_description}
                revisionValue={listingData?.azureListing?.full_description}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InfoDiffItem
                title="Getting Started Instructions"
                sourceValue={sourceListingData?.getting_started_instructions}
                revisionValue={
                  listingData?.azureListing?.getting_started_instructions
                }
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InfoDiffItem
                title="Search Keywords"
                sourceValue={sourceListingData?.search_keywords?.join(', ')}
                revisionValue={listingData?.azureListing?.search_keywords?.join(
                  ', ',
                )}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InfoDiffItem
                title="Privacy Policy Link"
                sourceValue={sourceListingData?.privacy_policy_link}
                revisionValue={listingData?.azureListing?.privacy_policy_link}
              />
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      <Box mt={3}>
        <FormSection
          title={
            <SectionIcon
              icon={<FaceAgent fontSize="inherit" />}
              title="Support"
            />
          }
          mb={1.5}
        >
          <AzureOverviewUrls
            sourceOverviewUrls={sourceListingData?.overview_urls}
            revisionOverviewUrls={listingData?.azureListing?.overview_urls}
          />
          <Grid container spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <InfoDiffItem
                title="Support Contact Name"
                sourceValue={sourceListingData?.support?.support_name}
                revisionValue={listingData?.azureListing?.support?.support_name}
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <InfoDiffItem
                title="Support Contact Email"
                sourceValue={sourceListingData?.support?.support_email}
                revisionValue={
                  listingData?.azureListing?.support?.support_email
                }
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <InfoDiffItem
                title="Support Contact Phone"
                sourceValue={sourceListingData?.support?.support_phone}
                revisionValue={
                  listingData?.azureListing?.support?.support_phone
                }
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <InfoDiffItem
                title="Support URL"
                sourceValue={sourceListingData?.support?.support_url}
                revisionValue={listingData?.azureListing?.support?.support_url}
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <InfoDiffItem
                title="Engineering Contact Name"
                sourceValue={sourceListingData?.support?.engineering_name}
                revisionValue={
                  listingData?.azureListing?.support?.engineering_name
                }
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <InfoDiffItem
                title="Engineering Contact Email"
                sourceValue={sourceListingData?.support?.engineering_email}
                revisionValue={
                  listingData?.azureListing?.support?.engineering_email
                }
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <InfoDiffItem
                title="Engineering Contact Phone"
                sourceValue={sourceListingData?.support?.engineering_phone}
                revisionValue={
                  listingData?.azureListing?.support?.engineering_phone
                }
              />
            </Grid>
          </Grid>
          <AzureSupportDocuments
            sourceSupportDocuments={
              sourceListingData?.support?.support_documents
            }
            revisionSupportDocuments={
              listingData?.azureListing?.support?.support_documents
            }
          />
        </FormSection>
      </Box>
      <Box mt={3}>
        <FormSection
          title={
            <SectionIcon
              icon={<FolderMultipleImage fontSize="inherit" />}
              title="Media"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <InfoDiffFile
                title="Logo"
                sourceFile={sourceListingData?.logo}
                sourceFileName={sourceListingData?.logo?.split('/').pop()}
                revisionFile={listingData?.azureListing?.logo}
                revisionFileName={listingData?.azureListing?.logo
                  ?.split('/')
                  .pop()}
                type="picture"
              />
            </Grid>
          </Grid>
          <AzureMediaImages
            sourceMediaImages={sourceListingData?.media_image}
            revisionMediaImages={listingData?.azureListing?.media_image}
          />
          <AzureMediaVideos
            sourceMediaVideos={sourceListingData?.media_video}
            revisionMediaVideos={listingData?.azureListing?.media_video}
          />
        </FormSection>
        <AzurePlans
          sourcePricing={sourceListingData?.pricing}
          revisionPricing={listingData?.azureListing?.pricing}
        />
        <AzureMetrics
          sourcePricing={sourceListingData?.pricing}
          revisionPricing={listingData?.azureListing?.pricing}
        />
      </Box>
      <Box mt={3}>
        <FormSection
          title={
            <SectionIcon icon={<License fontSize="inherit" />} title="Legal" />
          }
          mb={1.5}
        >
          <AzureLicenseAgreement
            sourceLicenseAgreement={sourceListingData?.license}
            revisionLicenseAgreement={listingData?.azureListing?.license}
          />
        </FormSection>
      </Box>
    </>
  );
};

export default ListingContentAzure;
