import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { Box, Typography } from 'vendor/material';
import { makeStyles } from 'vendor/material';

import useStyles from './App.styles';
import Routes from './Routes';

// TODO: reused from login page. Replace w/ error page styles
const useErrorStyles = makeStyles((theme) => ({
  container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    overflow: 'hidden',
  },
  loginPage: {
    height: '100%',
    width: '100vw',
    backgroundColor: theme.palette.NEUTRAL010,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.NEUTRAL900,
    overflow: 'hidden',
    '&::before': {
      position: 'absolute',
      top: -750,
      left: -180,
      backgroundColor: theme.palette.NEUTRAL020,
      height: '100%',
      width: '150%',
      display: 'block',
      content: "''",
      transform: 'rotate(-15deg)',
      [theme.breakpoints.up(2559)]: {
        top: -1190,
      },
      [theme.breakpoints.down(1920)]: {
        top: -650,
      },
      [theme.breakpoints.down(1680)]: {
        top: -500,
      },
      [theme.breakpoints.down(1440)]: {
        top: -400,
      },
      [theme.breakpoints.down(1024)]: {
        top: -500,
      },
      [theme.breakpoints.down(768)]: {
        top: -900,
      },
    },
  },
  loginPageContainer: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    zIndex: 9,
    padding: theme.spacing(5),
    backgroundColor: theme.palette.NEUTRAL000,
    boxShadow: theme.shadows[4],
    borderRadius: 4,
    overflow: 'hidden',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  errorId: {
    color: theme.palette.NEUTRAL500,
  },
}));

const ErrorPage = ({ eventId }: { eventId: string | null }) => {
  const classes = useErrorStyles();
  return (
    <div className={classes.container}>
      <Box className={classes.loginPage}>
        <div className={classes.loginPageContainer}>
          <Typography variant="h5" className={classes.title}>
            Uh oh! Something unexpected occurred.
          </Typography>
          <Typography className={classes.errorId}>
            Error ID: {eventId}
          </Typography>
        </div>
      </Box>
    </div>
  );
};

function App() {
  const classes = useStyles();
  return (
    <SentryErrorBoundary fallback={ErrorPage}>
      <div className={classes.app} data-testid="app-wrapper">
        <Routes />
      </div>
    </SentryErrorBoundary>
  );
}

export default App;
