import { Button } from '@tackle-io/platform-ui';
import { useState } from 'react';
import { Grid } from 'vendor/material';

import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';
import InfoList, { InfoListItem } from '../InfoList/InfoList';

const KeywordsDiff = ({
  sourceKeywords,
  revisionKeywords,
}: {
  sourceKeywords: string | null | undefined;
  revisionKeywords: string | null | undefined;
}) => {
  const [expandKeywords, setExpandKeywords] = useState(false);

  if (!sourceKeywords && !revisionKeywords) {
    return null;
  }

  const keywords = (
    revisionKeywords
      ? revisionKeywords.split(',')
      : sourceKeywords
      ? sourceKeywords.split(',')
      : []
  ).filter((keyword) => !!keyword && keyword.trim());

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <InfoDiffItem
          title="Search Keywords"
          sourceValue={sourceKeywords}
          revisionValue={revisionKeywords}
        />
      </Grid>
      <Grid item>
        <Button size="small" onClick={() => setExpandKeywords(!expandKeywords)}>
          {expandKeywords
            ? 'Hide Keywords (Individual click to copy)'
            : 'Expand Keywords (Individual click to copy)'}
        </Button>
        {expandKeywords && (
          <InfoList>
            {keywords.map((keyword, i) => {
              return (
                <InfoListItem key={i}>
                  <InfoDiffItem sourceValue={keyword} revisionValue={keyword} />
                </InfoListItem>
              );
            })}
          </InfoList>
        )}
      </Grid>
    </Grid>
  );
};

export default KeywordsDiff;
