import { Buffer } from 'buffer';

// Function to decode the encoded Base64 GCP config project service_account string back to JSON
// This will be temporary until we add proper encryption and access patterns for the service account key
export const decodeBase64ToJson = (base64String: string): string | null => {
  // The Buffer method is a Node.js
  const jsonString = Buffer.from(base64String, 'base64').toString();

  try {
    const parsedJson = JSON.parse(jsonString);
    return parsedJson;
  } catch (err) {
    console.log('error', err);
    return null;
  }
};

// Create and download a JSON file given a JSON object
// as found in SO: https://stackoverflow.com/questions/62591592/how-to-download-a-json-object-as-file
export const downloadJsonFile = (jsonObj: any, fileName: string): void => {
  if (!jsonObj) {
    return;
  }
  const blob = new Blob([jsonObj], { type: 'application/json' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const downloadedFileName = fileName;
  link.download = downloadedFileName;
  link.click();
  link.remove();
};
