import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { LocalStorageWrapper, persistCacheSync } from 'apollo3-cache-persist';
import { addVendorListPageDataToCache } from 'pages/VendorListPage';

const tokenOverride = localStorage.getItem('tokenOverride');

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      const locsStr = JSON.stringify(locations);
      console.error(
        new Error(
          `[GraphQL error]: Message: ${message}, Location: ${locsStr}, Path: ${path}`,
        ),
      );
    });
  if (networkError) reportError(networkError);
});

const httpLink = createHttpLink({
  uri: tokenOverride
    ? 'https://tackle-gql.tackle.io/graphql'
    : `${process.env.REACT_APP_GRAPHQL_SERVER_URL}/graphql`,
  fetchOptions: { mode: 'cors' },
});

const retryLink = new RetryLink();

const cache = new InMemoryCache({
  typePolicies: {
    VendorBillingConfiguration: { merge: true },
    VendorConfiguration: { merge: true },
  },
});

try {
  persistCacheSync({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
    persistenceMapper: async (dataString: string) => {
      try {
        const data = JSON.parse(dataString);
        const mappedData = {};
        // for now this is _only_ meant to cache data for the VendorListPage since it uses
        // `fetchPolicy: 'cache-and-network'` so it will load fast but update behind the scenes
        addVendorListPageDataToCache(data, mappedData);
        // console.log({ data, mappedData });
        return JSON.stringify(mappedData);
      } catch (error) {
        console.error('Error in persistenceMapper', error);
        return null;
      }
    },
  });
} catch (error) {
  console.error('Error instantiating persistCacheSync', error);
}

const getApolloClient = (
  authLink: ApolloLink,
): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    link: from([errorLink, retryLink, authLink, httpLink]),
    cache,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
  });

export default getApolloClient;
