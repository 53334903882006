import { InfoItem as PuiInfoItem } from '@tackle-io/platform-ui';
import {
  AwsPricingContractDimension,
  AwsPricingContractDimensionPrices,
} from 'generated/graphql';
import { zip } from 'lodash';
import React from 'react';
import { Grid } from 'vendor/material';

import { formatPrice } from '../../VendorListings.helpers';
import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';
import InfoList, { InfoListItem } from '../InfoList/InfoList';

type AwsDimensionsProps = {
  sourceDimensions: Array<AwsPricingContractDimension> | null | undefined;
  revisionDimensions: Array<AwsPricingContractDimension> | null | undefined;
};

type PricesProps = {
  sourcePrices: AwsPricingContractDimensionPrices | null | undefined;
  revisionPrices: AwsPricingContractDimensionPrices | null | undefined;
};

const DURATION_MATCHER = {
  one_year: '1-year',
  two_year: '2-year',
  three_year: '3-year',
  monthly: 'Monthly',
};

const DEFAULT_DURATIONS = {
  one_year: '',
  two_year: '',
  three_year: '',
  monthly: '',
};

const Prices: React.FC<PricesProps> = ({ sourcePrices, revisionPrices }) => {
  if (!sourcePrices && !revisionPrices) {
    return null;
  }

  return (
    <InfoList>
      {Object.entries(DEFAULT_DURATIONS).map((item) => {
        const duration = item[0] as keyof typeof DURATION_MATCHER;

        if (!sourcePrices?.[duration] && !revisionPrices?.[duration])
          return null;

        return (
          <InfoListItem key={duration}>
            <b>{DURATION_MATCHER[duration]}: </b>
            <InfoDiffItem
              sourceValue={
                sourcePrices?.[duration]
                  ? formatPrice(sourcePrices?.[duration] ?? '')
                  : ''
              }
              revisionValue={
                revisionPrices?.[duration]
                  ? formatPrice(revisionPrices?.[duration] ?? '')
                  : ''
              }
            />
          </InfoListItem>
        );
      })}
    </InfoList>
  );
};

const AwsDimensions: React.FC<AwsDimensionsProps> = ({
  sourceDimensions,
  revisionDimensions,
}) => {
  const combinedAwsDimensions = zip(
    sourceDimensions ?? [],
    revisionDimensions ?? [],
  );

  if (!combinedAwsDimensions?.length) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <InfoDiffItem title="Pricing Items" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {combinedAwsDimensions.map(([source, revision], ind) => {
        return (
          <React.Fragment key={ind}>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <InfoDiffItem
                title={`Pricing Item Name ${ind + 1}`}
                sourceValue={source?.name}
                revisionValue={revision?.name}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <InfoDiffItem
                title={`Pricing Item SKU ${ind + 1}`}
                sourceValue={source?.sku}
                revisionValue={revision?.sku}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <InfoDiffItem
                title={`Pricing Item Description ${ind + 1}`}
                sourceValue={source?.description}
                revisionValue={revision?.description}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <PuiInfoItem title={`Pricing Item Per Duration ${ind + 1}`}>
                <Prices
                  sourcePrices={source?.prices}
                  revisionPrices={revision?.prices}
                />
              </PuiInfoItem>
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

export default AwsDimensions;
