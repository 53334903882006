import React from 'react';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  dividerDashed: {
    width: '100%',
    height: '12px',
    borderBottom: `1px dashed ${theme.palette.NEUTRAL400}`,
    borderBottomWidth: 'thin',
    textAlign: 'center',
  },
  dividerTitle: {
    fontSize: '1rem',
    backgroundColor: theme.palette.NEUTRAL000,
    padding: '10px 20px 0px 20px',
  },
}));

const DashedDivider = ({ title }: { title: string }) => {
  const classes = useStyles();
  return (
    <div className={classes.dividerDashed}>
      <span className={classes.dividerTitle}>{title}</span>
    </div>
  );
};

export default DashedDivider;
