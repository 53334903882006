import { FormSection } from '@tackle-io/platform-ui';
import InfoItem from 'components/InfoItem';
import { GcpFeature, GcpMetric, GcpPlan, Maybe } from 'generated/graphql';
import { keyBy, some, union, zip } from 'lodash';
import { PackageVariant } from 'mdi-material-ui';
import React from 'react';
import { Box, Grid } from 'vendor/material';

import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';
import InfoList, { InfoListItem } from '../InfoList/InfoList';
import SectionIcon from '../SectionIcon/SectionIcon';

type durationDisplayMapping = {
  [key: string]: string;
};

const SORTED_DURATIONS = ['P1M', 'P1Y', 'P2Y', 'P3Y'];
const DURATION_DISPLAY_MAPPING: durationDisplayMapping = {
  P1M: 'One Month',
  P1Y: 'One Year',
  P2Y: 'Two Years',
  P3Y: 'Three Years',
};

type GcpPricingProps = {
  sourceFeatures: Array<GcpFeature> | null | undefined;
  sourcePlans: Array<GcpPlan> | null | undefined;
  sourceMetrics: Array<GcpMetric> | null | undefined;
  revisionFeatures: Array<GcpFeature> | null | undefined;
  revisionPlans: Array<GcpPlan> | null | undefined;
  revisionMetrics: Array<GcpMetric> | null | undefined;
};

const getGcpPricingOption = (
  metrics: (Maybe<string> | undefined)[] | undefined,
) => {
  if (!metrics) return '';

  return metrics?.length ? 'Subscription + Usage' : 'Subscription';
};

const getGcpPricingVisibility = (isPublic: boolean | null | undefined) => {
  if (isPublic === undefined || isPublic === null) return '';

  return isPublic ? 'Public' : 'Protected';
};

const GcpPricing: React.FC<GcpPricingProps> = ({
  sourceFeatures,
  sourcePlans,
  sourceMetrics,
  revisionFeatures,
  revisionPlans,
  revisionMetrics,
}) => {
  const sourceMetricsByName = keyBy(sourceMetrics, 'name');
  const revisionMetricsByName = keyBy(revisionMetrics, 'name');

  const sourceFeaturesByFeatureId = keyBy(sourceFeatures, 'feature_id');
  const revisionFeaturesByFeatureId = keyBy(revisionFeatures, 'feature_id');

  const buildSortedPricing = (plan: GcpPlan | null | undefined) => {
    let outputArray: string[] = [];

    SORTED_DURATIONS.forEach((duration) => {
      if (plan?.pricing?.[duration] !== undefined) {
        const displayDuration = DURATION_DISPLAY_MAPPING[duration];
        const price = plan?.pricing[duration];
        let outputString = `${displayDuration} - $${price}`;
        if (!outputArray.includes(outputString)) {
          outputArray.push(outputString);
        }
      } else {
        outputArray?.push(`${DURATION_DISPLAY_MAPPING[duration]}`);
      }
    });

    return outputArray;
  };

  const addMetricNamesAndSortedPricingToPlans = (
    plans: GcpPlan[] | null | undefined,
    metrics: GcpMetric[] | null | undefined,
  ) => {
    return plans?.map((plan) => {
      return {
        ...plan,
        sortedPricing: buildSortedPricing(plan),
        metrics: metrics
          ?.filter((m) =>
            some(m?.price_per_unit, (ppu) => ppu?.plan_id === plan?.plan_id),
          )
          .map((m) => m?.name),
      };
    });
  };

  const sourcePlansByPlanId = keyBy(
    addMetricNamesAndSortedPricingToPlans(sourcePlans, sourceMetrics),
    'plan_id',
  );
  const revisionPlansByPlanId = keyBy(
    addMetricNamesAndSortedPricingToPlans(revisionPlans, revisionMetrics),
    'plan_id',
  );

  const planIds = union(
    Object.keys(sourcePlansByPlanId),
    Object.keys(revisionPlansByPlanId),
  );

  const combinedPlans = planIds.map((id) => {
    return [sourcePlansByPlanId[id], revisionPlansByPlanId[id]];
  });

  return (
    <>
      {combinedPlans.map(([source, revision], index) => {
        return (
          <Box mt={3} key={index}>
            <FormSection
              title={
                <SectionIcon
                  icon={<PackageVariant fontSize="inherit" />}
                  title={
                    source?.name === revision?.name
                      ? `Plan - ${revision?.name}`
                      : '{Plan name}'
                  }
                />
              }
              mb={1.5}
            >
              <Grid container spacing={2}>
                {source?.name !== revision?.name && (
                  <Grid item xs={12}>
                    <InfoDiffItem
                      title="Plan name"
                      sourceValue={source?.name || ''}
                      revisionValue={revision?.name || ''}
                    />
                  </Grid>
                )}
                <Grid item xs={3} md={3} lg={3}>
                  <InfoDiffItem
                    title="Pricing Option"
                    sourceValue={getGcpPricingOption(source?.metrics)}
                    revisionValue={getGcpPricingOption(revision?.metrics)}
                  />
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                  <InfoDiffItem
                    title="Visibility"
                    sourceValue={getGcpPricingVisibility(source?.public)}
                    revisionValue={getGcpPricingVisibility(revision?.public)}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <InfoItem title="Subscription Periods">
                    {zip(
                      source?.sortedPricing ?? [],
                      revision?.sortedPricing ?? [],
                    ).length > 0 && (
                      <InfoList>
                        {zip(
                          source?.sortedPricing ?? [],
                          revision?.sortedPricing ?? [],
                        ).map(
                          ([sourcePricingOpt, revisionPricingOpt], index) => {
                            return (
                              <InfoListItem key={index}>
                                <strong>{`${
                                  revisionPricingOpt?.split('$')[0] ??
                                  sourcePricingOpt?.split('$')[0]
                                }`}</strong>{' '}
                                $
                                <InfoDiffItem
                                  sourceValue={
                                    sourcePricingOpt?.split('$')[1] || ''
                                  }
                                  revisionValue={
                                    revisionPricingOpt?.split('$')[1] || ''
                                  }
                                />
                              </InfoListItem>
                            );
                          },
                        )}
                      </InfoList>
                    )}
                  </InfoItem>
                </Grid>
                {union(source?.metrics, revision?.metrics)?.map(
                  (name, index) => (
                    <React.Fragment key={name}>
                      <Grid item xs={4} md={4} lg={4}>
                        <InfoDiffItem
                          title={`Metric Name ${index + 1}`}
                          sourceValue={
                            source?.metrics?.includes(name)
                              ? sourceMetricsByName?.[name!]?.name
                              : ''
                          }
                          revisionValue={
                            revision?.metrics?.includes(name)
                              ? revisionMetricsByName?.[name!]?.name
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={4} md={4} lg={4}>
                        <InfoDiffItem
                          title={`Reporting Unit ${index + 1}`}
                          sourceValue={
                            source?.metrics?.includes(name)
                              ? sourceMetricsByName?.[name!]?.unit
                              : ''
                          }
                          revisionValue={
                            revision?.metrics?.includes(name)
                              ? revisionMetricsByName?.[name!]?.unit
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={4} md={4} lg={4}>
                        <InfoDiffItem
                          title={`Price Per Unit ${index + 1}`}
                          sourceValue={
                            source?.metrics?.includes(name)
                              ? '$' +
                                sourceMetricsByName?.[name!]?.price_per_unit
                                  ?.find(
                                    (ppu) => ppu?.plan_id === source?.plan_id,
                                  )
                                  ?.price?.toString()
                              : ''
                          }
                          revisionValue={
                            revision?.metrics?.includes(name)
                              ? '$' +
                                revisionMetricsByName?.[name!]?.price_per_unit
                                  ?.find(
                                    (ppu) => ppu?.plan_id === revision?.plan_id,
                                  )
                                  ?.price?.toString()
                              : ''
                          }
                        />
                      </Grid>
                    </React.Fragment>
                  ),
                )}
                {union(source?.feature_ids, revision?.feature_ids)?.map(
                  (id, index) => (
                    <React.Fragment key={id}>
                      <Grid item xs={6} md={6} lg={6}>
                        <InfoDiffItem
                          title={`Feature Label ${index + 1}`}
                          sourceValue={
                            source?.feature_ids?.includes(id)
                              ? sourceFeaturesByFeatureId[id]?.label
                              : ''
                          }
                          revisionValue={
                            revision?.feature_ids?.includes(id)
                              ? revisionFeaturesByFeatureId[id]?.label
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <InfoDiffItem
                          title={`Feature Description ${index + 1}`}
                          sourceValue={
                            source?.feature_ids?.includes(id)
                              ? sourceFeaturesByFeatureId[id]?.description
                              : ''
                          }
                          revisionValue={
                            revision?.feature_ids?.includes(id)
                              ? revisionFeaturesByFeatureId[id]?.description
                              : ''
                          }
                        />
                      </Grid>
                    </React.Fragment>
                  ),
                )}
              </Grid>
            </FormSection>
          </Box>
        );
      })}
    </>
  );
};

export default GcpPricing;
