import { CompanyAvatar } from '@tackle-io/platform-ui';
import classNames from 'classnames';
import React from 'react';
import { Typography } from 'vendor/material';

import useStyles from './CompanyItem.styles';

interface CompanyItemProps {
  name: string;
  vendorId: string;
  logo: string | null | undefined;
  onClick?: () => void;
  children?: React.ReactNode;
}

interface CompanyItemTemplateProps {
  onClick?: () => void;
  Logo: React.ReactElement | null;
  Name: React.ReactElement;
  Vendorid: React.ReactElement;
  children?: React.ReactNode;
}

const CompanyItemTemplate = ({
  onClick,
  Logo,
  Name,
  Vendorid,
  children,
}: CompanyItemTemplateProps) => {
  const classes = useStyles();

  const handleClick = (): void => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={classNames(classes.container, {
        [classes.clickable]: !!onClick,
      })}
      onClick={handleClick}
      role="presentation"
    >
      <div className={classes.left}>
        <div className={classes.logoContainer}>{Logo}</div>
        <div className={classes.content}>
          {Name}
          {Vendorid}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

const CompanyItem = ({
  name,
  vendorId,
  logo,
  onClick,
  children,
}: CompanyItemProps) => {
  const classes = useStyles();

  return (
    <CompanyItemTemplate
      onClick={onClick}
      Logo={
        logo ? <CompanyAvatar logo={logo} className={classes.logo} /> : null
      }
      Name={<Typography className={classes.name}>{name}</Typography>}
      Vendorid={
        <Typography className={classes.vendorId}>
          Vendor ID: {vendorId}
        </Typography>
      }
    >
      {children}
    </CompanyItemTemplate>
  );
};

export default CompanyItem;
