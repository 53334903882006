import { gql } from '@apollo/client';
import { Alert, Button, Modal, TextField } from '@tackle-io/platform-ui';
import { Field, Form, Formik } from 'formik';
import { useCreateVendorMutation } from 'generated/graphql';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from 'vendor/material';
import { object as yupObject, string as yupString } from 'yup';

type FormValues = {
  name: string;
  email: string;
};

const schema = yupObject().shape({
  name: yupString().required(),
  email: yupString().email().required(),
});

const CREATE_VENDOR_MUTATION = gql`
  mutation CreateVendor($vendor: CreateVendorInput!) {
    createVendor(data: $vendor) {
      id
    }
  }
`;

const CreateVendorModalButton: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const [createVendor, { loading, error }] = useCreateVendorMutation({
    update: (cache, { data }) =>
      cache.modify({
        fields: {
          vendors(paginatedVendors) {
            const newVendorRef = cache.writeQuery({
              query: CREATE_VENDOR_MUTATION,
              data,
            });
            return {
              ...paginatedVendors,
              vendors: [...paginatedVendors.vendors, newVendorRef],
            };
          },
        },
      }),
  });
  return (
    <>
      <Button appearance="primary" onClick={(): void => setModalOpen(true)}>
        Create vendor
      </Button>
      <Modal title="Create vendor" width="medium" open={modalOpen}>
        <Formik<FormValues>
          initialValues={{
            name: '',
            email: '',
          }}
          onSubmit={async ({ name, email }: FormValues): Promise<void> => {
            const { data } = await createVendor({
              variables: {
                vendor: { name, email },
              },
            });
            history.push(`/vendor/${data?.createVendor?.id}`);
          }}
          validationSchema={schema}
        >
          {({ dirty, errors, isValid }): React.ReactElement => {
            return (
              <Form>
                <Grid container direction="column" spacing={2}>
                  {error && (
                    <Grid item xs={12}>
                      <Alert
                        appearance="warning"
                        title="Something went wrong"
                        noShadow
                        size="small"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          error={errors?.name}
                          label="Company name"
                          name="name"
                          disabled={loading}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          error={errors?.email}
                          label="Order notification email address"
                          name="email"
                          disabled={loading}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container justify="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        appearance="primary"
                        disabled={loading}
                        variant="text"
                        onClick={(): void => setModalOpen(false)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        appearance="primary"
                        disabled={loading || !isValid || !dirty}
                        type="submit"
                        variant="text"
                        loading={loading}
                      >
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default CreateVendorModalButton;
