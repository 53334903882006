import { gql } from '@apollo/client';
import { Button, Code, Modal } from '@tackle-io/platform-ui';
import {
  useCopyListingAwsListingMutation,
  useCopyListingAzureListingMutation,
  useCopyListingGcpListingMutation,
  useCopyListingRedHatListingMutation,
} from 'generated/graphql';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, makeStyles, Typography } from 'vendor/material';

import { VENDOR_LISTINGS_QUERY } from '../VendorListings';

gql`
  mutation CopyListingAwsListing($id: ID!) {
    copyAwsListing(id: $id) {
      id
    }
  }
`;

gql`
  mutation CopyListingAzureListing($id: ID!) {
    copyAzureListing(id: $id) {
      id
    }
  }
`;

gql`
  mutation CopyListingGcpListing($id: ID!) {
    copyGcpListing(id: $id) {
      id
    }
  }
`;

gql`
  mutation CopyListingRedHatListing($id: ID!) {
    copyRedHatListing(id: $id) {
      id
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  button: {
    paddingRight: theme.spacing(2),
  },
}));

interface CopyListingProps {
  productName: string;
  id: string;
  cloud: 'aws' | 'gcp' | 'azure' | 'redhat';
  vendorId: string;
}

const CopyListing: React.FC<CopyListingProps> = ({
  productName,
  id,
  cloud,
  vendorId,
}) => {
  const history = useHistory();
  const [confirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);
  const classes = useStyles();
  const [
    copyListingAwsListingMutation,
    { error: awsCopyError, loading: awsCopyLoading },
  ] = useCopyListingAwsListingMutation({
    refetchQueries: [VENDOR_LISTINGS_QUERY],
  });
  const [
    copyListingAzureListingMutation,
    { error: azureCopyError, loading: azureCopyLoading },
  ] = useCopyListingAzureListingMutation({
    refetchQueries: [VENDOR_LISTINGS_QUERY],
  });
  const [
    copyListingGcpListingMutation,
    { error: gcpCopyError, loading: gcpCopyLoading },
  ] = useCopyListingGcpListingMutation({
    refetchQueries: [VENDOR_LISTINGS_QUERY],
  });
  const [
    copyListingRedHatListingMutation,
    { error: redHatCopyError, loading: redHatCopyLoading },
  ] = useCopyListingRedHatListingMutation({
    refetchQueries: [VENDOR_LISTINGS_QUERY],
  });

  const error =
    awsCopyError || azureCopyError || gcpCopyError || redHatCopyError;
  const loading =
    awsCopyLoading || azureCopyLoading || gcpCopyLoading || redHatCopyLoading;

  const handleOpenConfirmationModal = (event: React.MouseEvent): void => {
    event.stopPropagation();
    setConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = (): void =>
    setConfirmationModalOpen(false);

  const handleCopyListing = async () => {
    if (cloud === 'aws') {
      const response = await copyListingAwsListingMutation({
        variables: {
          id,
        },
      });
      handleCloseConfirmationModal();
      history.push(
        `/vendor/${vendorId}/listings/${response.data?.copyAwsListing?.id}`,
      );
    }
    if (cloud === 'azure') {
      const response = await copyListingAzureListingMutation({
        variables: {
          id,
        },
      });
      handleCloseConfirmationModal();
      history.push(
        `/vendor/${vendorId}/listings/${response.data?.copyAzureListing?.id}`,
      );
    }
    if (cloud === 'gcp') {
      const response = await copyListingGcpListingMutation({
        variables: {
          id,
        },
      });
      handleCloseConfirmationModal();
      history.push(
        `/vendor/${vendorId}/listings/${response.data?.copyGcpListing?.id}`,
      );
    }
    if (cloud === 'redhat') {
      const response = await copyListingRedHatListingMutation({
        variables: {
          id,
        },
      });
      handleCloseConfirmationModal();
      history.push(
        `/vendor/${vendorId}/listings/${response.data?.copyRedHatListing?.id}`,
      );
    }
  };

  return (
    <div className={classes.button}>
      <Button
        size="small"
        variant="outlined"
        appearance="secondary"
        onClick={handleOpenConfirmationModal}
      >
        Copy listing
      </Button>
      <Modal
        title={`You are about to clone the listing: ${productName}`}
        width="medium"
        open={confirmationModalOpen}
        // @ts-ignore issue in platform-ui
        onClose={(e, reason) => {
          if (reason !== 'backdropClick') handleCloseConfirmationModal();
        }}
        footerActions={
          <>
            <Button
              disabled={loading}
              appearance="primary"
              variant="text"
              onClick={() => handleCloseConfirmationModal()}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              type="submit"
              appearance="primary"
              variant="text"
              onClick={() => handleCopyListing()}
            >
              {error ? 'Try again?' : 'Yes'}
            </Button>
          </>
        }
      >
        <Box maxHeight="100%">
          <Typography>Are you sure you would like to proceed?</Typography>
          {error && (
            <>
              <Typography color="error">
                There was an error, if this persist please open a support
                ticket: {error.message}
              </Typography>
              <Box maxHeight={200} overflow="scroll">
                <Code language="json">
                  {JSON.stringify(error, undefined, 2)}
                </Code>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default CopyListing;
