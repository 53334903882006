import InfoItem from 'components/InfoItem';
import { RedHatPricingEditionCharge as RedHatPricingEditionChargeType } from 'generated/graphql';
import { Box, Grid } from 'vendor/material';

import DashedDivider from './DashedDivider';

interface RedHatPricingEditionChargeProps {
  charge: RedHatPricingEditionChargeType;
  index: number;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const RedHatPricingEditionCharge = ({
  charge,
  index,
}: RedHatPricingEditionChargeProps) => {
  return (
    <>
      <Grid item xs={12}>
        <Box my={3}>
          <DashedDivider title={`Charges ${index + 1}`} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <InfoItem title="Name">{charge.name}</InfoItem>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <InfoItem title="Required Charge">
          {charge.required ? 'Yes' : 'No'}
        </InfoItem>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <InfoItem title="Included in Starting Price">
          {charge.include_in_starting_price ? 'Yes' : 'No'}
        </InfoItem>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box pt={2}>
          <InfoItem title="Description">{charge.name_description}</InfoItem>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box pt={2}>
          <InfoItem title="Sequence">{charge.sequence}</InfoItem>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box pt={2}>
          <InfoItem title="Price Type">{charge.price_type}</InfoItem>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box pt={2}>
          <InfoItem title="Charge Type">{charge.charge_type}</InfoItem>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box pt={2}>
          <InfoItem title="Vendor Charge ID">{charge.vendor_chargeid}</InfoItem>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box pt={2}>
          <InfoItem title="Price Frequency">{charge.price_frequency}</InfoItem>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box pt={2}>
          <InfoItem title="Minimum Quantity">
            {charge.minimum_quantity}
          </InfoItem>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box pt={2}>
          <InfoItem title="Maximum Quantity">
            {charge.maximum_quantity}
          </InfoItem>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box pt={2}>
          <InfoItem title="Increment">{charge.increment}</InfoItem>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box pt={2}>
          <InfoItem title="Unit of Measure">{charge.unit_of_measure}</InfoItem>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box pt={2}>
          <InfoItem title="Usage Reporting ID">
            {charge.usage_reportingid}
          </InfoItem>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box pt={2}>
          <InfoItem title="Price">{charge.price}</InfoItem>
        </Box>
      </Grid>
    </>
  );
};

export default RedHatPricingEditionCharge;
