import { makeStyles } from 'vendor/material';

export default makeStyles((theme) => ({
  cardWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    borderBottom: `1px solid ${theme.palette.NEUTRAL040}`,
    position: 'relative',
  },
  cardHeaderLeft: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.NEUTRAL900,
  },
  cardHeaderTitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(18),
  },
  cardHeaderIcon: {
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(2),
  },
  cardBody: {
    background: theme.palette.NEUTRAL010,
    overflow: 'auto',
  },
  cardBodyWrapper: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
  },
  configSection: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  subtitle: {
    color: theme.palette.NEUTRAL600,
    borderBottom: `1px solid ${theme.palette.NEUTRAL030}`,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(18),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  configIcon: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(24),
  },
  configButton: {
    minWidth: theme.spacing(4),
    minHeight: theme.spacing(4),
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  cloudLogo: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    minWidth: theme.spacing(2),
  },
  cloudInformation: {
    minWidth: theme.spacing(4.5),
  },
  cloudConfig: {
    marginTop: theme.spacing(6),
  },
  checkboxWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  loadingBar: {
    position: 'absolute',
    height: 2,
    bottom: 0,
    left: 0,
    right: 0,
  },
  coSellOverride: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
  },
  checkboxSpacing: {
    '& span:last-child': {
      paddingLeft: theme.spacing(1),
    },
  },
  configCodeContainer: {
    backgroundColor: theme.palette.NEUTRAL010,
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down(800)]: {
      padding: theme.spacing(2, 2),
    },
  },
  label: {
    color: theme.palette.NEUTRAL300,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(0.5),
  },
}));
