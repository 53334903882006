import { AzureMediaVideo } from 'generated/graphql';
import { zip } from 'lodash';
import React from 'react';
import { Grid } from 'vendor/material';

import InfoDiffFile from '../InfoDiffFile/InfoDiffFile';
import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';

type AzureMediaVideosProps = {
  sourceMediaVideos: Array<AzureMediaVideo> | null | undefined;
  revisionMediaVideos: Array<AzureMediaVideo> | null | undefined;
};

const AzureMediaVideos: React.FC<AzureMediaVideosProps> = ({
  sourceMediaVideos = [],
  revisionMediaVideos = [],
}) => {
  const combinedMediaVideos = zip(
    sourceMediaVideos ?? [],
    revisionMediaVideos ?? [],
  );

  if (!combinedMediaVideos?.length) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4} md={4} lg={4}>
          <InfoDiffItem title="Video Title 1" />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <InfoDiffItem title="Video URL 1" />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <InfoDiffItem title="Video Thumbnail 1" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {combinedMediaVideos.map(([source, revision], index) => (
        <React.Fragment key={index}>
          <Grid item xs={4} md={4} lg={4}>
            <InfoDiffItem
              title={`Video Title ${index + 1}`}
              sourceValue={source?.title}
              revisionValue={revision?.title}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <InfoDiffItem
              title={`Video URL ${index + 1}`}
              sourceValue={source?.video_url}
              revisionValue={revision?.video_url}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <InfoDiffFile
              type="picture"
              title={`Video Thumbnail ${index + 1}`}
              sourceFile={source?.thumbnail}
              sourceFileName={source?.thumbnail?.split('/').pop()}
              revisionFile={revision?.thumbnail}
              revisionFileName={revision?.thumbnail?.split('/').pop()}
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default AzureMediaVideos;
