import { gql } from '@apollo/client';
import { Tabs } from '@tackle-io/platform-ui';
import { useVendorQuery } from 'generated/graphql';
import useDocumentTitle from 'hooks/useDocumentTitle';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  useTheme,
} from 'vendor/material';

import { CompanyItem } from './components';
import AceVersion from './components/AceVersion/AceVersion';
import AwsConsoleButton from './components/AwsConsoleButton';
import AzurePartnerCenterButton from './components/AzurePartnerCenterButton';
import GoToDownstreamModalButton from './components/GoToDownstreamModalButton/GoToDownstreamModalButton';
import VendorConfig from './tabs/VendorConfig/VendorConfig';
import VendorListings from './tabs/VendorListings/VendorListings';
import VendorSandboxes from './tabs/VendorSandboxes/VendorSandboxes';
import VendorSupport from './tabs/VendorSupport/VendorSupport';
import { useActiveVendorTab, useVendorRootMatch } from './Vendor.hooks';
import { VendorEnvironmentEnum } from './VendorEnvironment.hooks';

export const VENDOR_QUERY = gql`
  query Vendor($id: ID!) {
    vendor(id: $id) {
      id
      name
      logo_url
      vendor_type
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1200,
    margin: '0 auto',
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down(1441)]: {
      maxWidth: '95%',
    },
    [theme.breakpoints.down(1367)]: {
      maxWidth: '90%',
    },
  },
  listHeader: {
    boxShadow: `-1px 0 0 #f5f5f5, 1px 0 0 #f5f5f5`,
    marginBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(8),
    position: 'sticky',
    top: 0,
    zIndex: 9,
    [theme.breakpoints.down(900)]: {
      width: 1200,
    },
  },
  listBody: {},
  modalContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const Vendor: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { path: rootPath, url, params: rootParams } = useVendorRootMatch()!;
  const { activeTabIndex, onClickTab, tabs = [] } = useActiveVendorTab();
  const { vendorid } = rootParams;

  const { data, loading, error } = useVendorQuery({
    variables: { id: vendorid },
  });

  useDocumentTitle(data?.vendor?.name ?? '');

  const handleClickTab = (_event: unknown, tabIndex: number): void =>
    onClickTab(tabIndex);

  if (loading) {
    return (
      <div className={classes.container}>
        <Box px={6} py={3} textAlign="center">
          <CircularProgress
            size={40}
            style={{ color: theme.palette.BLUE400 }}
          />
        </Box>
      </div>
    );
  }

  if (error || !data?.vendor) {
    return <Redirect to="/vendors" />;
  }

  if (activeTabIndex === null || activeTabIndex < 0) {
    return <Redirect to={`${url}/listings`} />;
  }

  const isSandboxEnvironment =
    data?.vendor?.vendor_type === VendorEnvironmentEnum.SANDBOX;

  const allowedTabs = isSandboxEnvironment
    ? tabs.filter((tab) => tab.tabPath !== 'sandboxes')
    : tabs;

  return (
    <div className={classes.container}>
      <CompanyItem
        name={data?.vendor?.name!}
        vendorId={data?.vendor?.id}
        logo={data?.vendor?.logo_url}
      >
        <Grid container spacing={1}>
          <Grid item>
            <AceVersion />
          </Grid>
          <Grid item>
            <AwsConsoleButton />
          </Grid>
          <Grid item>
            <AzurePartnerCenterButton />
          </Grid>
          <Grid item>
            <GoToDownstreamModalButton label="Go To Downstream" />
          </Grid>
        </Grid>
      </CompanyItem>
      <Box mt={5}>
        <Tabs
          tabs={allowedTabs}
          value={activeTabIndex}
          onChange={handleClickTab}
        />
        <Box mt={4}>
          <Switch>
            <Route path={`${rootPath}/listings`} component={VendorListings} />
            <Route path={`${rootPath}/config`} component={VendorConfig} />
            <Route path={`${rootPath}/support`} component={VendorSupport} />
            <Route path={`${rootPath}/sandboxes`} component={VendorSandboxes} />
            <Route path={`${rootPath}/*`}>
              <Redirect to={`${url}/listings`} />
            </Route>
          </Switch>
        </Box>
      </Box>
    </div>
  );
};

export default Vendor;
