import { gql } from '@apollo/client';
import { Banner, FormSection, Link } from '@tackle-io/platform-ui';
import InfoItem from 'components/InfoItem';
import {
  RedHatFaqItem,
  RedHatFeatureItem,
  RedHatImage,
  RedHatPricingEdition as RedHatPricingEditionType,
  RedHatSupportItem,
  RedHatVideo,
  useRedHatListingQuery,
} from 'generated/graphql';
import {
  ChartDonut,
  CurrencyUsd,
  FaceAgent,
  FileDocument,
  FileSearch,
  FolderMultipleImage,
  OfficeBuilding,
  Star,
} from 'mdi-material-ui';
import React, { Fragment } from 'react';
import { Box, Grid, Typography } from 'vendor/material';

import InfoFile from '../InfoFile/InfoFile';
import LoadingListingContent from '../LoadingListingContent/LoadingListingContent';
import SectionIcon from '../SectionIcon/SectionIcon';
import RedHatPricingEdition from './components/RedHatPricingEdition';

interface ListingContentRedHatProps {
  productidInternal: string;
}

export const REDHAT_LISTING_QUERY = gql`
  query RedHatListing($id: ID!) {
    redHatListing(id: $id) {
      callback {
        authentication {
          username
          password
        }
      }
      categories
      company {
        logo
        name
        website_url
      }
      configuration_url
      details {
        categories
        features {
          description
          title
        }
        license_agreement_url
      }
      faq_url
      faqs {
        answer
        link
        question
      }
      full_description
      id
      images {
        title
        url
      }
      logo
      marketplace_id
      marketplace_url
      name
      packages
      pricing {
        ECCN
        ECCN2
        UNSPSC
        editions {
          allow_auto_renew
          audience
          billing_frequency
          commerce_model
          delivery_method
          description
          details
          edition_charges {
            charge_type
            include_in_starting_price
            increment
            maximum_quantity
            minimum_quantity
            name
            name_description
            price
            price_frequency
            price_type
            required
            sequence
            unit_of_measure
            usage_reportingid
            vendor_chargeid
          }
          grace_period
          id
          launch_url
          name
          subscription_terms_in_months
          trial_length
          vendor_chargeid
        }
        geographies
      }
      product_readme
      quickstart_url
      readme_url
      search_keywords
      short_description
      status
      support {
        contact
        email
        items {
          description
          title
          url
        }
        privacy_policy_link
        resources
        support_url
      }
      troubleshooting_url
      vendorid
      videos {
        thumbnail_url
        title
        url
      }
      revision_of_product_id_internal
      revision_of_listing {
        id
        name
      }
    }
  }
`;

const CategoryDisplay = ({
  title,
  category,
}: {
  title: string | null | undefined;
  category: string | null | undefined;
}) => {
  return (
    <>
      <Typography variant="subtitle2" component="span">
        {title}:
      </Typography>{' '}
      {category}
      {'  '}
    </>
  );
};

const ListingContentRedHat: React.FC<ListingContentRedHatProps> = ({
  productidInternal,
}) => {
  const { data: listingData, loading: isFetching } = useRedHatListingQuery({
    variables: {
      id: productidInternal,
    },
  });
  const sourceListingData = listingData?.redHatListing?.revision_of_listing;

  if (isFetching) {
    return <LoadingListingContent />;
  }

  if (!listingData) {
    return <div>product info not found</div>;
  }

  return (
    <>
      {listingData?.redHatListing?.revision_of_product_id_internal && (
        <Box mb={3}>
          <Banner
            type="info"
            title={`Listing copied from ${sourceListingData?.name}`}
            body={
              <>
                <Typography>
                  We don't currently support diffing functionality for RedHat
                  listings.
                </Typography>
                <Link
                  to={`/vendor/${listingData?.redHatListing?.vendorid}/listings/${listingData?.redHatListing?.revision_of_product_id_internal}`}
                >
                  {sourceListingData?.name}
                </Link>
              </>
            }
          />
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem title="Internal Product ID">
            {listingData?.redHatListing?.id}
          </InfoItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem title="Marketplace Product ID">
            {listingData?.redHatListing?.marketplace_id}
          </InfoItem>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoItem title="SaaS URL">
            {listingData?.redHatListing?.marketplace_url}
          </InfoItem>
        </Grid>
      </Grid>
      <Box mt={5}>
        <FormSection
          title={
            <SectionIcon
              icon={<OfficeBuilding fontSize="inherit" />}
              title="Company Info"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <InfoItem title="Company Name">
                {listingData?.redHatListing?.company?.name}
              </InfoItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InfoItem title="Company Website URL">
                {listingData?.redHatListing?.company?.website_url}
              </InfoItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InfoItem title="Logo">
                {listingData?.redHatListing?.company?.logo && (
                  <InfoFile
                    name={listingData?.redHatListing?.company?.logo
                      ?.split('/')
                      .pop()}
                    type="picture"
                    file={listingData?.redHatListing?.company?.logo}
                  />
                )}
              </InfoItem>
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      <Box mt={5}>
        <FormSection
          title={
            <SectionIcon
              icon={<FileSearch fontSize="inherit" />}
              title="Search and Categories"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InfoItem title="Search Keywords">
                {listingData?.redHatListing?.search_keywords}
              </InfoItem>
            </Grid>
            <Grid item xs={12}>
              <InfoItem title="Categories">
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    {listingData?.redHatListing?.details?.categories?.[0] && (
                      <CategoryDisplay
                        title="Primary"
                        category={
                          listingData?.redHatListing?.details?.categories[0]
                        }
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    {listingData?.redHatListing?.details?.categories?.[1] && (
                      <CategoryDisplay
                        title="Sub Category 1"
                        category={
                          listingData?.redHatListing?.details.categories[1]
                        }
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    {listingData?.redHatListing?.details?.categories?.[2] && (
                      <CategoryDisplay
                        title="Sub Category 2"
                        category={
                          listingData?.redHatListing?.details.categories[2]
                        }
                      />
                    )}
                  </Grid>
                </>
              </InfoItem>
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      <Box mt={5}>
        <FormSection
          title={
            <SectionIcon
              icon={<FileDocument fontSize="inherit" />}
              title="General"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <InfoItem title="Title">
                {listingData?.redHatListing?.name}
              </InfoItem>
            </Grid>
            <Grid item sm={12} md={6}>
              <InfoItem title="Logo">
                {listingData?.redHatListing?.logo && (
                  <InfoFile
                    name={listingData?.redHatListing?.logo?.split('/').pop()}
                    type="picture"
                    file={listingData?.redHatListing?.logo}
                  />
                )}
              </InfoItem>
            </Grid>
            <Grid item xs={12}>
              <InfoItem title="Short Description">
                {listingData?.redHatListing?.short_description}
              </InfoItem>
            </Grid>
            <Grid item xs={12}>
              <InfoItem title="Long Description">
                {listingData?.redHatListing?.full_description}
              </InfoItem>
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      <Box mt={5}>
        <FormSection
          title={
            <SectionIcon icon={<Star fontSize="inherit" />} title="Features" />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            {listingData?.redHatListing?.details?.features?.map(
              (feature: RedHatFeatureItem, index: number) => {
                const count = index + 1;
                return (
                  <Fragment key={`${feature.title}${feature.description}`}>
                    <Grid item xs={12} sm={6}>
                      <InfoItem title={`Title ${count}`}>
                        {feature.title}
                      </InfoItem>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InfoItem title={`Description ${count}`}>
                        {feature.description}
                      </InfoItem>
                    </Grid>
                  </Fragment>
                );
              },
            )}
          </Grid>
        </FormSection>
      </Box>
      <Box mt={5}>
        <FormSection
          title={
            <SectionIcon
              icon={<FolderMultipleImage fontSize="inherit" />}
              title="Media"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            {listingData?.redHatListing?.videos?.map(
              (video: RedHatVideo, index: number) => {
                const count = index + 1;
                return (
                  <Fragment key={video?.url}>
                    <Grid item xs={12} sm={6} md={4}>
                      <InfoItem title={`Video Title ${count}`}>
                        {video?.title}
                      </InfoItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InfoItem title="Video URL">{video?.url}</InfoItem>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4}>
                      <InfoItem title="Video Thumbnail">
                        {video?.thumbnail_url && (
                          <InfoFile
                            name={video?.thumbnail_url?.split('/').pop()}
                            type="picture"
                            file={video?.thumbnail_url}
                          />
                        )}
                      </InfoItem>
                    </Grid>
                  </Fragment>
                );
              },
            )}
            {listingData?.redHatListing?.images?.map(
              (image: RedHatImage, index: number) => {
                const count = index + 1;
                return (
                  <Fragment key={image?.url}>
                    <Grid item xs={12} sm={6} md={4}>
                      <InfoItem title={`Image Title ${count}`}>
                        {image?.title}
                      </InfoItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InfoItem title={`Image URL ${count}`}>
                        <InfoFile
                          name={image?.url?.split('/').pop()}
                          type="picture"
                          file={image?.url}
                        />
                      </InfoItem>
                    </Grid>
                  </Fragment>
                );
              },
            )}
          </Grid>
        </FormSection>
      </Box>
      <Box mt={5}>
        <FormSection
          title={
            <SectionIcon
              icon={<FileDocument fontSize="inherit" />}
              title="Documentation"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <InfoItem title="License Agreement">
                {listingData?.redHatListing?.details?.license_agreement_url}
              </InfoItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InfoItem title="Configuration">
                {listingData?.redHatListing?.configuration_url}
              </InfoItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InfoItem title="Quickstart">
                {listingData?.redHatListing?.quickstart_url}
              </InfoItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InfoItem title="Troubleshooting">
                {listingData?.redHatListing?.troubleshooting_url}
              </InfoItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InfoItem title="FAQs">
                {listingData?.redHatListing?.faq_url}
              </InfoItem>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InfoItem title="Readme">
                {listingData?.redHatListing?.readme_url}
              </InfoItem>
            </Grid>
            <Grid item xs={12} sm={8} md={4}>
              <InfoItem title="Product Readme">
                {listingData?.redHatListing?.product_readme}
              </InfoItem>
            </Grid>
            {listingData?.redHatListing?.faqs?.map(
              (faq: RedHatFaqItem, index: number) => {
                const count = index + 1;
                return (
                  <Fragment key={faq?.question}>
                    <Grid item xs={12} sm={6} md={4}>
                      <InfoItem title={`FAQ Question ${count}`}>
                        {faq?.question}
                      </InfoItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InfoItem title={`FAQ Answer ${count}`}>
                        {faq?.answer}
                      </InfoItem>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4}>
                      <InfoItem title={`FAQ Answer URL ${count}`}>
                        {faq?.link}
                      </InfoItem>
                    </Grid>
                  </Fragment>
                );
              },
            )}
          </Grid>
        </FormSection>
      </Box>
      <Box mt={5}>
        <FormSection
          title={
            <SectionIcon
              icon={<FaceAgent fontSize="inherit" />}
              title="Resources"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            {listingData?.redHatListing?.support?.items?.map(
              (support_item: RedHatSupportItem, index: number) => {
                const count = index + 1;
                return (
                  <Fragment key={support_item?.url}>
                    <Grid item xs={12} sm={6} md={4}>
                      <InfoItem title={`Title ${count}`}>
                        {support_item?.title}
                      </InfoItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InfoItem title={`Description ${count}`}>
                        {support_item?.description}
                      </InfoItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <InfoItem title={`URL ${count}`}>
                        {support_item?.url}
                      </InfoItem>
                    </Grid>
                  </Fragment>
                );
              },
            )}
          </Grid>
        </FormSection>
      </Box>
      <Box mt={5}>
        <FormSection
          title={
            <SectionIcon
              icon={<CurrencyUsd fontSize="inherit" />}
              title="Pricing"
            />
          }
          mb={1.5}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InfoItem title="Export Control Classification Number (ECCN)">
                {listingData?.redHatListing?.pricing?.ECCN}
              </InfoItem>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoItem title="Other ECCN Code (if ECCN code is other)">
                {listingData?.redHatListing?.pricing?.ECCN2}
              </InfoItem>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoItem title="UNSPSC">
                {listingData?.redHatListing?.pricing?.UNSPSC}
              </InfoItem>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoItem title="Additional Geographies">
                {listingData?.redHatListing?.pricing?.geographies}
              </InfoItem>
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      <Box mt={5}>
        {listingData?.redHatListing?.pricing?.editions
          ? listingData?.redHatListing?.pricing?.editions.map(
              (edition: RedHatPricingEditionType, index: number) => (
                <Box mb={5} key={edition?.id}>
                  <RedHatPricingEdition edition={edition} index={index} />
                </Box>
              ),
            )
          : null}
        <Box mt={5}>
          <FormSection
            title={
              <SectionIcon
                icon={<ChartDonut fontSize="inherit" />}
                title="Lifecycle Notifications"
              />
            }
            mb={1.5}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InfoItem title="URL webhook">
                  {`https://upstream-api.tackle.io/redhat/webhook/${String(
                    listingData?.redHatListing?.vendorid,
                  )}`}
                </InfoItem>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InfoItem title="Operations email">support@tackle.io</InfoItem>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InfoItem sensitive title="Username">
                  {
                    listingData?.redHatListing?.callback?.authentication
                      ?.username
                  }
                </InfoItem>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InfoItem sensitive title="Password">
                  {
                    listingData?.redHatListing?.callback?.authentication
                      ?.password
                  }
                </InfoItem>
              </Grid>
            </Grid>
          </FormSection>
        </Box>
      </Box>
    </>
  );
};

export default ListingContentRedHat;
