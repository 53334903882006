import { AzureMediaImage } from 'generated/graphql';
import { zip } from 'lodash';
import React from 'react';
import { Grid } from 'vendor/material';

import InfoDiffFile from '../InfoDiffFile/InfoDiffFile';
import InfoDiffItem from '../InfoDiffItem/InfoDiffItem';

type AzureMediaImagesProps = {
  sourceMediaImages: Array<AzureMediaImage> | null | undefined;
  revisionMediaImages: Array<AzureMediaImage> | null | undefined;
};

const AzureMediaImages: React.FC<AzureMediaImagesProps> = ({
  sourceMediaImages = [],
  revisionMediaImages = [],
}) => {
  const combinedMediaImages = zip(
    sourceMediaImages ?? [],
    revisionMediaImages ?? [],
  );

  if (!combinedMediaImages?.length) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <InfoDiffItem title="Screenshot 1" />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <InfoDiffItem title="Caption 1" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {combinedMediaImages.map(([source, revision], index) => (
        <React.Fragment key={index}>
          <Grid item xs={6} md={6} lg={6}>
            <InfoDiffFile
              title={`Screenshot ${index + 1}`}
              sourceFile={source?.image_url}
              sourceFileName={source?.image_url?.split('/').pop()}
              revisionFile={revision?.image_url}
              revisionFileName={revision?.image_url?.split('/').pop()}
              type="picture"
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <InfoDiffItem
              title={`Caption ${index + 1}`}
              sourceValue={source?.caption}
              revisionValue={revision?.caption}
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default AzureMediaImages;
