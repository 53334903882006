import { useEffect, useRef } from 'react';

export default function ScrollToTopOnMount() {
  const elementRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  return <div ref={elementRef} />;
}
