import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  headerColumn: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.NEUTRAL600,
    cursor: 'pointer',
    '& > *:nth-child(2)': {
      display: 'none',
    },
  },
  headerColumnActive: {
    '& > *:nth-child(2)': {
      marginLeft: theme.spacing(1),
      display: 'block',
    },
    '& $headerText': {
      color: theme.palette.NEUTRAL600,
    },
  },
  headerText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 700, // theme.typography.fontWeightBold,
    color: theme.palette.NEUTRAL300,
  },
}));

export default useStyles;
