import { useHeaderQuery } from 'generated/graphql';
import { useEffect } from 'react';
import { setUser } from 'utils/datadog';

const DatadogUser = () => {
  const { data } = useHeaderQuery();

  const user = data?.currentUser;

  useEffect(() => {
    if (user) {
      setUser(user);
    }
  }, [user]);

  return null;
};

export default DatadogUser;
