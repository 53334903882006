import { Button, Modal, ProviderIcon } from '@tackle-io/platform-ui';
import { CogOutline } from 'mdi-material-ui';
import React, { useState } from 'react';
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from 'vendor/material';

import AzureVendorConfigForm from './AzureVendorConfigForm';
import GcpVendorConfigForm from './GcpVendorConfigForm';
import RedHatVendorConfigForm from './RedHatVendorConfigForm';
import useStyles from './VendorConfig.styles';

const ConfigButton = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>();
  const [marketplaceToEdit, setMarketplaceToEdit] = useState<
    'Azure' | 'Google' | 'Redhat' | null
  >(null);

  const theme = useTheme();

  const closeModal = (): void => setMarketplaceToEdit(null);

  return (
    <>
      <Button
        aria-controls="cloud-menu"
        aria-haspopup="true"
        className={classes.configButton}
        onClick={(e): void => setAnchorEl(e.currentTarget)}
      >
        <CogOutline className={classes.configIcon} />
      </Button>
      <Menu
        id="cloud-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        className={classes.cloudConfig}
      >
        <MenuItem onClick={(): void => setMarketplaceToEdit('Azure')}>
          <ListItemIcon className={classes.cloudInformation}>
            <ProviderIcon fontSize="inherit" provider="azure" />
          </ListItemIcon>
          <ListItemText primary="Azure Cloud Configuration" />
        </MenuItem>
        <MenuItem onClick={(): void => setMarketplaceToEdit('Google')}>
          <ListItemIcon className={classes.cloudInformation}>
            <ProviderIcon fontSize="inherit" provider="gcp" />
          </ListItemIcon>
          <ListItemText primary="Google Cloud Configuration" />
        </MenuItem>
        <MenuItem onClick={(): void => setMarketplaceToEdit('Redhat')}>
          <ListItemIcon className={classes.cloudInformation}>
            <ProviderIcon fontSize="inherit" provider="redhat" />
          </ListItemIcon>
          <ListItemText
            color={theme.palette.NEUTRAL400}
            className={classes.cloudLogo}
            primary="RedHat Cloud Configuration"
          />
        </MenuItem>
      </Menu>
      <Modal
        title={
          marketplaceToEdit
            ? `${marketplaceToEdit} Cloud Provider Configuration`
            : ''
        }
        width="medium"
        open={marketplaceToEdit !== null}
        // @ts-ignore issue in platform-ui
        onClose={(e, reason) => {
          if (reason !== 'backdropClick') closeModal();
        }}
      >
        <>
          {marketplaceToEdit === 'Azure' && (
            <AzureVendorConfigForm onClose={closeModal} />
          )}
          {marketplaceToEdit === 'Google' && (
            <GcpVendorConfigForm onClose={closeModal} />
          )}
          {marketplaceToEdit === 'Redhat' && (
            <RedHatVendorConfigForm onClose={closeModal} />
          )}
        </>
      </Modal>
    </>
  );
};

export default ConfigButton;
