import { makeStyles } from 'vendor/material';

const BACKGROUND = '#F7F7FA';

export default makeStyles(() => ({
  app: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: BACKGROUND,
  },
}));
