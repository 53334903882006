import { ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useRef } from 'react';
import getApolloClient from 'utils/getApolloClient';

const tokenOverride = localStorage.getItem('tokenOverride');

const AuthorizedApolloProvider = ({ children }: { children: ReactNode }) => {
  const { getAccessTokenSilently } = useAuth0();

  const authLink = setContext(async (operation, prevContext) => {
    const token = await getAccessTokenSilently();

    if (!token) return prevContext;

    return {
      ...prevContext,
      headers: {
        ...prevContext.headers,
        authorization: `Bearer ${tokenOverride || token}`,
      },
    };
  });

  const client = useRef(getApolloClient(authLink));
  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
