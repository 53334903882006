import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.NEUTRAL000,
    boxShadow: theme.shadows[1],
    borderRadius: 4,
    padding: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.shadows[2],
    },
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: 40,
    height: 40,
    borderRadius: '50%',
  },
  logoContainer: {
    marginRight: theme.spacing(2),
  },
  content: {},
  name: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 500, // theme.typography.fontWeightMedium,
    color: theme.palette.NEUTRAL700,
  },
  vendorId: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.NEUTRAL300,
  },
}));

export default useStyles;
