import './index.css';

import platformUITheme from '@tackle-io/platform-ui/Theme';
import AuthorizedApolloProvider from 'components/AuthorizedApolloProvider';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from 'vendor/material';

import App from './App';
import Auth0ProviderWithHistory from './components/Auth0ProviderWithHistory';
import DocumentTitleContext from './contexts/DocumentTitleContext';
import { initDatadog } from './utils/datadog';
import intiSentry from './utils/sentry';

initDatadog();
intiSentry();

const root = createRoot(document.getElementById('root')!);

root.render(
  // <React.StrictMode>
  // oddly it seems like we cant use strict mode with mui v4 or else we lose some styles
  <React.Fragment>
    <CssBaseline />
    <ThemeProvider theme={platformUITheme}>
      <Router>
        <Auth0ProviderWithHistory>
          <AuthorizedApolloProvider>
            <DocumentTitleContext.Provider value={document.title}>
              <App />
            </DocumentTitleContext.Provider>
          </AuthorizedApolloProvider>
        </Auth0ProviderWithHistory>
      </Router>
    </ThemeProvider>
  </React.Fragment>,
  // </React.StrictMode>
);
