import { Auth0Provider } from '@auth0/auth0-react';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const Auth0ProviderWithHistory = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const history = useHistory();
  const query = useQuery();
  const connection = query.get('con');
  const uri = `${window.location.origin}/auth/callback`;
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      redirectUri={uri}
      onRedirectCallback={(appState) =>
        history.push(appState?.returnTo || window.location.pathname)
      }
      cacheLocation="localstorage"
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      connection={connection || ''}
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
