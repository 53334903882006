export const LISTING_STATUS_IN_PROGRESS = 'in-progress';
export const LISTING_STATUS_WAITING_FOR_TACKLE = 'waiting-for-tackle';
export const LISTING_STATUS_WAITING_FOR_CLOUD_PROVIDER =
  'waiting-for-cloud-provider';
export const LISTING_STATUS_READY_TO_PREVIEW = 'ready-to-preview';
export const LISTING_STATUS_PUBLISHED = 'published';
export const LISTING_STATUS_DEPRECATED = 'deprecated';

export const LISTING_STATUSES = [
  LISTING_STATUS_IN_PROGRESS,
  LISTING_STATUS_WAITING_FOR_TACKLE,
  LISTING_STATUS_WAITING_FOR_CLOUD_PROVIDER,
  LISTING_STATUS_READY_TO_PREVIEW,
  LISTING_STATUS_PUBLISHED,
  LISTING_STATUS_DEPRECATED,
] as const;
