import { gql } from '@apollo/client';
import {
  Button,
  Checkbox,
  Modal,
  Select,
  TextField,
} from '@tackle-io/platform-ui';
import { Field, FieldProps, Form, Formik } from 'formik';
import {
  AwsListingRelistInput,
  AzureListingRelistInput,
  GcpListingRelistInput,
  useRelistListingButtonAwsListingMutation,
  useRelistListingButtonAzureListingMutation,
  useRelistListingButtonGcpListingMutation,
} from 'generated/graphql';
import { useState } from 'react';
import getProgressTitleFromStatus from 'utils/getProgressTitleFromStatus';
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  Typography,
} from 'vendor/material';
import {
  boolean as yupBoolean,
  object as yupObject,
  string as yupString,
} from 'yup';

import { LISTING_STATUSES } from '../../../../../../constants';
import { AWS_LISTING_QUERY } from '../ListingContentAWS/ListingContentAWS';
import { AZURE_LISTING_QUERY } from '../ListingContentAzure/ListingContentAzure';
import { GCP_LISTING_QUERY } from '../ListingContentGCP/ListingContentGCP';

gql`
  mutation RelistListingButtonAwsListing($updates: AwsListingRelistInput!) {
    relistAwsListingRevision(updates: $updates) {
      id
    }
  }
`;

gql`
  mutation RelistListingButtonAzureListing($updates: AzureListingRelistInput!) {
    relistAzureListingRevision(updates: $updates) {
      id
    }
  }
`;

gql`
  mutation RelistListingButtonGcpListing($updates: GcpListingRelistInput!) {
    relistGcpListingRevision(updates: $updates) {
      id
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    paddingRight: theme.spacing(2),
  },
}));

type Values = {
  marketplaceId: string;
  name: string;
  marketplaceUrl: string;
  status: string;
  encryptedProductId?: string;
  dashboardUrl?: string;
  syncListingToSF: boolean;
};

const getRelistSchema = (marketplace: 'aws' | 'gcp' | 'azure') => {
  const schema = {
    name: yupString().required(),
    marketplaceUrl: yupString().url('Must be a valid URL.').required(),
    marketplaceId: yupString()
      .required('Marketplace ID cannot be empty')
      .matches(
        /^[^\\/?]+$/,
        'Marketplace ID cannot contain certain special characters',
      ),
    status: yupString().required(),
    syncListingToSF: yupBoolean().required(),
  };

  if (marketplace === 'aws') {
    return yupObject()
      .shape({ ...schema, encryptedProductId: yupString().required() })
      .required();
  }

  if (marketplace === 'gcp') {
    return yupObject()
      .shape({
        ...schema,
        dashboardUrl: yupString()
          .matches(/^https:\/\//, {
            message: "Must be a valid URL beginning with 'https://'",
            excludeEmptyString: true,
          })
          .url('Mus be a valid URL.'),
      })
      .required();
  }

  return yupObject().shape(schema).required();
};

const awsListingToApi = (
  values: Values,
): Omit<AwsListingRelistInput, 'id'> => ({
  marketplace_id: values?.marketplaceId,
  name: values?.name,
  marketplace_url: values?.marketplaceUrl,
  status: values?.status,
  should_sync_listing_to_salesforce_app: values?.syncListingToSF,
  encrypted_productid: values.encryptedProductId!,
});

const azureListingToApi = (
  values: Values,
): Omit<AzureListingRelistInput, 'id'> => ({
  marketplace_id: values?.marketplaceId,
  name: values?.name,
  marketplace_url: values?.marketplaceUrl,
  status: values?.status,
  should_sync_listing_to_salesforce_app: values?.syncListingToSF,
});

const gcpListingToApi = (
  values: Values,
): Omit<GcpListingRelistInput, 'id'> => ({
  marketplace_id: values?.marketplaceId,
  name: values?.name,
  marketplace_url: values?.marketplaceUrl,
  status: values?.status,
  should_sync_listing_to_salesforce_app: values?.syncListingToSF,
  dashboard_url: values.dashboardUrl || null,
});
interface RelistListingButtonProps {
  productidInternal: string;
  marketplace: 'aws' | 'gcp' | 'azure' | 'redhat';
}

const refetchQueries = ['vendorListings'];

const RelistListingButton = ({
  productidInternal,
  marketplace,
}: RelistListingButtonProps) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [
    relistAwsListing,
    { loading: loadingRelistAwsListing, error: errorRelistAwsListing },
  ] = useRelistListingButtonAwsListingMutation({
    refetchQueries: [
      ...refetchQueries,
      {
        query: AWS_LISTING_QUERY,
        variables: {
          id: productidInternal,
        },
      },
    ],
  });
  const [
    relistAzureListing,
    { loading: loadingRelistAzureListing, error: errorRelistAzureListing },
  ] = useRelistListingButtonAzureListingMutation({
    refetchQueries: [
      ...refetchQueries,
      {
        query: AZURE_LISTING_QUERY,
        variables: {
          id: productidInternal,
        },
      },
    ],
  });
  const [
    relistGcpListing,
    { loading: loadingRelistGcpListing, error: errorRelistGcpListing },
  ] = useRelistListingButtonGcpListingMutation({
    refetchQueries: [
      ...refetchQueries,
      {
        query: GCP_LISTING_QUERY,
        variables: {
          id: productidInternal,
        },
      },
    ],
  });

  const loading =
    loadingRelistAwsListing ||
    loadingRelistAzureListing ||
    loadingRelistGcpListing;
  const hasRelistError =
    errorRelistAwsListing || errorRelistAzureListing || errorRelistGcpListing;

  if (marketplace === 'redhat') {
    return null;
  }

  const handleOnSubmit = async (values: Values) => {
    if (marketplace === 'aws') {
      await relistAwsListing({
        variables: {
          updates: {
            id: productidInternal,
            ...awsListingToApi(values),
          },
        },
      });
    }

    if (marketplace === 'azure') {
      await relistAzureListing({
        variables: {
          updates: {
            id: productidInternal,
            ...azureListingToApi(values),
          },
        },
      });
    }

    if (marketplace === 'gcp') {
      await relistGcpListing({
        variables: {
          updates: {
            id: productidInternal,
            ...gcpListingToApi(values),
          },
        },
      });
    }
  };

  return (
    <Box className={classes.buttonContainer}>
      <Modal
        title="Relisting Modal"
        width="medium"
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        {/* We could consolidate all properties in one form, but unsure if we should be defining a form per cloud ??? let see */}
        <Formik<Values>
          validationSchema={getRelistSchema(marketplace)}
          initialValues={{
            marketplaceId: '',
            name: '',
            marketplaceUrl: '',
            status: '',
            encryptedProductId: '',
            dashboardUrl: '',
            syncListingToSF: false,
          }}
          onSubmit={handleOnSubmit}
        >
          {({ dirty, errors, isValid }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    You are about to create a new listing with the changes
                    specified below, before relisting we must capture some extra
                    information to match marketplace requirements.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    error={errors?.marketplaceId}
                    label="Marketplace ID"
                    name="marketplaceId"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    error={errors?.name}
                    label="Name"
                    name="name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    error={errors?.marketplaceUrl}
                    label="Marketplace Url"
                    name="marketplaceUrl"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={Select}
                    error={errors.status}
                    label="Status"
                    name="status"
                  >
                    <>
                      <option disabled>Choose a value</option>
                      {LISTING_STATUSES.map((value) => {
                        return (
                          <option key={value} value={value}>
                            {getProgressTitleFromStatus(value)}
                          </option>
                        );
                      })}
                    </>
                  </Field>
                </Grid>
                {/* GCP Only */}
                {marketplace === 'gcp' && (
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      error={errors?.dashboardUrl}
                      label="Dashboard Url"
                      name="dashboardUrl"
                    />
                  </Grid>
                )}
                {/* AWS Only */}
                {marketplace === 'aws' && (
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      error={errors?.encryptedProductId}
                      label="Encrypted Product ID"
                      name="encryptedProductId"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Field name="syncListingToSF">
                    {({ field }: FieldProps<boolean>) => {
                      return (
                        <div>
                          <FormControlLabel
                            label="Sync listing to Salesforce Application"
                            control={
                              <Box mx={1}>
                                <Checkbox
                                  {...field}
                                  onChange={field.onChange}
                                  checked={field.value}
                                  aria-label="Sync listing to Salesforce Application"
                                />
                              </Box>
                            }
                          />
                          <FormHelperText>
                            Enable listing syncing to the Salesforce
                            Application.
                          </FormHelperText>
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
              </Grid>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    disabled={loading}
                    appearance="primary"
                    variant="text"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    loading={loading}
                    disabled={!isValid || loading || !dirty}
                    type="submit"
                    appearance={hasRelistError ? 'destructive' : 'success'}
                    variant="text"
                  >
                    {hasRelistError ? 'Try again' : 'Relist'}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Modal>
      <Button
        size="small"
        variant="outlined"
        appearance="primary"
        onClick={() => setIsModalOpen(true)}
      >
        Relist
      </Button>
    </Box>
  );
};

export default RelistListingButton;
