import { init } from '@sentry/react';

const initSentry = (): void => {
  if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
    init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENVIRONMENT,
      release: `admin-dashboard@${process.env.REACT_APP_RELEASE_SHA}`, // should match SENTRY_RELEASE from .circleci/config.yml
      integrations: [],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
};

export default initSentry;
