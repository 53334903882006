import React from 'react';
import { Box, CircularProgress, useTheme } from 'vendor/material';

const LoadingListingContent = () => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" py={6}>
      <CircularProgress size={40} style={{ color: theme.palette.BLUE400 }} />
    </Box>
  );
};

export default LoadingListingContent;
