import { gql } from '@apollo/client';
import { Alert, Button, Code, TextField } from '@tackle-io/platform-ui';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Download } from 'mdi-material-ui';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography } from 'vendor/material';
import { object as yupObject, string as yupString } from 'yup';

import {
  useGcpVendorConfigurationFormQuery,
  useUpdateGcpVendorConfigurationFormMutation,
} from '../../../../generated/graphql';
import { decodeBase64ToJson, downloadJsonFile } from './helpers';
import useStyles from './VendorConfig.styles';

export const GCP_VENDOR_CONFIGURATION_QUERY = gql`
  query GcpVendorConfigurationForm($id: ID!) {
    vendor(id: $id) {
      id
      configuration {
        id
        gcp {
          partner_id
          projects {
            name
            report_bucket
            service_account
            project_name
            project_number
            service_account_name
            service_account_email_address
          }
        }
      }
    }
  }
`;

export const GCP_VENDOR_CONFIGURATION_MUTATION = gql`
  mutation UpdateGcpVendorConfigurationForm($updates: VendorInput!) {
    updateVendor(updates: $updates) {
      id
      configuration {
        id
        gcp {
          partner_id
        }
      }
    }
  }
`;

type FormValues = {
  gcpPartnerId: string;
};

const schema = yupObject().shape({
  gcpPartnerId: yupString().required(),
});

const GcpVendorConfigForm = ({ onClose }: { onClose: () => void }) => {
  const { vendorid } = useParams<{ vendorid: string }>();

  const { data, loading: isQueryLoading } = useGcpVendorConfigurationFormQuery({
    variables: {
      id: vendorid,
    },
  });

  const [mutateAsync, { loading: isMutationLoading, error: isError }] =
    useUpdateGcpVendorConfigurationFormMutation({ onCompleted: onClose });

  const isLoading = isQueryLoading || isMutationLoading;

  const classes = useStyles();

  const partnerId = data?.vendor?.configuration?.gcp?.partner_id || '';
  const project = data?.vendor?.configuration?.gcp?.projects?.[0] || {};
  const serviceAccount = project?.service_account || null;
  const decodedServiceAccountJson = serviceAccount
    ? JSON.stringify(decodeBase64ToJson(serviceAccount), null, 2)
    : null;
  const serviceKeyAccounJsonFileName =
    `${project?.project_name}-${vendorid}` || '';

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        gcpPartnerId: partnerId,
      }}
      onSubmit={async ({ gcpPartnerId }: FormValues): Promise<void> => {
        await mutateAsync({
          variables: {
            updates: {
              id: vendorid,
              configuration: {
                gcp: { partner_id: gcpPartnerId },
              },
            },
          },
        });
      }}
      validationSchema={schema}
    >
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field name="gcpPartnerId">
              {({
                field,
                meta,
              }: FieldProps<
                FormValues['gcpPartnerId']
              >): React.ReactElement => (
                <TextField
                  {...field}
                  label="Partner ID *"
                  error={meta.error}
                  disabled={isLoading}
                />
              )}
            </Field>
          </Grid>
          <>
            <Grid item xs={12}>
              <Typography className={classes.label} component="p">
                Service Account key JSON
              </Typography>
              {serviceAccount ? (
                <Code language="json">{decodedServiceAccountJson}</Code>
              ) : (
                <Typography component="p">
                  No service account key uploaded to vendor.
                </Typography>
              )}
            </Grid>
            {serviceAccount && (
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row-reverse">
                  <Button
                    variant="outlined"
                    appearance="secondary"
                    onClick={() =>
                      downloadJsonFile(
                        decodedServiceAccountJson,
                        serviceKeyAccounJsonFileName,
                      )
                    }
                    startIcon={<Download />}
                  >
                    Download
                  </Button>
                </Box>
              </Grid>
            )}
          </>
        </Grid>
        {isError && (
          <Box mt={4}>
            <Alert
              appearance="danger"
              noShadow
              hideIcon
              title="Error: Unsuccessful vendor update. Create a support ticket, assign it to pod:streetsharks, and the team will reach out when the issue has been resolved."
            />
          </Box>
        )}
        <Box display="flex" flexDirection="row-reverse" mt={4}>
          <Button
            type="submit"
            appearance="primary"
            variant="text"
            disabled={isLoading}
            loading={isLoading}
          >
            Update
          </Button>
          <Button
            appearance="primary"
            disabled={false}
            variant="text"
            onClick={(): void => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </Box>
      </Form>
    </Formik>
  );
};

export default GcpVendorConfigForm;
