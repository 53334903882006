import { Link, Modal, ProviderIcon } from '@tackle-io/platform-ui';
import classnames from 'classnames';
import ScrollToTopOnMount from 'components/ScrollToTopOnMount';
import { ChevronDown, ChevronUp, CogOutline } from 'mdi-material-ui';
import React, { useState } from 'react';
import getProgressTitleFromStatus from 'utils/getProgressTitleFromStatus';
import { Grid, IconButton, Paper, Typography } from 'vendor/material';

import { GoToDownstreamModalButton } from '../../../../components';
import AwsListingUpdateModalContents from '../AwsListingUpdateModalContents';
import AzureListingUpdateModalContents from '../AzureListingUpdateModalContents';
import CopyListing from '../CopyListing';
import ExportEntitlementsCsvButton from '../ExportEntitlementsCsvButton/ExportEntitlementsCsvButton';
import ExportListingToJsonButton from '../ExportListingToJsonButton/ExportListingToJsonButton';
import GcpListingUpdateModalContents from '../GcpListingUpdateModalContents';
import ListingContentAWS from '../ListingContentAWS/ListingContentAWS';
import ListingContentAzure from '../ListingContentAzure/ListingContentAzure';
import ListingContentGCP from '../ListingContentGCP/ListingContentGCP';
import ListingContentRedHat from '../ListingContentRedHat/ListingContentRedHat';
import MergeListingButton from '../MergeListingButton/MergeListingButton';
import RedHatListingUpdateModalContents from '../RedHatListingUpdateModalContents';
import RelistListingButton from '../RelistListingButton/RelistListingButton';
import UpdateListingWithCapiModalButton from '../UpdateListingWithCapiModalButton/UpdateListingWithCapiModalButton';
import useStyles from './ListingListItem.styles';

interface ListingListItemProps {
  encryptedProductid: string | null | undefined;
  gcpService: string | null | undefined;
  marketplaceUrl: string | null | undefined;
  name: string;
  onToggleOpen: () => void;
  open: boolean;
  marketplaceId: string;
  productidInternal: string;
  provider: 'aws' | 'gcp' | 'redhat' | 'azure';
  status: Parameters<typeof getProgressTitleFromStatus>[0];
  vendorid: string;
  isARevision: boolean;
  isProductionVendor: boolean;
}

const EM_DASH = '—';

type MarketplaceUrlLinkProps = {
  marketplaceUrl: string | null | undefined;
};

const MarketplaceUrlLink: React.FC<MarketplaceUrlLinkProps> = ({
  marketplaceUrl,
}) => {
  const classes = useStyles();
  const stopPropagation = (e: React.MouseEvent<HTMLAnchorElement>): void =>
    e.stopPropagation();

  if (!marketplaceUrl) {
    return <Typography>{EM_DASH}</Typography>;
  }

  return (
    <Typography>
      <Link
        to={marketplaceUrl}
        className={classes.marketplaceLink}
        disableStyles
        external
        showExternalIcon
        onClick={stopPropagation}
      >
        Link
      </Link>
    </Typography>
  );
};

const ListingListItem: React.FC<ListingListItemProps> = ({
  marketplaceUrl,
  name,
  onToggleOpen,
  open,
  marketplaceId,
  productidInternal,
  provider,
  status,
  vendorid,
  isARevision,
  isProductionVendor,
}) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const cssRoot = classnames(classes.root, { [classes.opened]: open });

  const onOpenUpdateModal = (event: React.MouseEvent): void => {
    event.stopPropagation();
    setModalOpen(true);
  };

  const handleCloseUpdateModal = (): void => setModalOpen(false);

  return (
    <Paper className={cssRoot}>
      {open && <ScrollToTopOnMount />}
      <div
        className={classnames(classes.header)}
        onClick={onToggleOpen}
        role="presentation"
      >
        <IconButton onClick={onToggleOpen}>
          {open ? <ChevronUp /> : <ChevronDown />}
        </IconButton>
        <div className={classes.headerContent}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={9} sm={6} md={6} lg={6}>
              <div className={classes.listingName}>
                <div className={classes.providerLogo}>
                  <ProviderIcon provider={provider} fontSize="inherit" />
                </div>
                <Typography>{name}</Typography>
                {isARevision && <div className={classes.editTag}>Edits</div>}
              </div>
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={2}>
              <Typography>{getProgressTitleFromStatus(status)}</Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              className={classes.productIdColumn}
            >
              <Typography>{marketplaceId}</Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <MarketplaceUrlLink marketplaceUrl={marketplaceUrl} />
            </Grid>
          </Grid>
        </div>
        <IconButton onClick={onOpenUpdateModal}>
          <CogOutline />
        </IconButton>
        <Modal
          title={`Update ${name}`}
          width="medium"
          open={modalOpen}
          onClick={(e): void => e.stopPropagation()}
          // @ts-ignore issue in platform-ui
          onClose={(e, reason) => {
            if (reason !== 'backdropClick') handleCloseUpdateModal();
          }}
        >
          {provider === 'aws' ? (
            <AwsListingUpdateModalContents
              listingId={productidInternal}
              onClose={handleCloseUpdateModal}
            />
          ) : provider === 'azure' ? (
            <AzureListingUpdateModalContents
              listingId={productidInternal}
              onClose={handleCloseUpdateModal}
            />
          ) : provider === 'gcp' ? (
            <GcpListingUpdateModalContents
              listingId={productidInternal}
              onClose={handleCloseUpdateModal}
            />
          ) : provider === 'redhat' ? (
            <RedHatListingUpdateModalContents
              listingId={productidInternal}
              onClose={handleCloseUpdateModal}
            />
          ) : (
            <div>Something went wrong</div>
          )}
        </Modal>
      </div>
      {open && (
        <>
          <div className={classes.subheader}>
            {isARevision && (
              <>
                <MergeListingButton
                  marketplace={provider}
                  productidInternal={productidInternal}
                />
                <RelistListingButton
                  marketplace={provider}
                  productidInternal={productidInternal}
                />
              </>
            )}
            {provider === 'aws' && status === 'waiting-for-tackle' && (
              <UpdateListingWithCapiModalButton />
            )}
            {provider === 'aws' && (
              <ExportEntitlementsCsvButton
                vendorId={vendorid}
                productId={marketplaceId}
              />
            )}
            <CopyListing
              productName={name}
              vendorId={vendorid}
              cloud={provider}
              id={productidInternal}
            />
            <ExportListingToJsonButton
              marketplace={provider}
              productidInternal={productidInternal}
            />
            <GoToDownstreamModalButton
              cloud={provider}
              label="Go To Listing Management"
            />
          </div>
          <div className={classes.content}>
            <div className={classes.contentInfo}>
              {provider === 'aws' && (
                <ListingContentAWS productidInternal={productidInternal} />
              )}
              {provider === 'azure' && (
                <ListingContentAzure productidInternal={productidInternal} />
              )}
              {provider === 'gcp' && (
                <ListingContentGCP productidInternal={productidInternal} />
              )}
              {provider === 'redhat' && (
                <ListingContentRedHat productidInternal={productidInternal} />
              )}
            </div>
          </div>
        </>
      )}
    </Paper>
  );
};

export default ListingListItem;
