import {
  LISTING_STATUS_DEPRECATED,
  LISTING_STATUS_IN_PROGRESS,
  LISTING_STATUS_PUBLISHED,
  LISTING_STATUS_READY_TO_PREVIEW,
  LISTING_STATUS_WAITING_FOR_CLOUD_PROVIDER,
  LISTING_STATUS_WAITING_FOR_TACKLE,
} from '../constants';

const progressTitleByStatus = {
  [LISTING_STATUS_IN_PROGRESS]: 'Draft',
  [LISTING_STATUS_WAITING_FOR_TACKLE]: 'Tackle review',
  [LISTING_STATUS_WAITING_FOR_CLOUD_PROVIDER]: 'Cloud review',
  [LISTING_STATUS_READY_TO_PREVIEW]: 'Limited preview',
  [LISTING_STATUS_PUBLISHED]: 'Published',
  [LISTING_STATUS_DEPRECATED]: 'Deprecated',
} as const;

const getProgressTitleFromStatus = (
  status: keyof typeof progressTitleByStatus,
): string => {
  return progressTitleByStatus[status];
};

export default getProgressTitleFromStatus;
