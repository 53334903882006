import { HtmlTooltip } from '@tackle-io/platform-ui';
import { FilePdfBox, ImageArea } from 'mdi-material-ui';
import React from 'react';
import forceBrowserDownload from 'utils/forceBrowserDownload';
import { Typography } from 'vendor/material';

import useStyles from './InfoFile.styles';

interface InfoFileProps {
  type: 'pdf' | 'picture';
  name: string | null | undefined;
  file: string | null | undefined;
}

type PopoverTitleProps = {
  type: string;
  name: string;
  file: string;
};

const PopoverTitle: React.FC<PopoverTitleProps> = ({ file, name, type }) => {
  if (type === 'pdf') {
    return <Typography component="p">Click to download</Typography>;
  }

  return (
    <div>
      <img
        alt={name}
        src={file}
        style={{ maxWidth: '50vw', maxHeight: '50wh' }}
      />
    </div>
  );
};

const InfoFile: React.FC<InfoFileProps> = ({ type, name, file }) => {
  const classes = useStyles();
  if (!name || !file) return null;

  const handleDownload = async (): Promise<void> => {
    if (type === 'pdf') {
      await forceBrowserDownload(file, name, 'pdf');
    }
  };

  return (
    <HtmlTooltip
      interactive
      placement="bottom"
      title={<PopoverTitle type={type} name={name} file={file} />}
    >
      <div
        className={classes.container}
        onClick={handleDownload}
        role="presentation"
      >
        <div className={classes.fileIcon}>
          {type === 'picture' && <ImageArea fontSize="inherit" />}
          {type === 'pdf' && <FilePdfBox fontSize="inherit" />}
        </div>
        {name}
      </div>
    </HtmlTooltip>
  );
};

export default InfoFile;
